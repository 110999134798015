import React, {useState, useEffect, useContext} from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from './Pagination';
import { OBJECTS_ROUTE } from "../utils/consts";
import { Context } from "..";
import { crmRequest } from "../http/userAPI";
import AddObject from "./modalsForm/AddObject";

const TableObjects = observer(() => {
  const {client, object, user} = useContext(Context);
  const params = useParams();
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [countObjects, setCountObjects] = useState({"active": [1,0], "search": ''});
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const offset = object._limit * (selectedPage - 1);
  const [searchTimeout, setSearchTimeout] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const permissionAdd = user._permissions.includes('objects/add');
  const permissionList = user._permissions.includes('objects/list');
  const permissionGet = user._permissions.includes('objects/get');



  const filterObjects = (category, search) => {
    setSearchQuery(search);
    setCountObjects({"active": category, "search": search})
    setSelectedPage(1);
  }

  const chosenPage = (page) => {
    setSelectedPage(page);
  }

 let requestBody = {
    "id_client": params.id,
    "limit": object._limit,
    "offset": offset,
    "active": countObjects.active, 
    "search": searchQuery
  }


  const objectList = async () => {
    let obj = await crmRequest('objects/list', true, requestBody);
    return obj;
  }


  useEffect(()=> {
    if(searchQuery.length > 0){
      if(searchTimeout != false){
        clearTimeout(searchTimeout)
      }  
        setSearchTimeout(setTimeout(() => {
            objectList().then(obj => {
            object.setTotalCount(obj.count)
            object.setObject(obj.list);
      }).finally(() => setLoading(false))
      }, 500))
    } else{
    objectList().then(obj => {
      object.setTotalCount(obj.count)
      object.setObject(obj.list);
  }).finally(() => setLoading(false))}
  }, [countObjects, searchQuery, offset, selectedPage ])


  const keyHandler = (e) => {
    e.preventDefault();
 
}

const Callto = ({ phone, children }) => {
  return <a href={`tel:${phone}`}>{children}</a>;
};

 


  return (
    <div>
              <nav className="uk-flex uk-flex-wrap uk-flex-between " style={{paddingTop: '10px', paddingRight: '10px'}}>
                
                <div className="uk-width-1-1 uk-first-column uk-flex  uk-flex-between">
                    <h3 className="uk-margin-small-left">Об'єкти</h3>
                  <div className="uk-margin-small-bottom">
                    {
                      permissionAdd
                      ?
                      <button className="uk-button  uk-float-right  uk-button-primary" style={{textTransform: 'none'}}  onClick={() => setModalActive(true)}>Додати об'єкт</button>
                      :
                      null
                    }
                    
                </div>
                </div>

                <div className="uk-width-1-1 uk-margin-small-right">
                    <div className="uk-margin-small uk-flex uk-flex-between uk-flex-wrap uk-margin-right uk-margin-remove-right@m">
                      <form className="uk-search uk-search-default uk-flex uk-margin-small-left uk-margin-large-right uk-margin-bottom" style={{width: '250px'}}>
                          <input className="uk-search-input" value={searchQuery} type="search" onChange={e => filterObjects(countObjects.active, e.target.value)} placeholder="Пошук об'єкта..." aria-label="Search"/>
                          <span className="uk-search-icon-flip uk-position-center-right" uk-icon style={{paddingRight: '10px', paddingBottom: '3px'}}>
                            <svg width='20' height='20' viewBox="0 0 20 20">
                              <circle fill='none' stroke='#000' stroke-width='1.1' cx='9' cy='9' r='7'></circle>
                              <path fill='none' stroke='#000' stroke-width='1.1' d='M14,14 L18,18 L14,14 Z'></path> 
                            </svg>
                          </span>
                      </form>
                      <div>
                        <select className="uk-select uk-margin-small-left" onChange={(e) => filterObjects(Array.from(e.currentTarget.value), searchQuery)} aria-label="Select ">
                          {object._category.map(category =>
                            <option
                              key={category.id}
                              value={category.active}>
                              {category.type}
                              </option>
                          )}
                        </select>
                      </div>
                    </div>
                </div>

              </nav>
  
              <div className="uk-card-body">
               <table className="uk-table uk-table-small uk-table-divider" style={{minHeight: "300px"}}>
          
                 <thead>
                   <tr>
                    <th>Назва об'єкта</th>
                    <th>Адреса</th>
                    <th>Телефон</th>
                    <th className="uk-visible@m">Дата додавання</th>
                    <th className="uk-visible@l">Ширина</th>
                    <th className="uk-visible@l">довжина</th>
                    <th className="uk-visible@l">Статус</th>
                    </tr>
                 </thead>
                 {loading
                    ?
                    <div  uk-spinner="ratio: 1"></div>
                     :
                 <tbody>
                  {object._object.length != 0 && permissionList
                    ?
                    object._object.map(obj =>
                      <tr key={obj.id}>
                        {
                          permissionGet
                          ?
                          <td className="uk-text-left uk-text-small"><a onClick={() => history(OBJECTS_ROUTE + '/' + obj.id_client + '/' + obj.id)}>{obj.name}</a></td>
                          :
                          <td className="uk-text-left uk-text-small">{obj.name}</td>
                        }
                        <td className="uk-text-left uk-text-small">{obj.address}</td>
                        <td className="uk-text-left uk-text-small"><Callto phone={obj.phone}>{obj.phone}</Callto></td>
                        <td className="uk-visible@m uk-text-left">{obj.time_stamp}</td>
                        <td className="uk-visible@l uk-text-left">{obj.latitude}</td>
                        <td className="uk-visible@l uk-text-left">{obj.longitude}</td>
                        <td className="uk-visible@l uk-text-left">
                          {obj.active === '1'
                           ?
                           <a href="#" className="uk-icon-link uk-icon uk-text-success uk-margin-small-left" uk-icon="unlock"></a>
                           :
                           <a href="#" className="uk-icon-link uk-icon uk-text-danger uk-margin-small-left"uk-icon="lock"></a>
                          }
                        </td>
                      </tr>
                      )
                    :
                    <p className='uk-text-bold uk-margin-small-left uk-margin-top uk-text-uppercase' style={{textAlign: 'start'}}>Об'єкти не знайдені</p>
                    }
                 </tbody>
                  }
               </table>
              </div>

              <div className="uk-card-footer">
                <Pagination limit={object._limit} totalCount={object._totalCount} chosenPage={chosenPage} selectedPage={selectedPage}/>
              </div>

              {modalActive && <AddObject active={modalActive} setActive={setModalActive} keyHandler={keyHandler}/>}
         
    </div>
  );
});

export default TableObjects;