import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate} from "react-router-dom";
import {TASKS_ROUTE, CLIENTS_ROUTE, OBJECTS_ROUTE, WORKERS_ROUTE } from "../utils/consts";


const Menu = observer(() => {

  const history = useNavigate();

  return (
   
<nav className="uk-navbar-container uk-margin-bottom uk-sticky"  uk-sticky='sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky'>
  <div className="uk-container uk-margin-left" >
    <div uk-navbar>
      
    <div className="uk-navbar-left">

        <ul className="uk-navbar-nav">
            <li  className="uk-active">
                <a href="#" onClick={() => history(CLIENTS_ROUTE)}>
                  Клієнти
                </a>
            </li>
            <li><a href="#" onClick={() => history(TASKS_ROUTE)}>Задачі</a></li>
            <li><a href="#" onClick={() => history(WORKERS_ROUTE)}>Працівники</a></li>
        </ul>

    </div> 
    </div>
    </div>
</nav>


  );
});

export default Menu;