import React, { useEffect, useState, useContext } from "react";
import { Context } from "..";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { CLIENTS_ROUTE, EDITTASK_ROUTE, OBJECTS_ROUTE } from "../utils/consts";


const CardTask = observer(({singleTask, changeStatus}) => {
  const {user, task} = useContext(Context);
  const history = useNavigate();
  const [changeTask, setChangeTask] = useState(singleTask);
  const [statusTask, setStatusTask] = useState({});
  const [actionsTask, setActions] = useState({});
  const [clients, setClients] = useState({});
  const [objects, setObjects] = useState({});
  const statuses = [];
  const roles = [];
  const permissionEdit = user._permissions.includes('tasks/edit');
  const permissionGetClient = user._permissions.includes('clients/get');
  const permissionGetObject = user._permissions.includes('objects/get');
  const permissionTaskStatus = user._permissions.includes('tasks/status');
  

  
  

  for (let key in user._status) {
    if (user._status.hasOwnProperty(key)) {
      let statusOject = {key: key, status: user._status[key]} 
      statuses.push(statusOject);
    }
  }

  for(let key in user._role){
    if (user._role.hasOwnProperty(key)) {
      let role = {key: key, role: user._role[key]}
      roles.push(role);
    }
  }

  useEffect(()=> {
    setChangeTask({...singleTask});
  },[singleTask])
  
  
  useEffect(() => {
    setStatusTask(singleTask.status);
    setActions(singleTask.status);
  }, [])

  const Callto = ({ phone, children }) => {
    return <a href={`tel:${phone}`}>{children}</a>;
  };
  



  return (
    <div uk-grid className="">

      <div className="uk-card uk-margin-bottom uk-width-expand">

        <div className ="uk-card uk-padding-small uk-background-default">
                  
                    <div className="uk-card-title uk-flex uk-flex-between uk-text-medium">
                        Задача
                    <div className="uk-flex">
                      <div className="uk-margin-small-right">
                        {
                          permissionEdit && singleTask.status === 'new'
                          ?
                           <a href="#" className="uk-icon-link uk-icon  uk-text-primary" onClick={() => history(EDITTASK_ROUTE + '/' + singleTask.id_client + '/' + singleTask.id_object + '/' + singleTask.id)} uk-icon="file-edit"></a>
                          :
                          null
                        }
                        
                      </div>
             
                     
                    </div>
                    </div>
                     
                
                  <div className="uk-accordion-content" >
                    <div className="uk-flex uk-flex-between uk-flex-wrap">
                    <h4 className="">{singleTask.name}</h4> 
                    <div>
                      {
                        statuses.map(status => status.key === singleTask.status
                          ?
                       <span className="uk-label" style={{backgroundColor:status.status.color}}>{status.status.name}</span>  
                       :
                       null 
                        )
                      }
                      
                    </div>
                    
                    </div>

                    <div className="">
                      <p>{singleTask.description}</p>
                      <p>Клієнт: {
                          permissionGetClient
                            ?
                          <b><a onClick={() => history(CLIENTS_ROUTE + '/' + singleTask.id_client)}>{singleTask.link_clients_nick_name}</a></b>
                          :
                          <b>{singleTask.link_clients_nick_name}</b>
                          
                          }
                      </p>
                      <p>Об'єкт: {
                           permissionGetObject
                           ?
                          <b><a onClick={() => history(OBJECTS_ROUTE + '/' + singleTask.id_client + '/' + singleTask.id_object)}>{singleTask.link_objects_name}</a></b>
                          :
                          <b>{singleTask.link_objects_name}</b>
                         }
                        </p>
                        <p>Рівень: {
                        roles.map( role => role.key === singleTask.level
                          ?
                          <b>{role.role}</b>
                          :
                          null
                        )
                        }
                        </p>

                      <p>Дата виконання:
                        {singleTask.date === null
                          ?
                          task._term.map(term => 
                            term.key === singleTask.term
                            ?
                            <b> {term.type}</b>
                            :
                            null
                          )
                          :
                          <b> {singleTask.date}</b>
                        }
                        </p>

                      {singleTask.contacts
                      ?
                      singleTask.contacts.object 
                       ?
                      <div>
                        
                        <div className="uk-margin-small-right">Адреса: 
                          <a target="_blank" className="uk-margin-small-left" href={singleTask.contacts.object.link}>{singleTask.contacts.object.address}</a>
                        </div>  
                      <p>Телефон: 
                       <span className="uk-margin-small-left"> <Callto className="uk-margin-left" phone={singleTask.contacts.object.phone}>{singleTask.contacts.object.phone}</Callto></span>
                      </p>
                      </div>
                      :
                      null
                      :
                      null
                    }
                    {singleTask.contacts
                      ?
                      singleTask.contacts.person
                       ?
                      <p className="">Контакти: 
                       {singleTask.contacts.person.map(person => 
                        <div className="uk-flex uk-margin-large-left">
                          <div className="uk-margin-small-right">{person.name}</div>  
                          <div className="uk-margin-small-right">
                          <span className="uk-margin-small-left"> <Callto className="uk-margin-left" phone={person.phone}>{person.phone}</Callto></span>
                          </div>
                        </div>
                       )}
                       </p>
                      :
                      null
                      :
                      null
                    }
                    </div>

                    <div className="uk-card-footer">
                      <div className="uk-text-center uk-width-1-1">
                      <p className="uk-article-meta">Створив задачу: {singleTask.link_users_username} {singleTask.time_stamp}</p>

                    {permissionTaskStatus
                    ?
                    task._actions.map(action => 
                      statuses.map(status =>
                        status.key === action
                        ?
                        <button name={status.key} className="uk-button uk-button-primary uk-margin-right uk-margin-bottom" onClick={e => changeStatus(e)}>{status.status.name}</button>
                        :
                        null
                      )
                    )
                    :
                    null
                    }
                      </div>

                  </div>
                </div>
        </div>
      </div>

      
    </div>
  );
});

export default CardTask;