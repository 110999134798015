import React from "react";
import Table from "../components/Table";
import { observer } from "mobx-react-lite";


const Clients = observer(() => {

  return (
    <div className="uk-container uk-container-xlarge uk-padding-small"  uk-height-viewport>
            <Table title='Клієнти' />       
    </div>
  );
});

export default Clients;