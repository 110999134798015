import React, {useState, useEffect, useContext} from "react";
import EditObject from '../pages/EditObject'
import { observer } from "mobx-react-lite";
import { useParams, useNavigate } from "react-router-dom";
import { crmRequest } from "../http/userAPI";
import { EDITOBJECT_ROUTE } from "../utils/consts";
import { Context } from "..";

const CardObject = observer(({object}) => {
  const {user} =useContext(Context);
  
  const params = useParams();
  const history = useNavigate();
  const [changeObject, setChangeObject] = useState(object);
  const permissionEdit = user._permissions.includes('objects/edit');
 
  useEffect(()=> {
    setChangeObject({...object});
  },[object])

  const Callto = ({ phone, children }) => {
    return <a href={`tel:${phone}`}>{children}</a>;
  };

  

  return (
    <div uk-grid className="uk-grid uk-margin-small" >

      <div  className="uk-card uk-width-expand">

        <div className ="uk-margin-bottom uk-padding-small uk-background-default">

                    <div className="uk-card-title uk-text-medium uk-flex uk-flex-between">
                      <div>
                      Об'єкт: {changeObject.name}
                      </div>
                      <div  className="uk-flex">
                        <div>
                          {
                            permissionEdit
                            ?
                            <a href="#" className="uk-icon-link uk-icon uk-text-primary uk-margin-medium-left" onClick={() => history(EDITOBJECT_ROUTE + '/' + params.clientId  + '/'+ changeObject.id)} uk-icon="file-edit"></a>
                            :
                            null
                          }

                        </div>
                        <div>
                        {changeObject.active === '1'
                          ?
                          <a href="#" className="uk-icon-link uk-icon uk-text-success uk-margin-small-left"  uk-icon="unlock"></a>
                          :
                          <a href="#" className="uk-icon-link uk-icon uk-text-danger uk-margin-small-left"  uk-icon="lock"></a> 
                          } 
                        </div>
                      </div>
                    </div>
                
                  <div className="uk-accordion-content" >

                      <div className="" style={{color: '#666'}}>
                        <p>Адреса: <b>{object.address}</b></p>
                        <p>Номер телефону: <b><Callto phone={object.phone}>{object.phone}</Callto></b></p>
                        <p>Коментар: <b>{object.notes}</b></p>
                        <p>Ширина: <b>{object.latitude}</b></p>
                        <p>Довжина: <b>{object.longitude}</b></p>
                      </div>

          <div className="uk-card-footer">

            
            <div>
              {/* <div uk-grid className="uk-grid uk-margin-bottom uk-margin-top">
                
                <div className="uk-width-1-2@m uk-first-colum">
                  <p className="uk-article-meta uk-margin-small">Дата редагування 14.04.2024 </p>
                </div>
                <div className="uk-width-1-2@m">
                  <p className="uk-article-meta">Дата блокування 20.06.2024</p>
                </div>
              </div>*/}
                <div className="uk-grid uk-flex uk-flex-between uk-text-center">
                  <div className="uk-width-1-1 uk-first-colum uk-text-small uk-margin-bottom">
                    <p className="uk-article-meta">Додано <time datetime="2024-03-15T19:00">{object.time_stamp}</time></p>
                  </div>
                {/* 
                  <div className="uk-margin-bottom uk-width-1-1 uk-first-column uk-text-small uk-border-rounded">
                    <button className="uk-button  uk-button-primary uk-text-small uk-border-rounded" uk-toggle="target: #modal-add-task">Додати задачу</button>
                  </div>*/}
                </div>
            </div>
            
            
          </div>
        </div>
             
        </div>
      </div>



      
    </div>
  );
});

export default CardObject;