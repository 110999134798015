import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import TableTasks from "../components/TableTasks";
import { Context } from "..";
import { crmRequest } from "../http/userAPI";


const Tasks = observer(() => {
  const {user} = useContext(Context);
 

  return (
    <div className="uk-container uk-container-xlarge uk-padding-small">
      <TableTasks title='Задачі'/>
    </div>
  );
});

export default Tasks;