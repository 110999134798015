import React, {useState, useEffect} from "react";

export const useValidation = (value, validations) => {
  const [isEmpty, setEmpty] = useState(true);
  const [minLengthError, setMinLengthError] = useState(false);
  const [maxLengthError, setMaxLengthError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [latitudeError, setLatitudeError] = useState(false);
  const [inputValid, setInputValid] = useState(false);


  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case 'minLength' :
            value.length < validations[validation] ? setMinLengthError(true) : setMinLengthError(false)
            break;
        case 'isEmpty' :
            value ? setEmpty(false) : setEmpty(true)
            break
        case 'maxLength' :
            value.length > validations[validation] ? setMaxLengthError(true) : setMaxLengthError(false)
            break; 
        case 'phoneError' :
          const re = /\+\d{12,15}$/;
          re.test(String(value).toLocaleLowerCase()) ? setPhoneError(false) : setPhoneError(true)
            break
        case 'latitudeError' :
           const regLaltitude = /\d{2}\.\d{2,7}$/;
           regLaltitude.test(String(value).toLocaleLowerCase()) ? setLatitudeError(false) : setLatitudeError(true)
            break
      }
        
    }

  }, [value])

  useEffect(() => {
    if(isEmpty || minLengthError || maxLengthError || phoneError){
      setInputValid(false);
    }else{
      setInputValid(true);
    }

  }, [isEmpty, minLengthError, maxLengthError, phoneError])

  return {
    isEmpty,
    minLengthError,
    phoneError,
    maxLengthError,
    latitudeError,
    inputValid
  }
}