import React, { useEffect, useState, useContext }  from "react";
import { Context } from "..";
import { useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import CardTask from '../components/CardTask';
import { HOME_ROUTE, TASKS_ROUTE} from "../utils/consts";
import AddFoto from '../components/modalsForm/AddFoto';
import { crmRequest } from "../http/userAPI";
import AddComment from "../components/modalsForm/AddComment";


const Task = observer(({clients, objects}) => {
  const { task, user} = useContext(Context);
  const history = useNavigate();
  const [singleTask, setSingleTask] = useState({});
  const [statusAdd, setStatusAdd] = useState('');
  const [statusId, setStatusId] = useState('');
  const [actionTask, setActionTask] = useState('');
  const statuses = [];
  const [modalActive, setModalActive] = useState(false);
  const [modalPhotoActive, setModalPhotoActive] = useState(false);
  const permissionPhoto = user._permissions.includes('tasks/photo');
  const permissionComment = user._permissions.includes('tasks/note');
  const [permissionActionForTable, setPermissionForAction] = useState(true)
  const permissionList = user._permissions.includes('tasks/list');

  //console.log(user._status)

  useEffect(() => {
     if(permissionPhoto && permissionComment) {      
    setPermissionForAction(false)
  }
  }, [])

  

  for (let key in user._status) {
    if (user._status.hasOwnProperty(key)) {
      let statusOject = {key: key, status: user._status[key]} 
      statuses.push(statusOject);
    }
  }

  
  const params = useParams(); 
 

  let requestBody = {
    "id":params.id,
  }

 
    const oneTask = async () => {
    let task = await crmRequest('tasks/get', true, requestBody);
    return task;
  }

  const commentTask = async () => {
    let task = await crmRequest('tasks/info', true, requestBody);
    return task;
  }

  let requestStatus = {
    "id": singleTask.id,
    "status": actionTask,
  }


  const statusChange = async () => {
    let status = await crmRequest('tasks/status', true, requestStatus);
    return status;
  }

  const changeStatus = (e) => {

       switch(e.target.name) {
        case 'deferred':  
        let isDeferred = window.confirm("Ви впевнені, що хочете відкласти задачу?");
        if(isDeferred){
          setActionTask(e.target.name)
         }else return
          break;

          case 'new':  
          let isNew = window.confirm("Ви впевнені, що хочете змінити статус на новий?");
          if(isNew ){
            setActionTask(e.target.name)
           }else return
            break;

            case 'accepted':  
            let isAccepted = window.confirm("Ви впевнені, що хочете прийняти задачу?");
            if(isAccepted){
              setActionTask(e.target.name)
             }else return
              break;

            case 'working':  
            let isWorking = window.confirm("Ви впевнені, що хочете прийняти задачу в роботу?");
            if(isWorking){
              setActionTask(e.target.name)
            }else return
              break;

            case 'done':  
            let isDone = window.confirm("Ви впевнені, що хочете змінитис статус задачі на Виконаний?");
            if(isDone){
              setActionTask(e.target.name)
              }else return
                break;

            case 'not_done':  
             let isNotDone = window.confirm("Ви впевнені, що хочете змінити статус задачі на Не Виконана?");
             if(isNotDone){
                  setActionTask(e.target.name)
              }else return
                break;

                case 'repeat':  
                let isRepeat = window.confirm("Ви впевнені, що хочете змінити статус задачі Повторити?");
                if(isRepeat){
                    setActionTask(e.target.name)
                 }else return
                   break;
                  
               case 'canceled':  
               let isCanceled = window.confirm("Ви впевнені, що хочете скасувати задачу?");
                if(isCanceled){
                      setActionTask(e.target.name)
                    }else return
                      break;
      
  
      
    }
    //setActionTask(e.target.name);
   }

   const keyHandler = (e) => {
    e.preventDefault();
   // console.log('+') ;
}


   useEffect(()=> {
   if(actionTask !== ''){
     statusChange().then(idStatus => {
      task.setStatusId(idStatus.id);
     });
     setActionTask('');
   }
   }, [actionTask])


    useEffect(() => {
      oneTask().then(ts => {
        setSingleTask(ts);
        task.setActions(ts.actions);
      })
      commentTask().then(comm => {
        task.setComments(comm)
      })
    }, [actionTask, task._statusId])

    //console.log(modalActive);




  return (
    <div className="uk-container uk-container-xlarge uk-padding-small"  uk-height-viewport>
    
  
      
      <div className ="uk-card uk-card-small uk-card-default uk-card-title">
      <nav aria-label="Breadcrumb">
        <ul className="uk-breadcrumb uk-padding-small uk-margin-top">
            <li><a href="#" uk-icon="icon: home"  onClick={() => history(HOME_ROUTE)}></a></li>
            
              {
                permissionList
                ?
               <li> <a href="#" onClick={() => history(TASKS_ROUTE)}><span className="uk-link">Задачі</span></a></li>
                :
                null
              }
              
            <li><span aria-current="page">{singleTask.name}</span></li>
        </ul>
      </nav>
    </div>

      <div className="uk-grid-small uk-flex uk-flex-wrap">
        <div className="uk-width-1-2@m uk-width-1-3@l uk-width-1-1 uk-first-column">
        <CardTask singleTask={singleTask} changeStatus={changeStatus}/>

        </div>

        <div className="uk-width-1-2@m uk-width-2-3@l uk-margin-bottom">
          <div className="uk-card uk-card-default uk-card-hover">
                  <h3 className="uk-margin-small-left">Фото і коментарі до задач</h3>
                  <div className="uk-card-body">
                    <table className="uk-table uk-table-small uk-table-divider">
                                  
                      <thead>
                        <tr>
                          <th className="uk-text-small" style={{fontSize:'12px'}}>Статус задачі</th>
                          <th className="uk-text-small" style={{fontSize:'12px'}}>Змінив статус</th>
                          <th className="uk-text-small uk-visible@s" style={{fontSize:'12px'}}>Дата статусу</th> 
                          {
                            permissionActionForTable
                           ?
                           <th className="uk-text-small" style={{fontSize:'12px'}}>Дії</th>
                           :
                           null
                          }
                          
                        </tr>
                              
                      </thead>
                      
                          {
                            task.comments.length !== 0
                            ?
                            task.comments.map( elem => 
                            
                             <tbody>   
                              
                              <tr>
                                <td className="uk-text-left uk-text-small" style={{fontSize:'11px'}}>
                                  {statuses.map (status => 
                                    status.key === elem.status
                                    ?
                                    <span className="uk-label uk-text-small" style={{backgroundColor:status.status.color, fontSize:'11px', paddingLeft: '5px', paddingRight: '5px'}}>{status.status.name}</span>
                                    :
                                    null
                                  )}
                                  </td>
                                <td className="uk-text-left uk-text-small" style={{fontSize:'12px'}}>{elem.link_users_username}</td>
                                <td className="uk-text-left uk-text-small uk-visible@s">{elem.time_stamp}</td>
                                <td className="uk-text-small">
                                  {permissionPhoto
                                  ?
                                  <p><button type="button" style={{fontSize: '12px'}} idStatus={elem.id} onClick={() => {setModalPhotoActive(true); setStatusAdd(elem.status); setStatusId(elem.id)}} className="uk-button uk uk-margin-large-left@s uk-text-center uk-button-primary">Фото</button></p>
                                  :
                                  null
                                  }
                                 {permissionComment
                                  ?
                                  <p><button type="button" style={{fontSize: '11px'}} idStatus={elem.id}  onClick={() => {setModalActive(true); setStatusAdd(elem.status); setStatusId(elem.id)}} className="uk-button uk-margin-large-left@s uk-text-center uk-button-primary">Коментар</button></p>
                                  :
                                  null
                                  }
                                </td>
                               </tr> 
                             <tr>
                                {
                                  elem.photos.length !== 0
                                  ?
                                <td colspan="4">
                                    <details>
                                      <summary>Подивитись фото</summary>
                                          <div className="uk-flex uk-flex-wrap uk-flex-between" style={{gap: '20px'}}>
                                            
                                            {
                                              elem.photos.map( photo =>
                                             <div className="uk-flex" style={{gap: '20px'}} uk-grid uk-lightbox="animation: slide">   
                                              <div className="uk-margin-auto-left uk-card uk-active uk-flex" >
                                             
                                                
                                               <div className="uk-active uk-card uk-link-reset" >
                                                
                                                <a className="uk-inline"  href={"https://crm.pm-pool.com.ua/"+photo.folder + '/' + photo.optimized}>
                                                <div className="uk-card  uk-card-default">
                                                  <div className="uk-text-center" style={{paddingTop: '10px'}}>{photo.time_stamp}</div>
                                                  <img  className="uk-object-cover"  src={"https://crm.pm-pool.com.ua/"+photo.folder + '/' + photo.thumbnail} width="200" height="200" alt="pool1" style={{aspectRatio: "1 / 1"}}/>
                                                  <div className="uk-text-center" style={{paddingBottom: '10px'}}>{photo.link_users_username}</div>
                                                  {/*
                                                  <button className="uk-offcanvas-close uk-icon uk-close  uk-text-muted  " type="button" uk-close aria-label='Close' uk-toggle="target: .deleteFoto1">
                                                    <svg width="14" height='14' viewBox='0 0 14 14'>
                                                      <line fill='none' stroke='#000' strokeWidth='1.1' x1='1' y1='1' x2='13' y2='13'></line>
                                                      <line fill='none' stroke='#000' strokeWidth='1.1' x1='13' y1='1' x2='1' y2='13'></line>
                                                    </svg>
                                                  </button> */}
                                                </div>
                                                </a>
                                               </div>
                                            
                                             </div>
                                             </div>
                                              )
                                            }
                                            </div>
                                          
                                        

                                      </details>
                                </td>
                                  :
                                  null
                                }
                              </tr>
                              <tr>
                                {
                                  elem.notes.length !== 0
                                  ?
                                  <td colspan="4">
                                    <details>
                                      <summary>Подивитись коментарі</summary>
                                       { 
                                        elem.notes.map(note => 
                                      <div className="uk-flex" style={{gap: '20px'}}>
                                          <div>{note.link_users_username}</div>
                                          <div>{note.time_stamp}</div>
                                          <div>{note.note}</div>
                                      </div>
                                          )
                                        }
                                    </details>
                                 </td>
                                 :
                                  null
                                }
                              </tr>
                            </tbody>
                              )
                             :
                            null
                          }
                    </table>
                  </div>

          </div>
        </div>
        
      </div>


  
      {modalActive && <AddComment active={modalActive} status={statusAdd} statusId={statusId} setActive={setModalActive} />}
    
     {modalPhotoActive && <AddFoto active={modalPhotoActive} status={statusAdd} statusId={statusId} setActive={setModalPhotoActive} keyHandler={keyHandler}/>} 
    </div>
  );
});

export default Task;