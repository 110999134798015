import { makeAutoObservable } from "mobx";
export default class TaskCrm {
  constructor() {
    this._task = [];
    this._term = [
      {type: 'без терміну', key: "free"},
      {type: 'попутно', key: "passing"},
      {type: 'на дату', key: "date"}
    ]
    this._page = 1;
    this._totalCount = 0;
    this._limit = 5;
    this._taskClients = {};
    this._taskObjects = {};
    this._taskUsers = [];
    this._taskLevels = [];
    this._actions =[];
    this._comments = [];
    this._statusId = '';
    this._taskForDashboard = {};

    makeAutoObservable(this)
  }

  setTask(task) {
    this._task = task
  }

  setCategory(category) {
    this._category = category
  }
  setPage(page) {
    this._page = page
  }
  setTotalCount(totalCount) {
    this._totalCount = totalCount
  }
  setLimit(limit) {
    this._limit= limit
  }
  setTaskClients(taskClients) {
    this._taskClients= taskClients
  }
  setTaskObjects(taskObjects) {
    this._taskObjects= taskObjects
  }
  setTaskUsers(taskUsers) {
    this._taskUsers= taskUsers
  }
  setTaskLevels(taskLevels) {
    this._taskLevels= taskLevels
  }

  setTerm(term) {
    this._term = term
  }

  setActions(actions) {
    this._actions = actions
  }

  setComments(comments) {
    this._comments = comments
  }

  setStatusId(statusId) {
    this._statusId = statusId
  }

  setTaskForDashboard(taskForDashboard){
    this._taskForDashboard = taskForDashboard
  }
  

  get task() {
    return this._task
  }

  get taskStatus() {
    return this._taskStatus
  }
  get category() {
    return this._category
  }
  get page() {
    return this._task
  }
  get totalCount() {
    return this._totalCount
  }
  get limit() {
    return this._limit
  }
  get taskClients() {
    return this._taskClients
  }
  get taskObjects() {
    return this._taskObjects
  }
  get taskUsers() {
    return this._taskUsers
  }
  get taskLevels() {
    return this._taskLevels
  }

  get term() {
    return this._term
  }
  get actions() {
    return this._actions
  }

  get comments() {
    return this._comments
  }

  get statusId () {
    return this._statusId
  }
  get taskForDashboard () {
    return this._taskForDashboard
  }
}