import React from "react";
import CardTaskDashbord from './CardTaskDashbord';
//import PlusTask from "./PlusTask";

const ListTasks = ({tasks}) => {

  

  return (

          <div>

            {tasks.length !== 0
               ?
               tasks.map(obj => 
                <CardTaskDashbord object={obj}/>
               )
              :
              null}

         </div>    
  );
};

export default ListTasks;