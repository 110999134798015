import React, { useContext, useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { crmRequest } from "../../http/userAPI";
import { useInput } from "../hooks/useInput";
import { Context } from "../..";


const AddUser = observer(({active, setActive}) => {
  const {user} = useContext(Context);
  const offset = user._limit * (user._page - 1);
  const username = useInput('', {isEmpty: true, minLength: 2, maxLength: 100});
  const first_name = useInput('', {isEmpty: true, minLength: 2, maxLength: 100});
  const last_name = useInput('', {isEmpty: true, minLength: 2, maxLength: 100});
  const phone = useInput('', {isEmpty: true, maxLength: 16, phoneError: false});
  const password = useInput('', {isEmpty: true, minLength: 3, maxLength: 100});
  const [role, setRole] = useState('');
  const modalRef = useRef(null);
  const [roleDirty, setRoleDirty] = useState(false);
  const [roleError, setRoleError] = useState("");
  const [formValid, setFormValid] = useState(false);

  useEffect(()=> {
    if(role === ""){
      setFormValid(false)
    } else {
      setFormValid(true)
    }

  },[role])


  const roleHandler = (e) => {

    if(e.currentTarget.value === "") {
      setRoleError('Потрібно вибрати роль')
    }
    else{
      setRole(e.currentTarget.value)
      setRoleError('')
    }
   }

   const blurHandlerRole = (e) => {
   
    switch (e.target.name) {
      case 'role' :
        setRoleDirty(true)
        break
    }
  }


  const infoUser = {
    "username": username.value,
    "password": password.value,
    "first_name": first_name.value,
    "last_name": last_name.value,
    "phone": phone.value,
    "role": role
  }
 

  let requestBody = {
    "limit": user._limit,
    "offset": offset,
    "active": [1,0], 
    "search": ''
  }


    const userList = async () => {
    let user = await crmRequest('users/list', true, requestBody);
    return user;
  }

  const addUser = async () => {
    
    let addUs = await crmRequest('users/add', true, infoUser);
    userList().then(us => {
      user.setUser(us.list)
      user.setTotalCount(us.count)
    });
    setActive(false);
    username.value = '';
    password.value = '';  
    first_name.value = ''; 
    last_name.value = ''; 
    phone.value = ''; 
    role = ''; 
    
  }

  const keyHandler = (e) => {
    e.preventDefault(); // запобігає стандартній поведінці Enter
      addUser(); 
    // if (e.key === 'Enter') { //якщо натиснута клавіша Enter
    //   e.preventDefault(); // запобігає стандартній поведінці Enter
    //   addUser(); // викликаємо обробник відправки форми
    }
  


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setActive(false); 
      }
    };

    const handleTab = (event) => {
      if (event.key === 'Tab' && modalRef.current) {
        const focusableElements = modalRef.current.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const touchAction = 'none';
        document.documentElement.style.touchAction= touchAction;
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];
      


        if (event.shiftKey) { // Shift + Tab
          if (document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          }
        } else { // Tab
          if (document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }
    };

    // Додаємо обробчик подій
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keydown', handleTab);

    // Видаляємо обробчик подій при розмонтурованні компонента
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keydown', handleTab);
    };
  }, [setActive]);

  if (!active) return null; // Якщо модальне вікно закрито, нічого не рендеримо




  return (
    <div id="modal-add-user" uk-modal className={active ? "uk-modal uk-open" : "uk-modal"} style={{zIndex: '1011', display: 'block'}}>
          
    <div className="uk-modal-dialog uk-modal-body" role='alertdialog' aria-modal='true' onClick={e => e.stopPropagation()}>
       <h2 className="uk-modal-title">Додайте нового користувача</h2>

       <form className="uk-form-stacked" onSubmit={e => keyHandler(e)}>
        <div className ="uk-card">

      <div className="uk-card-body uk-padding-small">


        <div  className="uk-margin uk-text-left">
          <label className="uk-form-label" for="userNameRule">Логін працівника <span className="uk-text-danger">*</span></label>
          <div className="uk-form-controls">
              {(username.isDirty && username.isEmpty) && <div className="uk-text-danger">Поле не може бути порожнім</div>}
              {(username.isDirty && username.minLengthError ) && <div className="uk-text-danger">Довжина закоротка</div>}
              {(username.isDirty && username.maxLengthError ) && <div className="uk-text-danger">Довжина задовга</div>}
            <input name="username" tabindex="0"  onBlur={e => username.onBlur(e)} className="uk-input" value={username.value} onChange={e => username.onChange(e)} id="userNameRule" type="text" maxlength="100" placeholder="Логін"/>
          </div>
          </div>

          <div  className="uk-margin uk-text-left">
            <label className="uk-form-label" for="passwordRule">Пароль працівника <span className="uk-text-danger">*</span></label>
            <div className="uk-form-controls">
              {(password.isDirty && password.isEmpty) && <div className="uk-text-danger">Поле не може бути порожнім</div>}
              {(password.isDirty && password.minLengthError ) && <div className="uk-text-danger">Довжина закоротка</div>}
              {(password.isDirty && password.maxLengthError ) && <div className="uk-text-danger">Довжина задовга</div>}
              <input name="password" tabindex="0"  onBlur={e => password.onBlur(e)} className="uk-input" id="passwordRule" value={password.value} onChange={e => password.onChange(e)} type="text" maxlength="100" placeholder="Пароль"/>
            </div>
          </div>

          <div  className="uk-margin uk-text-left">
            <label className="uk-form-label" for="firstNameRule">Ім'я працівника</label>
            <div className="uk-form-controls">
              {(first_name.isDirty && first_name.maxLengthError ) && <div className="uk-text-danger">Довжина задовга</div>}
              <input name="first_name" tabindex="0" onBlur={e => first_name.onBlur(e)} className="uk-input" id="firstNameRule" value={first_name.value} onChange={e => first_name.onChange(e)} type="text" maxlength="100" placeholder="Ім'я"/>
            </div>
          </div>

          <div  className="uk-margin uk-text-left">
            <label className="uk-form-label" for="lastNameRule">Прізвище працівника</label>
            <div className="uk-form-controls">
              {(last_name.isDirty && last_name.maxLengthError ) && <div className="uk-text-danger">Довжина задовга</div>}
              <input name="lastName" tabindex="0"  onBlur={e => last_name.onBlur(e)} className="uk-input" id="lastNameRule" value={last_name.value} onChange={e => last_name.onChange(e)} type="text" maxlength="100" placeholder="Прізвище"/>
            </div>
          </div>
         

          <div  className="uk-margin  uk-text-left">
            <label className="uk-form-label" for="phoneRule">Номер телефону працівника <span className="uk-text-danger">*</span></label>
            <div className="uk-form-controls">
                {(phone.isDirty && phone.isEmpty) && <div className="uk-text-danger">Поле не може бути порожнім</div>}
                {(phone.isDirty && phone.minLengthError ) && <div className="uk-text-danger">Довжина не корректна</div>}
                {(phone.isDirty && phone.phoneError ) && <div className="uk-text-danger">Не корректний номер</div>}
              <input name="phone" tabindex="0" onBlur={e => phone.onBlur(e)} className="uk-input" id="phoneRule" value={phone.value} onChange={e => phone.onChange(e)} type="tel" maxlength="16" placeholder="+380660010001"/>
            </div>
          </div>

          
          <div className="uk-margin uk-text-left">
            <label className="uk-form-label" for="form-horizontal-select">Посада працівника <span className="uk-text-danger">*</span></label>
            <div className="uk-form-controls">
            {(role.isDirty && roleError) && <div className="uk-text-danger">Поле не може бути порожнім</div>}
              <select className="uk-select" name='role' onKeyDown={keyHandler} onBlur={e => blurHandlerRole(e)} id="form-horizontal-select" onChange={e => roleHandler(e)}>
               <option value="">-</option>
                {user._rolesFilter.map(elem => 
                  <option
                  key={elem[0]}
                  value={elem[0]}
                  >{elem[1]}</option>
                )}
              </select>
            </div>
          </div>
      
        </div>
      </div>
    
  
           
       <p className="uk-text-right">
       <button className="uk-button uk-button-default" type="button" tabindex="0" uk-toggle="target: #modal-add-user" onClick={() => setActive(false)}>Відмінити</button>
        <button className="uk-button uk-button-primary" disabled={!username.inputValid || !phone.inputValid || !password.inputValid || !formValid} type="submit" uk-toggle="target: #modal-add-user">Зберегти</button>
       </p>
</form>

     </div>        
</div>
  );
});

export default AddUser;