import React, {useState, useEffect, useContext} from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import Pagination from './Pagination';
import { CLIENTS_ROUTE } from "../utils/consts";
import AddClient from "./modalsForm/AddClient";
import { Context } from "..";
import { crmRequest } from "../http/userAPI";


const Table = observer(({title}) => {
  const {client, user} = useContext(Context);
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [countClients, setCountClients] = useState({"active": [1,0], "search": ''});
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const offset = client._limit * (selectedPage - 1);
  const [modalActive, setModalActive] = useState(false);
  
  const [searchTimeout, setSearchTimeout] = useState(false);
  const permissionAdd = user._permissions.includes('clients/add');
  const permissionList = user._permissions.includes('clients/list');
  const permissionGet = user._permissions.includes('clients/get');

 
 const filterClients = (category, search) => {
    setSearchQuery(search);
    setCountClients({"active": category, "search": search})
    setSelectedPage(1);
    
  }

  const chosenPage = (page) => {
    setSelectedPage(page);
  }

 
 let requestBody = {
    "limit": client._limit,
    "offset": offset,
    "active": countClients.active, 
    "search": searchQuery
  }

  const clientList = async () => {
    let cl = await crmRequest('clients/list', true, requestBody);
    return cl;
  }


  useEffect(()=> {
    if(searchQuery.length > 0) {

     if(searchTimeout != false){
      clearTimeout(searchTimeout)
    }
    setSearchTimeout(setTimeout(() => {
      clientList().then(cl => {
        client.setTotalCount(cl.count);
        client.setClients(cl.list);
    }).finally(() => setLoading(false))
    }, 500))
  }else {
    clientList().then(cl => {
      client.setTotalCount(cl.count);
      client.setClients(cl.list);
  }).finally(() => setLoading(false))
  }
  }, [countClients, searchQuery, offset, selectedPage])


  const keyHandler = (e) => {
       e.preventDefault();
   }

   const Callto = ({ phone, children }) => {
    return <a href={`tel:${phone}`}>{children}</a>;
  };
 

  return (
    <div className="uk-card uk-card-default uk-card-hover ">

      <div>

        <nav className="uk-flex uk-flex-wrap uk-flex-between" style={{paddingTop: '10px', paddingRight: '10px'}}>
          
          <div className="uk-width-1-6@m  uk-width-1-4  uk-first-column">
              <h3 className="uk-margin-small-left uk-text-start">{title}</h3>
          </div>

          <div className="uk-width-1-1 uk-width-1-2@m  uk-flex-last">
              <div className="uk-margin-small uk-flex uk-flex-between uk-flex-wrap uk-margin-right uk-margin-remove-right@m">
                <form className="uk-search uk-search-default uk-flex uk-margin-small-left uk-margin-large-right uk-margin-bottom" style={{width:"250px"}}>
                    <input className="uk-search-input" value={searchQuery} onChange={e => filterClients(countClients.active, e.target.value)} type="search" placeholder="Пошук клієнта..." aria-label="Search"/>
                    <span className="uk-search-icon-flip uk-position-center-right uk-article-meta" uk-icon style={{paddingRight: '10px', paddingBottom: '3px'}}>
                      <svg width='20' height='20' viewBox="0 0 20 20" >
                        <circle fill='none' stroke='#000' stroke-width='1.1' cx='9' cy='9' r='7'></circle>
                        <path  fill='none' stroke='#000' stroke-width='1.1' d='M14,14 L18,18 L14,14 Z'></path> 
                      </svg>
                    </span>
                </form>
                <div>
                <select onChange={(e)=> filterClients(Array.from(e.currentTarget.value), searchQuery)} className="uk-select uk-margin-small-left" aria-label="Select ">
                  {client._category.map(category => 
                    <option
                    key={category.id}
                    value={category.active}>
                    {category.type}
                    </option>
                  )}
                </select>
                </div>
            </div>
          </div>

          <div className="uk-width-1-4@m uk-width-2-3 uk-first-column  uk-flex-last@m uk-margin-small-bottom">
            {
              permissionAdd 
              ?
              <button className="uk-button uk-float-right uk-button-primary"  style={{textTransform: 'none'}} onClick={() => setModalActive(true)}>Додати клієнта</button>
              :
              null
            }
              
          </div>
          
        </nav>
      


          <div className="uk-card-body">
          <table className="uk-table uk-table-small uk-table-divider" style={{minHeight: "500px"}}>
            
            <thead>
              <tr>
                <th>Назва клієнта</th>
                <th className="uk-visible@s">Ім'я</th>
                <th className="uk-visible@s">прізвище</th>
                <th>Номер телефону</th>
                <th className="uk-visible@s">Статус</th>
              </tr>
         
            </thead>
            {loading
                ?
                <div  uk-spinner="ratio: 1"></div>
                 :
            <tbody>
              { client._clients.length !== 0 && permissionList
                ?
              client._clients.map(obj =>
                <tr key={obj.id} >
                 {
                  permissionGet
                  ? 
                <td className="uk-text-left" >
                 <a  onClick={() => history(CLIENTS_ROUTE + '/' + obj.id)}>{obj.nick_name}</a>
                 </td>
                  :
                  <td className="uk-text-left">
                  {obj.nick_name}
                  </td>
                 }
                  
                <td className="uk-visible@s uk-text-left" >{obj.first_name}</td>
                <td className="uk-visible@s uk-text-left" >{obj.last_name}</td>
                <td className="uk-text-left" ><Callto phone={obj.phone}>{obj.phone}</Callto></td>
                <td className="uk-visible@s uk-text-left">
                  {obj.active === "1"
                    ?
                   <a href="#" className="uk-icon-link uk-icon uk-text-success uk-margin-small-left" uk-icon="unlock"></a>
                   :
                   <a href="#" className="uk-icon-link uk-icon uk-text-danger uk-margin-small-left"uk-icon="lock"></a>
                  }</td>
               </tr>
               )
              :
              <p className='uk-text-bold uk-margin-small-left uk-margin-top uk-text-uppercase' style={{textAlign: 'start'}}>Клієнти не знайдені</p>
              }
              
              </tbody>
              }
          </table>

        </div>
  
        <div className="uk-card-footer">
          <Pagination limit={client._limit} totalCount={client._totalCount} chosenPage={chosenPage} selectedPage={selectedPage}/>
        </div>
        </div>


 {modalActive && <AddClient active={modalActive} setActive={setModalActive} keyHandler={keyHandler}/>} 

    </div>
  );
});

export default Table;