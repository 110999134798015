import { makeAutoObservable } from "mobx";

export default class ObjectCrm {
  constructor() {
    this._object = [];
    this._category =[
      {id: 1, type: 'усі об*єкти', active: [1, 0]},
      {id: 2, type: 'активні', active: [1]},
      {id: 3, type: 'не активні', active: [0]}
    ]
    this._currentObject = {};
    this._page = 1;
    this._totalCount = 0;
    this._limit = 5;

    makeAutoObservable(this)
  }

  setObject(object) {
    this._object = object
  }
  setCategory(category) {
    this._category = category
  }
  setCurrentObject(currentObject) {
    this._currentObject = currentObject
  }
  setPage(page) {
    this._page = page
  }
  setTotalCount(totalCount) {
    this._totalCount = totalCount
  }
  setLimit(limit) {
    this._limit = limit
  }

  get object() {
    return this._object
  }
  get category() {
    return this._category
  }
  get page() {
    return this._page
  }
  get totalCount() {
    return this._totalCount
  }
  get limit() {
    return this._limit
  }
  get currentObject() {
    return this._currentObject
  }

}