import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams} from "react-router-dom";
import TableTrusteds from "../components/TableTrusteds";
import CardObject from '../components/CardObject';
import {CLIENTS_ROUTE, HOME_ROUTE } from "../utils/consts";

import { crmRequest } from "../http/userAPI";

const Object = observer(() => {
  const [singleObject, setSingleObject] = useState({});
  const [singleClient, setSingleClient] = useState({});
  const history = useNavigate();
  const params = useParams(); 
  
 

  let requestBody = {
    "id_client": params.clientId,
    "id": params.objectId,
  }


  const oneObject = async () => {
    let obj = await crmRequest('objects/get', true, requestBody);
    return obj;
  }


  let requestBodyClient = {
    "id": params.clientId,
  }
  
  const oneClient = async () => {
    let cl = await crmRequest('clients/get', true, requestBodyClient);
    return cl;
  }

  useEffect(() => {
  
    oneObject().then(obj => {
      setSingleObject(obj);
    })
    oneClient().then(cl => {
      setSingleClient(cl);
    })
    
    },[])


  return (
    <div className="uk-container uk-container-xlarge uk-padding-small" style={{paddingTop: '10px'}}>
    
      <div className ="uk-card uk-card-small uk-card-default uk-card-title" >
      <nav aria-label="Breadcrumb">
        <ul className="uk-breadcrumb uk-padding-small uk-margin-small-top">
            <li><a href="#" uk-icon="icon: home"  onClick={() => history(HOME_ROUTE)}></a></li>
            <li><a href="#"  onClick={() => history(CLIENTS_ROUTE)}><span className="uk-link">Клієнти</span></a></li>
            <li><a href="#" onClick={() => history(CLIENTS_ROUTE + '/' + singleClient.id)}><span className="uk-link">{singleClient.nick_name}</span></a></li>
            <li><span aria-current="page">{singleObject.name}</span></li>
        </ul>
      </nav>
    </div>

      <div className="uk-grid-small uk-flex uk-flex-wrap">
        <div className="uk-width-1-2@s uk-width-1-3@m uk-width-1-1 uk-first-column">
          <div className="">
            <div className="">
             
            <div uk-grid className="uk-grid uk-margin-small">

              <div className="uk-card uk-width-expand">

                 <div className ="uk-padding-small uk-background-muted">
       
                    <div className="uk-card-title uk-text-medium  uk-flex uk-flex-between">
                      <div>
                      Клієнт: {singleClient.nick_name}
                      </div>
                      <div className="uk-flex">
                        
                        {singleClient.active === '1'
                        ? 
                        <a href="#" className="uk-icon-link uk-icon uk-text-success uk-margin-small-left" uk-icon="unlock"></a>
                        :
                        <a href="#" className="uk-icon-link uk-icon uk-text-danger uk-margin-small-left" uk-icon="lock"></a>
                        }
                        
                      </div>
                    </div>
                  
                  <div className="uk-accordion-content">
                   
                    <div className="" style={{color: '#666'}}>
                      <p>Ім'я клієнта: <b>{singleClient.first_name}</b></p>
                      <p>Прізвище клієнта: <b>{singleClient.last_name}</b></p>
                      <p>Номер телефону: <b><a href="tel:{singleClient.phone}">{singleClient.phone}</a></b></p>
                      <p>Коментар: <b>{singleClient.notes}</b></p>
                    </div>

                    <div className="uk-card-footer">
                      
                      <div>
                       {/* <div uk-grid className="uk-grid uk-margin-bottom uk-margin-top">
                          <div className="uk-width-1-2@m uk-first-colum">
                            <p className="uk-article-meta uk-margin-small">{}</p>
                          </div>
                          <div className="uk-width-1-2@m">
                            <p className="uk-article-meta">{}</p>
                          </div>
                        </div>*/}
                        <div className="uk-grid uk-flex uk-flex-between uk-text-center">
                          <div className="uk-width-1-1 uk-first-colum uk-text-small">
                            <p className="uk-article-meta">Додано <time datetime="2024-03-15T19:00">{singleClient.time_stamp}</time></p>
                          </div>
                       </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>

            <CardObject object={singleObject}/>
             
            </div>
          </div>
        </div>

        <div className="uk-width-1-2@s uk-width-2-3@m uk-width-1-1 uk-margin-bottom">
          <div className="uk-card uk-card-default uk-card-hover">
              <TableTrusteds/>
          </div>
        </div>
        
      </div>

    </div>
  );
});

export default Object;