import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "..";
import { useLocation, useNavigate} from "react-router-dom";
import {  CLIENTS_ROUTE, HOME_ROUTE } from "../utils/consts";
import { crmRequest } from "../http/userAPI";



const Auth = observer(() => {
  const {user, task} = useContext(Context);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [uuid, setUuid] = useState('');
  const location = useLocation();
  const history = useNavigate();
  const from = location.state?.from?.pathname || '/'

let userAuth = {
  "username": username,
  "password": password
}


const login = async (e) => {
   e.preventDefault();

  let logUser = await crmRequest('auth/login', false, userAuth); 
  
   if(logUser.uuid ){
     setUuid(logUser.uuid);
      user.setIsAuth(true);
      user.setUserUuid(logUser.uuid);
      localStorage.setItem('uuid', logUser.uuid);
      setUsername('');
      setPassword('');
      history(from, { replace: true });
   }

}


const keyHandler = (e) => {
  if (e.key === 'Enter') { //якщо натиснута клавіша Enter
    //e.preventDefault(); // запобігає стандартній поведінці Enter
    login(e);
}
}

let userUuid = {uuid: uuid}
console.log(userUuid.uuid);

const logCheck = async () => {
  let checkUser = await crmRequest('auth/check', true, userUuid); 
 return checkUser;
}

 useEffect(() => {
 if(uuid !== '')
    
     logCheck().then(data => {
       
       user.setLoginUser(data.user);
       user.setRole(data.roles);
       user.setRolesFilter(Object.entries(data.roles));
       user.setPermissions(data.permissions);
       user.setStatus(data.status);
     
    })
 
  },[uuid])

 


  return (
    <div className="uk-margin-large-top uk-margin-left uk-margin-right uk-flex uk-modal-central uk-open  " uk-height-viewport>
      <div className="uk-modal-dialog uk-margin-auto-vertical" role='dialog' aria-modal='true'>
        <button className="uk-modal-close-default uk-icon uk-close" type="button" uk-close arial-lable='Close'>
          <svg width='14' height='14' viewBox='0 0 14 14'>
            <line fill='none' stroke='#000' strokeWidth='1.1' x1='1' y1='1' x2='13' y2='13'></line>
            <line fill='none' stroke='#000' strokeWidth='1.1' x1='13' y1='1' x2='1' y2='13'></line>
          </svg>
        </button>

          <form className="uk-form-stacked uk-margin-left uk-margin-right ">

        <div className="uk-modal-header">
          <h3>Увійти</h3>
        </div>

        <div className="uk-modal-body">

            <div className="uk-margin">
                <label className="uk-form-label" for="form-auth-login">Ім'я користувача</label>
                <div className="uk-form-controls">
                    <input className="uk-input" type="text" onKeyDown={e => keyHandler(e)} placeholder="Введіть Ім'я" name='username' value={username} onChange={e => setUsername(e.target.value)}/>
                </div>
            
            </div>

            <div className="uk-margin">
                <label className="uk-form-label" for="form-auth-password">Пароль</label>
                <div className="uk-form-controls">
                  <input className="uk-input" type="password" onKeyDown={e => keyHandler(e)} placeholder="Введіть Пароль" aria-label="Input" value={password} onChange={e => setPassword(e.target.value)}/>
                </div>  
            </div>

        </div>

        <div className="uk-modal-footer uk-text-right">
          <div uk-form-custom='target: > * > span:first-child' className="uk-form-custom">
            
          </div>
          <button 
          name='login_enter' 
          className="uk-button uk-button-primary uk-padding-left uk-padding-right"
          style={{paddingLeft: '30px', paddingRight: '30px'}}  
          type="button"
          onClick={(e) => login(e)}>
            Вхід
          </button>
          
        </div>

</form> 
        
</div>
    </div>
  );
});

export default Auth;