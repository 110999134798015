import React, { useContext } from "react";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { TASKS_ROUTE } from "../utils/consts";



const CardTaskDashbord = observer(({object}) => {
  const {user, task} = useContext(Context);
  const history = useNavigate();

  const permissionTaskGet = user._permissions.includes('tasks/get');
  const roles = [];
  const statuses = [];

  for (var key in user._status) {
    if (user._status.hasOwnProperty(key)) {
      statuses.push(key, user._status[key]);
    }
  }

  for(let key in user._role){
    if (user._role.hasOwnProperty(key)) {
      let role = {key: key, role: user._role[key]}
      roles.push(role);
    }
  }



  return (
    <div>
      
   
    {
      permissionTaskGet
      ?
      <div  className="uk-margin-small-bottom uk-grid-match"  task={object} uk-grid onClick={() => history(TASKS_ROUTE + '/' + object.id)} uk-parallax="start: 100%; end: 100%; border-color: lightgrey;" style={{border: '1px solid #000', cursor:'pointer'}}>
                  <div className="uk-background-default uk-card uk-padding-small uk-card-body uk-card-hover uk-border-rounded " uk-card>
                    <p>{object.name}</p>
                   
                    <div uk-grid className="uk-flex uk-flex-between">
                      {
                        object.term === 'date'
                        ?
                        <div className="uk-width-1-1 uk-width-1-2@l uk-text-small">
                            {object.date}
                        </div>
                        :
                          task._term.map(elem => elem.key === object.term
                            ?
                            <div className="uk-width-1-1 uk-width-1-2@l uk-text-small">
                            {elem.type}</div> 
                            :
                            null
                          )
                        }
                        
                      <div>
                        {statuses.map(status => status === object.status
                        ?
                        <div className="uk-width-1-2 uk-text-right uk-text-small"><span className="uk-label" style={{backgroundColor: `${user._status[status].color}`}}>{user._status[status].name}</span></div>
                        :
                        null
                    )}
                      </div>
                      
                    
                    </div>

                    <p uk-grid className="uk-flex uk-flex-between uk-article-meta">
                        <div className="uk-width-1-2 uk-text-small">{object.link_users_username}</div>
                        {
                          roles.map(role => 
                           role.key === object.level
                           ?
                           <div className="uk-width-1-2 uk-text-small uk-text-right">{role.role}</div>
                           :
                           null
                          )
                        }
                    </p>

                  </div>
     </div>
      :

      <div  className="uk-margin-small-bottom uk-grid-match"  task={object} uk-grid  uk-parallax="start: 100%; end: 100%; border-color: lightgrey;" style={{border: '1px solid #000', cursor:'pointer'}}>
                  <div className="uk-background-default uk-card uk-padding-small uk-card-body uk-card-hover uk-border-rounded " uk-card>
                    <p>{object.name}</p>
                   
                    <div uk-grid className="uk-flex uk-flex-between">
                      {
                        object.term === 'date'
                        ?
                        <div className="uk-width-1-1 uk-width-1-2@l uk-text-small">
                            {object.date}
                        </div>
                        :
                          task._term.map(elem => elem.key === object.term
                            ?
                            <div className="uk-width-1-1 uk-width-1-2@l uk-text-small">
                            {elem.type}</div> 
                            :
                            null
                          )
                        }
                        
                      <div>
                        {statuses.map(status => status === object.status
                        ?
                        <div className="uk-width-1-2 uk-text-right uk-text-small"><span className="uk-label" style={{backgroundColor: `${user._status[status].color}`}}>{user._status[status].name}</span></div>
                        :
                        null
                    )}
                      </div>
                      
                    
                    </div>

                    <p uk-grid className="uk-flex uk-flex-between uk-article-meta">
                        <div className="uk-width-1-2 uk-text-small">{object.link_users_username}</div>
                        {
                          roles.map(role => 
                           role.key === object.level
                           ?
                           <div className="uk-width-1-2 uk-text-small uk-text-right">{role.role}</div>
                           :
                           null
                          )
                        }
                    </p>

                  </div>
     </div>
}
      
 </div>
      
   
  );
});

export default CardTaskDashbord;