import { makeAutoObservable } from "mobx";

export default class PhotoCrm {
  constructor() {
    this._photo =  [
      {id: 1, idTask: 2, comment: 'готова робота справа', src: '../assets/pool_1.png', dateAdd: '15.06.24', dateRemove: '20.06.24'},
      {id: 2, idTask: 3, comment: 'готова робота зліва', src: '../assets/pool_2.png', dateAdd: '04.05.24', dateRemove: null},
      {id: 3, idTask: 1, comment: 'фронтальне', src: '../assets/pool_3.png', dateAdd: '01.07.24', dateRemove: null},
    ]
    makeAutoObservable(this)
  }

  setPhoto(photo) {
    this._photo = photo
  }

  get photo() {
    return this._photo
  }
}