import React, {useContext} from "react";
import { observer } from "mobx-react-lite";
import ListTasks from './ListTasks';
import { Context } from "..";
import { useParams } from "react-router-dom";

const TaskDashboard = observer(() => {
    const {task} = useContext(Context);
    const params = useParams();
    const arrayTasks = [];
   
    
        for (let key in task._taskForDashboard) {
          if (task._taskForDashboard.hasOwnProperty(key)) {
            let arr = {key: key, infoTasks: task._taskForDashboard[key]} 
            arrayTasks.push(arr);
          }
        }


  return (
    <div className="uk-container uk-container-xlarge uk-padding-small" uk-height-viewport style={{minHeight: 'max(0px, 100vh)'}}>
      <div className={params.my === 'my'?"uk-grid-small uk-flex uk-flex-center uk-flex-wrap": "uk-grid-small uk-flex uk-flex-between uk-flex-wrap"} >

       {
        arrayTasks.map(elem => 
            <div className="uk-width-1-2@s uk-width-1-3@m uk-width-1-6@l uk-first-column uk-margin-small-left@s" >
  
                <div className="uk-card uk-card-default uk-card-hover uk-border-rounded uk-margin-bottom uk-text-center uk-padding-small" style={{backgroundColor: elem.infoTasks.color, color: 'white'}}>
                    {elem.infoTasks.name}
                </div>

                <ListTasks tasks={elem.infoTasks.items} />
           </div>
        )

       }
        
      </div>
      
    </div>

  );
});

export default TaskDashboard;