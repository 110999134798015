import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import BreadCrumb from "../components/Breadcrumb";
import TableObjects from "../components/TableObjects";
import CardClient from "../components/CardClient";
import { crmRequest } from "../http/userAPI";


const Client = observer(() => {

  const [singleClient, setSingleClient] = useState({});
  const params = useParams(); 


  let requestBody = {
    "id":params.id,
  }


  const oneClient = async () => {
    let cl = await crmRequest('clients/get', true, requestBody);
    return cl;
  }

 
  
  useEffect(()=> {
    oneClient().then(client => {
      setSingleClient(client);
    })
  },[])

  




  return (
    <div className="uk-container uk-container-xlarge uk-padding-small">
     <BreadCrumb client={singleClient}/> 

      <div  className="uk-grid-small uk-flex uk-flex-wrap">
        <div className="uk-width-1-2@s uk-width-1-3@m  uk-width-1-1 uk-first-column">
           <CardClient client={singleClient}/> 
        </div>

        <div className="uk-width-1-2@s uk-width-2-3@m uk-width-1-1 uk-margin-bottom">
          <div className="uk-card uk-card-default uk-card-hover">
                <TableObjects title='Об*єкти' obj={singleClient} />
          </div>
        </div>
        
      </div>
    </div>
  );
});

export default Client;