import React, {useEffect, useState, useRef, useContext} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { crmRequest } from "../http/userAPI";
import { OBJECTS_ROUTE } from "../utils/consts";
import { Context } from "..";

const EditClient = observer(() => {
  const {user} = useContext(Context);
  const params = useParams(); 
  const history = useNavigate();
  const [client, setClient] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [latitude, setLatitude] = useState('0.0');
  const [longitude, setLongitude] = useState('0.0');
  const [notes, setNotes] = useState('');
  const [changeObject, setChangeObject] = useState('');

  const [nameDirty, setNameDirty] = useState(false);
  const [addressDirty, setAddressDirty] = useState(false);

  const [nameError, setNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [latitudeError, setLatitudeError] = useState("");
  const [longitudeError, setLongitudeError] = useState("");
  const [notesError, setNotesError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [modalActive, setModalActive] = useState(true);
  const modalRef = useRef(null);
  const permissionActive = user._permissions.filter(elem => elem === 'objects/activate');
  const permissionDeactive = user._permissions.filter(elem => elem === 'objects/deactivate');


  useEffect(()=> {
    if(nameError || addressError){
      setFormValid(false)
    } else {
      setFormValid(true)
    }

  },[nameError, addressError])


  const infoObject = {
    "id":params.objectId,
    "id_client": params.clientId,
    "name": name,
    "address": address,
    "phone": phone,
    "latitude": latitude,
    "longitude": longitude,
    "notes": notes
  }


  let requestBody = {
    "id":params.objectId,
    "id_client": params.clientId,
  }

  const oneObject = async () => {
    let cl = await crmRequest('objects/get', true, requestBody);
    return cl;
  }

  let requestOneClient = {
    "id": params.clientId, 
  }


  const oneClient = async () => {
    let cl = await crmRequest('clients/get', true, requestOneClient);
    return cl;
  }

 

  useEffect(() => {
    oneObject().then(obj => {
      setName(obj.name);
      setAddress(obj.address);
      setPhone(obj.phone);
      setLatitude(obj.latitude);
      setLongitude(obj.longitude);
      setNotes(obj.notes);
      setChangeObject(obj.active);
    })
    oneClient().then(client => {
      setClient(client.nick_name);
    })
  }, [])

  const deactivate = async () => {
    let deactive = await crmRequest('objects/deactivate', true, requestBody)
    setChangeObject('0');
}

const activate = async () => {
  let active = await crmRequest('objects/activate', true, requestBody)
  setChangeObject('1');
}


  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'name' :
        setNameDirty(true)
        break
      case 'address':
        setAddressDirty(true)
        break
    }
  }




   const nameHandler = (e) => {
    setName(e.target.value)
    if(e.target.value.length < 3) {
      setNameError('Назва надто коротка')
    }else if(e.target.value.length > 100){
      setNameError('Назва задовга')
    }else if(!e.target.value){
      setNameError("Назва не може бути порожньою")
    }
    else{
      setNameError('')
    }
   }

   const addressHandler = (e) => {
    setAddress(e.target.value)
    if(e.target.value.length < 3) {
      setAddressError('Адреса надто коротка')
    }else if(e.target.value.length > 255){
      setAddressError('Адреса задовга')
    }else if(!e.target.value){
      setAddressError("Адреса не може бути порожня")
    }
    else{
      setAddressError('')
    }
   }


   const notesHandler = (e) => {
    setNotes(e.target.value)
    if(e.target.value.length > 3000){
      setNotesError('Коментар задовгий')
    } else{
      setNotesError('')
    }
   }

   const latitudeHandler = (e) => {
    setLatitude(e.target.value)
    const re = /\d{2}\.\d{2,7}$/;
    if(!re.test(String(e.target.value).toLocaleLowerCase())){
      setLatitudeError('Некоректна широта')
    }else{
      setLatitudeError('')
    }
   }

   const longitudeHandler = (e) => {
    setLongitude(e.target.value)
    const re = /\d{2}\.\d{2,7}$/;
    if(!re.test(String(e.target.value).toLocaleLowerCase())){
      setLongitudeError('Некоректна довгота')
    }else{
      setLongitudeError('')
    }
   }

   const phoneHandler = (e) => {
    setPhone(e.target.value)
    const re = /\+\d{12,15}$/;
    if(!re.test(String(e.target.value).toLocaleLowerCase())){
      setPhoneError('Некоректний номер')
    }else{
      setPhoneError('')
    }
   }

   //console.log(infoObject);

  const editObject = async () => {
    let editobj = await crmRequest('objects/edit', true, infoObject);
    history(OBJECTS_ROUTE + '/' + params.clientId + '/' + params.objectId);
  }

  const keyHandler = (e) => {
    if (e.key === 'Enter') { //якщо натиснута клавіша Enter
      e.preventDefault(); // запобігає стандартній поведінці Enter
      editObject(); // викликаємо обробник відправки форми
    }
  }

  const submitForm = (e) => {
    e.preventDefault(); 
    editObject(); 
}

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setModalActive(false);
        history(OBJECTS_ROUTE + '/' + params.clientId + '/' + params.objectId); 
      }
    };

    const handleTab = (event) => {
      if (event.key === 'Tab' && modalRef.current) {
        const focusableElements = modalRef.current.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
       // const touchAction = 'none';
        //document.documentElement.style.touchAction= touchAction;
        //console.log(document.documentElement)
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];
       // console.log(focusableElements);
        //console.log(firstElement);
        //console.log(lastElement);


        if (event.shiftKey) { // Shift + Tab
          if (document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          }
        } else { // Tab
          if (document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }
    };

    // Додаємо обробчик подій
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keydown', handleTab);

    // Видаляємо обробчик подій при розмонтурованні компонента
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keydown', handleTab);
    };
  }, [setModalActive]);

  if (!modalActive) return null; // Якщо модальне вікно закрито, нічого не рендеримо
//console.log(changeObject);

  return (
    <div   className={modalActive ? "uk-modal uk-open" : "uk-modal"} style={{zIndex: '1011', display: 'block'}} tabIndex='-1'>
          
          <div className="uk-modal-dialog uk-modal-body" ref={modalRef}  role='alertdialog' aria-modal='true' onClick={e => e.stopPropagation()}>
             
             <div className="uk-modal-title uk-flex uk-flex-between">
             <h2>Відредагуйте об'єкт</h2>
             <div>
              { changeObject === '1' && permissionDeactive
                  ?
                  <a href="#" className="uk-icon-link uk-icon uk-text-success uk-margin-small-left" onClick={deactivate} uk-icon="unlock"></a>
                   :
                 null
              }   
                 { changeObject === '0' && permissionActive
                  ?
                  <a href="#" className="uk-icon-link uk-icon uk-text-danger uk-margin-small-left" onClick={activate}  uk-icon="lock"></a>
                   :
                  null
              }   
              </div>
            </div>
             <form className="uk-form-stacked" ref={modalRef} onSubmit={e => submitForm(e)}>
          <div className ="uk-card">

        
  
            <div className="uk-card-body uk-padding-small">

            <div className="uk-margin">Клієнт: {client}</div>

              <div  className="uk-margin uk-text-left">
                <label className="uk-form-label" for="nameRule">Назва об'єкта <span className="uk-text-danger">*</span></label>
                <div className="uk-form-controls">
                  {(nameDirty && nameError) && <div className="uk-text-danger uk-text-large">{nameError}</div>}
                  <input name="name" onBlur={e => blurHandler(e)} onKeyDown={keyHandler}  className="uk-input" id="nameRule" type="text" maxLength="100" value={name} onChange={e => nameHandler(e)}/>
                </div>

                <div  className="uk-margin">
                  <label className="uk-form-label" for="addressRule">Адреса об'єкта <span className="uk-text-danger">*</span></label>
                  <div className="uk-form-controls">
                  {(addressDirty && addressError) && <div className="uk-text-danger">{addressError}</div>}
                    <input name="address" onBlur={e => blurHandler(e)} onKeyDown={keyHandler} className="uk-input" id="addressRule" type="text" maxLength="255" value={address} onChange={e => addressHandler(e)}/>
                  </div>
                  </div>

                <div  className="uk-margin">
                  <label className="uk-form-label" for="phoneRule">Номер телефону клієнта</label>
                  <div className="uk-form-controls">
                  {(phoneError) && <div className="uk-text-danger">{phoneError}</div>}
                    <input name="phone" onKeyDown={keyHandler} className="uk-input" id="phoneRule" type="tel" maxLength="16" value={phone} onChange={e => phoneHandler(e)} placeholder="+380662554433"/>
                  </div>
                </div>

                <div  className="uk-margin">
                  <label className="uk-form-label" for="latitudeRule">Координати ширини</label>
                  <div className="uk-form-controls">
                  {(latitudeError) && <div className="uk-text-danger">{latitudeError}</div>}
                    <input name="latitude"  onKeyDown={keyHandler} className="uk-input" id="latitudeRule" type="text" maxLength="10" value={latitude} onChange={e => latitudeHandler(e)} placeholder="38.0662554"/>
                  </div>
                </div>

                <div  className="uk-margin">
                  <label className="uk-form-label" for="longitudeRule">Координати довжини</label>
                  <div className="uk-form-controls">
                  {(longitudeError) && <div className="uk-text-danger">{longitudeError}</div>}
                    <input name="longitude" onKeyDown={keyHandler} className="uk-input" id="longitudeRule" type="text" maxLength="10" value={longitude} onChange={e => longitudeHandler(e)} placeholder="38.0662554"/>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" for="textRule">Коментар</label>
                  {(notesError) && <div className="uk-text-danger">{notesError}</div>}
                  <textarea name='notes' id="textRule" className="uk-textarea" rows="5" value={notes}  onChange={e => notesHandler(e)} aria-label="Textarea"></textarea>
                </div>

            
              </div>
            </div>
          </div>
        
                 
             <p className="uk-text-right">
             <button className="uk-button uk-button-default" type="button"  onClick={() => history(OBJECTS_ROUTE + '/' + params.clientId + '/' + params.objectId)}>Відмінити</button>
              <button disabled={!formValid} className="uk-button uk-button-primary" type="submit" >Зберегти</button>
             </p>
          </form>

           </div>        
     </div>
  );
});

export default EditClient;