import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { crmRequest } from "../../http/userAPI";
import { useInput } from "../hooks/useInput";
import { Context } from "../..";

const AddTrustedPerson = observer(({active, setActive}) => {
 
  const {object, client, trustedPerson} = useContext(Context);
  const params = useParams();
  const offset = trustedPerson._limit * (trustedPerson._page- 1);
  const name = useInput('', {isEmpty: true, minLength: 2, maxLength: 100});
  const phone = useInput('', {minLength: 12, maxLength: 16, phoneError: false});
  const notes = useInput('', {maxLength: 3000});
  const modalRef = useRef(null);


  const infoObject = {
      "id_object": params.objectId,
      "name":  name.value,
      "phone": phone.value,
      "notes": notes.value,
  }


  let requestBody = {
    "id_object": params.objectId,
    "limit": trustedPerson._limit,
    "offset": offset,
    "active": [1,0], 
    "search": ''
  }

  let requestBodyClient = {
    "id": params.clientId,
  }
  
  const oneClient = async () => {
    let cl = await crmRequest('clients/get', true, requestBodyClient);
    return cl;
  }

  let requestBodyObject = {
    "id_client": params.clientId,
    "id": params.objectId
  }

  const oneObject = async () => {
    let obj = await crmRequest('objects/get', true, requestBodyObject );
    return obj;
  }

  const personsList = async () => {
    let obj = await crmRequest('persons/list', true, requestBody);
    return obj;
  }


    const addPerson = async () => {
      let addPers = await crmRequest('persons/add', true, infoObject);
      personsList().then(pers => {
        trustedPerson.setTotalCount(pers.count)
        trustedPerson.setTrustedPerson(pers.list);
      });
      setActive(false);
      name.value = ''; 
      phone.value = ''; 
      notes.value = ''; 
      
    }

    useEffect(() => {
      oneClient().then(cl => client.setSelectedClient(cl));
      oneObject().then(obj => object.setCurrentObject(obj));

    }, [])  

    const keyHandler = (e) => {
      e.preventDefault();
      addPerson();
      setActive(false); 
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setActive(false); 
      }
    };
  
    const handleTab = (event) => {
      if (event.key === 'Tab' && modalRef.current) {
        const focusableElements = modalRef.current.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];
  
  
        if (event.shiftKey) { // Shift + Tab
          if (document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          }
        } else { // Tab
          if (document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }
    };
  
    // Додаємо обробчик подій
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keydown', handleTab);
  
    // Видаляємо обробчик подій при розмонтурованні компонента
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keydown', handleTab);
    };
  }, [setActive]);
  
  if (!active) return null; // Якщо модальне вікно закрито, нічого не рендеримо
    
    
    



  return (
    <div id="modal-add-person" uk-modal className={active ? "uk-modal uk-open" : "uk-modal"} style={{zIndex: '1011', display: 'block'}} tabIndex='-1'>
          
    <div className="uk-modal-dialog uk-modal-body" ref={modalRef} role='alertdialog' aria-modal='true' onClick={e => e.stopPropagation()}>
       <h2 className="uk-modal-title">Додайте довірену особу</h2>

       <form className="uk-form-stacked" onSubmit={e => keyHandler(e)}>
    <div className ="uk-card">

      <div className="uk-card-body uk-padding-small">

      <div className="uk-margin">
            <p className="uk-form-label" for="textRule">Клієнт: {client._selectedClient.nick_name}</p>
      </div>

      <div className="uk-margin">
            <p className="uk-form-label" for="textRule">Об'єкт: {object._currentObject.name}</p>
      </div>

        <div  className="uk-margin uk-text-left">
          <label className="uk-form-label" for="nameRule">Ім'я <span className="uk-text-danger">*</span></label>
          <div className="uk-form-controls">
          {(name.isDirty && name.isEmpty) && <div className="uk-text-danger">Поле не може бути порожнім</div>}
          {(name.isDirty && name.minLengthError ) && <div className="uk-text-danger">Довжина коротка</div>}
          {(name.isDirty && name.maxLengthError ) && <div className="uk-text-danger">Довжина довга</div>}
            <input name="name" tabindex="0" className="uk-input" onBlur={e => name.onBlur(e)} value={name.value} onChange={e => name.onChange(e)} id="nameRule" type="text" maxlength="100" placeholder="Ім'я"/>
          </div>


          <div  className="uk-margin">
            <label className="uk-form-label" for="phoneRule">Номер телефону <span className="uk-text-danger">*</span></label>
            <div className="uk-form-controls">
            {(phone.isDirty && phone.minLengthError ) && <div className="uk-text-danger">Довжина коротка</div>}
            {(phone.isDirty && phone.maxLengthError ) && <div className="uk-text-danger">Довжина довга</div>}
            {(phone.isDirty && phone.phoneError ) && <div className="uk-text-danger">Не корректний номер</div>}
              <input name="phone" tabindex="0" onBlur={e => phone.onBlur(e)} value={phone.value} onChange={e => phone.onChange(e)} className="uk-input" id="phoneRule" type="tel" maxlength="16" placeholder="+380660002233"/>
            </div>
          </div>


          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="textRule" for="textRule">Коментар
            {(notes.maxLengthError ) && <div className="uk-text-danger">Довжина довга</div>}
            <textarea className="uk-textarea" id="textRule" tabindex="0" onBlur={e => notes.onBlur(e)} value={notes.value} onChange={e => notes.onChange(e)}  rows="5" placeholder="Напишіть коментар" aria-label="Textarea"></textarea>
            </label>
          </div>

      
        </div>
      </div>
    </div>
  
           
       <p className="uk-text-right">
       <button className="uk-button uk-button-default" type="button" tabindex="0" uk-toggle="target: #modal-add-person" onClick={() => setActive(false)}>Відмінити</button>
       <button className="uk-button uk-button-primary" tabindex="0" /* disabled={!name.inputValid || !phone.inputValid}*/  type="submit" uk-toggle="target: #modal-add-person" >Зберегти</button>
       </p>
      </form>  
     </div>
    

</div>
  );
});

export default AddTrustedPerson;