import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { crmRequest } from "../../http/userAPI";
import { useInput } from "../hooks/useInput";
import { Context } from "../..";

const AddObject = observer(({active, setActive}) => {
const {object, client} = useContext(Context);
const params = useParams();
const offset = object._limit * (object._page- 1);
const nickname = useInput('', {isEmpty: true, minLength: 2, maxLength: 100});
const address = useInput('', {isEmpty: true, minLength: 2, maxLength: 255});
const phoneObject = useInput('', {minLength: 12, maxLength: 16, phoneError: false});
const latitude = useInput('0.0', {minLength: 5, maxLength: 10, latitude:false});
const longitude = useInput('0.0', {minLength: 5, maxLength: 10, latitude:false});
const notes = useInput('', {maxLength: 3000});
const modalRef = useRef(null);

const infoObject = {
    "id_client":params.id,
    "name": nickname.value,
    "address": address.value,
    "phone": phoneObject.value,
    "notes": notes.value,
    "latitude": latitude.value,
    "longitude": longitude.value
}


let requestBody = {
  "id_client": params.id,
  "limit": object._limit,
  "offset": offset,
  "active": [1,0], 
  "search": ''
}

let requestBodyClient = {
  "id": params.id,
}

const oneClient = async () => {
  let cl = await crmRequest('clients/get', true, requestBodyClient);
  return cl;
}

const objectList = async () => {
  let obj = await crmRequest('objects/list', true, requestBody);
  return obj;
}


  const addObject = async () => {
    let addObj = await crmRequest('objects/add', true, infoObject);
    objectList().then(obj => {
      object.setObject(obj.list)
      object.setTotalCount(obj.count)
    });

    setActive(false);
    
    nickname.value = ''; 
    address.value = ''; 
    phoneObject.value = ''; 
    latitude.value = '0.0'; 
    longitude.value = '0.0'; 
    notes.value = ''; 
    
  }

  useEffect(() => {
    oneClient().then(cl => client.setClients(cl))
  }, [])


  const keyHandler = (e) => {
    e.preventDefault();
    addObject();
}

useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setActive(false); 
    }
  };

  const handleTab = (event) => {
    if (event.key === 'Tab' && modalRef.current) {
      const focusableElements = modalRef.current.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (event.shiftKey) { // Shift + Tab
        if (document.activeElement === firstElement) {
          event.preventDefault();
          lastElement.focus();
        }
      } else { // Tab
        if (document.activeElement === lastElement) {
          event.preventDefault();
          firstElement.focus();
        }
      }
    }
  };

  // Додаємо обробчик подій
  document.addEventListener('keydown', handleKeyDown);
  document.addEventListener('keydown', handleTab);

  // Видаляємо обробчик подій при розмонтурованні компонента
  return () => {
    document.removeEventListener('keydown', handleKeyDown);
    document.removeEventListener('keydown', handleTab);
  };
}, [setActive]);

if (!active) return null; // Якщо модальне вікно закрито, нічого не рендеримо





  return (
    <div id="modal-add-object" uk-modal className={active ? "uk-modal uk-open" : "uk-modal"} style={{zIndex: '1011', display: 'block'}} tabIndex='-1'>
          
          <div className="uk-modal-dialog uk-modal-body" ref={modalRef} role='alertdialog' aria-modal='true' onClick={e => e.stopPropagation()}>
             <h2 className="uk-modal-title">Додайте об'єкт</h2>
   
             <form className="uk-form-stacked" onSubmit={e => keyHandler(e)}>
          <div className ="uk-card">
  
            <div className="uk-card-body uk-padding-small">

            <div className="uk-margin">
                  <p className="uk-form-label" for="textRule">Клієнт: {client._clients.nick_name}</p>
            </div>

              <div  className="uk-margin uk-text-left">
                <label className="uk-form-label" for="nickNameRule">Назва об'єкта <span className="uk-text-danger">*</span></label>
                <div className="uk-form-controls">
                  {(nickname.isDirty && nickname.isEmpty) && <div className="uk-text-danger">Поле не може бути порожнім</div>}
                  {(nickname.isDirty && nickname.minLengthError ) && <div className="uk-text-danger">Довжина коротка</div>}
                  {(nickname.isDirty && nickname.maxLengthError ) && <div className="uk-text-danger">Довжина довга</div>}
                  <input name="nickname" tabindex="0" onBlur={e => nickname.onBlur(e)} value={nickname.value} onChange={e => nickname.onChange(e)}  className="uk-input" id="nickNameRule" type="text" maxlength="100" placeholder="Назва об'єкта"/>
                </div>

                <div  className="uk-margin">
                  <label className="uk-form-label" for="addressRule">Адреса об'єкта <span className="uk-text-danger">*</span></label>
                  <div className="uk-form-controls">
                  {(address.isDirty && address.isEmpty) && <div className="uk-text-danger">Поле не може бути порожнім</div>}
                  {(address.isDirty && address.minLengthError ) && <div className="uk-text-danger">Довжина коротка</div>}
                  {(address.isDirty && address.maxLengthError ) && <div className="uk-text-danger">Довжина довга</div>}
                    <input name="address" tabindex="0" onBlur={e => address.onBlur(e)} value={address.value} onChange={e => address.onChange(e)} className="uk-input" id="addressRule" type="text" maxlength="255" placeholder="Адреса об'єкта"/>
                  </div>
                  </div>


                <div  className="uk-margin">
                  <label className="uk-form-label" for="phoneObjectRule">Номер телефону об'єкта</label>
                  <div className="uk-form-controls">
                  {(phoneObject.isDirty && phoneObject.minLengthError ) && <div className="uk-text-danger">Довжина коротка</div>}
                  {(phoneObject.isDirty && phoneObject.maxLengthError ) && <div className="uk-text-danger">Довжина довга</div>}
                  {(phoneObject.isDirty && phoneObject.phoneError ) && <div className="uk-text-danger">Не корректний номер</div>}
                    <input name="phoneObject" tabindex="0" onBlur={e => notes.onBlur(e)}  value={phoneObject.value} onChange={e => phoneObject.onChange(e)} className="uk-input" id="phoneObjectRule" type="tel" maxlength="16" placeholder="+380660010001"/>
                  </div>
               
                </div>

                <div  className="uk-margin">
                  <label className="uk-form-label" for="latitudeRule">Координати широти</label>
                  <div className="uk-form-controls">
                  {(latitude.isDirty && latitude.latitudeError ) && <div className="uk-text-danger">Не корректна широта</div>} 
                  {(latitude.isDirty && latitude.minLengthError ) && <div className="uk-text-danger">Широта закоротка</div>}
                  {(latitude.isDirty && latitude.maxLengthError ) && <div className="uk-text-danger">Широта задовга</div>}
                    <input name="latitude" tabindex="0" onBlur={e => notes.onBlur(e)} value={latitude.value} onChange={e => latitude.onChange(e)} className="uk-input" id="latitudeRule" type="text" maxlength="10" placeholder="42.254525"/>
                  </div>
                </div>

                <div  className="uk-margin">
                  <label className="uk-form-label" for="longitudeRule">Координати довготи</label>
                  <div className="uk-form-controls">
                  {(longitude.isDirty && longitude.latitudeError ) && <div className="uk-text-danger">Не корректна довжина</div>} 
                  {(longitude.isDirty && longitude.minLengthError ) && <div className="uk-text-danger">Довжина коротка</div>}
                  {(longitude.isDirty && longitude.maxLengthError ) && <div className="uk-text-danger">Довжина довга</div>}
                    <input name="longitude" tabindex="0" onBlur={e => notes.onBlur(e)} value={longitude.value} onChange={e => longitude.onChange(e)} className="uk-input" id="longitudeRule" type="text" maxlength="10" placeholder="42.254525"/>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="textRule" for="textRule">Коментар
                  {(notes.maxLengthError ) && <div className="uk-text-danger">Довжина задовга</div>}
                  <textarea className="uk-textarea" id="textRule" name="textRule" tabindex="0" onBlur={e => notes.onBlur(e)} value={notes.value} onChange={e => notes.onChange(e)}   rows="5" placeholder="Напишіть коментар" aria-label="Textarea"></textarea>
                  </label>
                </div>

            
              </div>
            </div>
          </div>
        
                 
             <p className="uk-text-right">
             <button className="uk-button uk-button-default" type="button" tabindex="0" uk-toggle="target: #modal-add-object" onClick={() => setActive(false)}>Відмінити</button>
              <button className="uk-button uk-button-primary" tabindex="0" disabled={!nickname.inputValid || !address.inputValid} type="submit" uk-toggle="target: #modal-add-object">Зберегти</button>
             </p>
        </form>

           </div>        
     </div>
  );
});

export default AddObject;