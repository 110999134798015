import React, {useEffect, useState, useRef, useContext} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { crmRequest } from "../http/userAPI";
import { OBJECTS_ROUTE, TASKS_ROUTE } from "../utils/consts";
import { Context } from "..";

const EditTask = observer(() => {
  const {user, task} = useContext(Context);
  const params = useParams(); 
  const history = useNavigate();
  const [idClient, setIdClient] = useState('');
  const [idObject, setIdObject] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [termQuery, setTermQuery] = useState("");
  const [dateQuery, setDateQuery] = useState('');
  const [levelQuery, setLevelQuery] = useState('');
  const [allClients, setAllClients] = useState([]);
  const [allObjects, setAllObjects] = useState([]);
  const [allLevels, setAllLevels] = useState([]);

  const [nameDirty, setNameDirty] = useState(false);
  const [descriptionDirty, setDescriptionDirty] = useState(false);

  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  
  const [formValid, setFormValid] = useState(false);
  const [modalActive, setModalActive] = useState(true);
  const modalRef = useRef(null);


  const dirList = async () => {
    let dir  = await crmRequest('tasks/dir', true);
    return dir ;
  }

  useEffect(()=> {
    dirList().then(dir => {
      setAllClients(dir.clients);
      setAllObjects(dir.objects);
      setAllLevels(dir.level);
    })
  },[])

  useEffect(()=> {
    if(nameError || descriptionError ){
      setFormValid(false)
    } else {
      setFormValid(true)
    }

  },[nameError, descriptionError])

//console.log(params);

  const infoTask = {
    "id": params.taskId,
    "id_client": idClient,
    "id_object": idObject,
    "name": name,
    "description": description,
    "term": termQuery,
    "date": dateQuery,
    "level": levelQuery
  }

  console.log(infoTask);

  let requestBodyTask = {
    "id": params.taskId,
  
  }

  const oneTask = async () => {
    let task = await crmRequest('tasks/get', true, requestBodyTask);
    return task;
  }

 

  useEffect(() => {
    oneTask().then(elem => {
      setIdClient(elem.id_client);
      setIdObject(elem.id_object);
      setName(elem.name);
      setDescription(elem.description);
      setTermQuery(elem.term);
      setDateQuery(elem.date);
      setLevelQuery(elem.level);
    })
  }, [])


  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'name' :
        setNameDirty(true)
        break
      case 'description':
        setDescriptionDirty(true)
        break
    }
  }


   const nameHandler = (e) => {
    setName(e.target.value)
   if(e.target.value.length > 100){
      setNameError('Назва задовга')
    }else if(!e.target.value){
      setNameError("Назва не може бути порожньою")
    }
    else{
      setNameError('')
    }
   }


   const descriptionHandler = (e) => {
    setDescription(e.target.value)
    if(e.target.value.length > 3000){
      setDescriptionError('Коментарі задовгі')
    } else{
      setDescriptionError('')
    }
   }


  const editTask = async () => {
    let editts = await crmRequest('tasks/edit', true, infoTask);
    history(TASKS_ROUTE + '/' + params.taskId);
  }

  const keyHandler = (e) => {
    
      e.preventDefault(); // запобігає стандартній поведінці Enter
      editTask(); // викликаємо обробник відправки форми
   
  }

  const submitForm = (e) => {
      e.preventDefault(); 
      editTask(); 
  }

  const idClientHandler = (e) => {
    //console.log(e)
     if(e === "null") {
       setIdClient(null);
   }
    else{
     setIdClient(e)  
     }
   }


  const changeObject = (e) => {
    if(e.currentTarget.value === "null") {
      setIdObject(null);
   }
    else{
      setIdObject(e.currentTarget.value)  
     }
   
    if(idClient === params.clientId){
      if(e.currentTarget.value === "null") {
        setIdObject(null);
     }
      else{
        setIdObject(e.currentTarget.value)  
       }
    }
  }

  const cancelHandler = (e) => {
    //console.log(e.target.innerText)

    if(e.target.innerText === "Відмінити"){
     let isBoss = window.confirm("Ви впевнені що хочете відмінити редагування?");
     
      if(isBoss){
        setModalActive(false)
        history(TASKS_ROUTE + '/' + params.taskId)
      }else{
        setModalActive(true)
      }
    }

 }


  useEffect(()=> {
    if(idClient !== params.clientId){
      setIdObject(null);
    }
  }, [idClient])

  //console.log(idClient === params.clientId);
 // console.log(infoTask);


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
          setModalActive(false)
          history(TASKS_ROUTE + '/' + params.taskId); 
      }
    };

    const handleTab = (event) => {
      if (event.key === 'Tab' && modalRef.current) {
        const focusableElements = modalRef.current.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
       // const touchAction = 'none';
        //document.documentElement.style.touchAction= touchAction;
        //console.log(document.documentElement)
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];
       // console.log(focusableElements);
        //console.log(firstElement);
        //console.log(lastElement);


        if (event.shiftKey) { // Shift + Tab
          if (document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          }
        } else { // Tab
          if (document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }
    };

    // Додаємо обробчик подій
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keydown', handleTab);

    // Видаляємо обробчик подій при розмонтурованні компонента
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keydown', handleTab);
    };
  }, [setModalActive]);

  if (!modalActive) return null; // Якщо модальне вікно закрито, нічого не рендеримо


  return (
    <div id="modal-edit-task" uk-modal className={modalActive ? "uk-modal uk-open" : "uk-modal"} style={{zIndex: '1011', display: 'block'}} tabIndex='-1'>
          
          <div className="uk-modal-dialog uk-modal-body" ref={modalRef} role='alertdialog' aria-modal='true' onClick={e => e.stopPropagation()}>
             <h2 className="uk-modal-title">Відредагуйте задачу</h2>
   
             <form className="uk-form-stacked" onSubmit={e => submitForm(e)}>
          <div className ="uk-card">
  
            <div className="uk-card-body uk-padding-small">
              <div  className="uk-margin">
                <label className="uk-form-label" for="nameRule">Назва</label>
                <div className="uk-form-controls">
                {(nameDirty && nameError) && <div className="uk-text-danger uk-text-large">{nameError}</div>}
                  <input name="name" onBlur={e => blurHandler(e)}  className="uk-input" id="nameRule" type="text" maxlength="100" value={name} onChange={e => nameHandler(e)}/>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" for="textRule">Опис</label>
                  {(descriptionDirty && descriptionError) && <div className="uk-text-danger uk-text-large">{descriptionError}</div>}
                  <textarea name='notes' id="textRule" onBlur={e => blurHandler(e)} className="uk-textarea" rows="5"  value={description} onChange={e => descriptionHandler(e)} aria-label="Textarea"></textarea>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" for="form-horizontal-select1">Клієнт</label>
                  <div className="uk-form-controls">
                    <select onChange={e => idClientHandler(e.currentTarget.value)} className="uk-select" id="form-horizontal-select1">
                      <option value='null'>-</option>
                    {
                        allClients.map(elem => 
                          elem.id === params.clientId
                          ?
                          <option
                            selected
                            key={elem.id}
                            value={elem.id}
                        >{elem.nick_name}</option>
                        :
                        elem.active === '1'
                        ?
                        <option
                          key={elem.id}
                          value={elem.id}
                      >{elem.nick_name}</option>
                      :
                      null
                        )
                      }
                    </select>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" for="form-horizontal-select2">Об'єкт</label>
                  <div className="uk-form-controls">
                    <select onChange={e => changeObject(e)} className="uk-select" id="form-horizontal-select2">
                      <option value='null'>-</option>
                    {allObjects.map(object =>
                       
                        object.id === idObject && object.id_client === idClient
                        ?
                        <option
                        selected
                        key={object.id}
                        value={object.id}
                        >{object.name}</option>
                        :
                        object.active === '1' && object.id_client === idClient
                        ?
                        <option
                        key={object.id}
                        value={object.id}
                        >{object.name}</option>
                        :
                        null
                      )}
                    </select>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" for="form-horizontal-select4">Рівень</label>
                  <div className="uk-form-controls">
                    <select className="uk-select" onChange={e => setLevelQuery(e.currentTarget.value)}  id="form-horizontal-select4">
                    {allLevels.map(level => 
                       level === levelQuery
                       ?
                        <option 
                        selected
                        key={level}
                        value={level}
                        >
                          {user._rolesFilter.map(role => 
                            role[0] === level
                            ?
                            <span>{role[1]}</span>
                            :
                            null
                          )}
                        </option>
                        :
                        <option 
                        key={level}
                        value={level}
                        >
                          {user._rolesFilter.map(role => 
                            role[0] === level
                            ?
                            <span>{role[1]}</span>
                            :
                            null
                          )}
                        </option>
                      )}

                      

                    </select>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" for="form-horizontal-select3">Термін задачі</label>
                  <div className="uk-form-controls">
                    <select onChange={e => setTermQuery(e.currentTarget.value)} className="uk-select" id="form-horizontal-select3">
                    {task._term.map(elem => elem.key === termQuery
                      ?
                      <option selected value={elem.key}>{elem.type}</option>
                      :
                      <option value={elem.key}>{elem.type}</option>
                    )
                  }
                    </select>
                  </div>
                </div>

                {
                  termQuery === 'date'
                  ?
                  <div  className="uk-margin" id='dateSelection'>
                    <label className="uk-form-label" for="dateRule">Дата</label>
                      <div className="uk-form-controls">
                          <input onChange={e => setDateQuery(e.target.value)} name="date" value={dateQuery} className="uk-input" id="dateRule" type="date"/>
                       </div>
                  </div>
                  :
                  null
                }

            
              </div>
            </div>
          </div>
        
                 
             <p className="uk-text-right">
              <button className="uk-button uk-button-default" type="button" onClick={e => cancelHandler(e)}>Відмінити</button>
              <button disabled={!formValid} className="uk-button uk-button-primary" type="submit" uk-toggle="target: #modal-edit-task">Зберегти</button>
             </p>
         </form>    
           </div>        
     </div>
  );
});

export default EditTask;