import React, {useEffect, useState, useRef, useContext} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { crmRequest } from "../http/userAPI";
import { OBJECTS_ROUTE } from "../utils/consts";
import { Context } from "..";

const EditClient = observer(() => {
  const {user} = useContext(Context);
  const params = useParams(); 
  const history = useNavigate();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [notes, setNotes] = useState('');
  const [clientName, setClientName] = useState('');
  const [objectName, setObjectName] = useState('');
  const [active, setActive] = useState('');

  const [nameDirty, setNameDirty] = useState(false);
  const [phoneDirty, setPhoneDirty] = useState(false);

  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [notesError, setNotesError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [modalActive, setModalActive] = useState(true);
  const modalRef = useRef(null);
  const permissionActive = user._permissions.includes('persons/activate');
  const permissionDeactive = user._permissions.includes('persons/deactivate');


  useEffect(()=> {
    if(nameError || phoneError ){
      setFormValid(false)
    } else {
      setFormValid(true)
    }

  },[nameError, phoneError])

//console.log(params);
  const infoPerson = {
    "id": params.personId,
    "id_object": params.objectId,
    "name": name,
    "phone": phone,
    "notes": notes
  }

  //console.log(infoPerson);


  let requestBodyClient = {
    "id":params.clientId,
  }

  const oneClient = async () => {
    let cl = await crmRequest('clients/get', true, requestBodyClient);
    return cl;
  }

  let requestBodyObject= {
    "id":params.objectId,
    "id_client":params.clientId,
  }

  const oneObject = async () => {
    let cl = await crmRequest('objects/get', true, requestBodyObject);
    return cl;
  }

  let requestBodyPerson = {
    "id": params.personId,
    "id_object": params.objectId,
  }

  const onePerson = async () => {
    let cl = await crmRequest('persons/get', true, requestBodyPerson);
    return cl;
  }

 

  useEffect(() => {
    oneClient().then(client => {
      setClientName(client.nick_name);
    })
    oneObject().then(obj => {
      setObjectName(obj.name)
    })
    onePerson().then(pers => {
      setName(pers.name);
      setPhone(pers.phone);
      setNotes(pers.notes);
      setActive(pers.active);
    })
  }, [])

  const deactivate = async (e) => {
    let deactive = await crmRequest('persons/deactivate', true, requestBodyPerson);
    setActive('0');
}

const activate = async (e) => {
  let active = await crmRequest('persons/activate', true, requestBodyPerson);
  setActive('1');
}


  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'name' :
        setNameDirty(true)
        break
      case 'phone':
        setPhoneDirty(true)
        break

    }
  }


   const nameHandler = (e) => {
    setName(e.target.value)
    if(e.target.value.length < 3) {
      setNameError('Назва надто коротка')
    }else if(e.target.value.length > 100){
      setNameError('Назва задовга')
    }else if(!e.target.value){
      setNameError("Назва не може бути порожньою")
    }
    else{
      setNameError('')
    }
   }


   const notesHandler = (e) => {
    setNotes(e.target.value)
    if(e.target.value.length > 100){
      setNotesError('Коментарі задовгі')
    } else{
      setNotesError('')
    }
   }

   const phoneHandler = (e) => {
    setPhone(e.target.value)
    const re = /\+\d{12,15}$/;
    if(!re.test(String(e.target.value).toLocaleLowerCase())){
      setPhoneError('Некоректний номер')
    }else{
      setPhoneError('')
    }
   }

  const editPerson = async () => {
    let editcl = await crmRequest('persons/edit', true, infoPerson);
    history(OBJECTS_ROUTE + '/' + params.clientId + '/' + params.objectId);
  }

  const keyHandler = (e) => {
    if (e.key === 'Enter') { //якщо натиснута клавіша Enter
      e.preventDefault(); // запобігає стандартній поведінці Enter
      editPerson(); // викликаємо обробник відправки форми
    }
  }

  const submitForm = (e) => {
      e.preventDefault(); 
      editPerson(); 
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setModalActive(false);
        history(OBJECTS_ROUTE + '/' + params.clientId + '/' + params.objectId); 
      }
    };

    const handleTab = (event) => {
      if (event.key === 'Tab' && modalRef.current) {
        const focusableElements = modalRef.current.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
       // const touchAction = 'none';
        //document.documentElement.style.touchAction= touchAction;
        //console.log(document.documentElement)
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];
       // console.log(focusableElements);
        //console.log(firstElement);
        //console.log(lastElement);


        if (event.shiftKey) { // Shift + Tab
          if (document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          }
        } else { // Tab
          if (document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }
    };

    // Додаємо обробчик подій
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keydown', handleTab);

    // Видаляємо обробчик подій при розмонтурованні компонента
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keydown', handleTab);
    };
  }, [setModalActive]);

  if (!modalActive) return null; // Якщо модальне вікно закрито, нічого не рендеримо


  return (
    <div   className={modalActive ? "uk-modal uk-open" : "uk-modal"} style={{zIndex: '1011', display: 'block'}} tabIndex='-1'>
          
          <div className="uk-modal-dialog uk-modal-body" role='alertdialog' aria-modal='true'  onClick={e => e.stopPropagation()}>
             <div className="uk-modal-title uk-flex uk-flex-between">
              <h3>Відредагуйте довірену особу</h3>
              <div>
                 {active === '1' && permissionDeactive
                    ?
                    <a href="#"  className="uk-icon-link uk-icon uk-text-success "  onClick={e => deactivate(e)} uk-icon="unlock"></a>
                    :
                    null
                }
                 {active === '0' && permissionActive
                    ?
                    <a href="#"  className="uk-icon-link uk-icon uk-text-danger" onClick={e => activate(e)} uk-icon="lock"></a>
                    :
                    null
                }
              </div>
             </div>
             
   
             <form className="uk-form-stacked" ref={modalRef} onSubmit={e => submitForm(e)}>
                <div className ="uk-card">
  
                  <div className="uk-card-body">

                      <div className="uk-margin">Клієнт: {clientName}</div>
                      <div className="uk-margin">Об'єкт: {objectName}</div>

                      <div  className="uk-margin">
                        <label className="uk-form-label" for="nameRule">Ім'я особи  <span className="uk-text-danger">*</span></label>
                        <div className="uk-form-controls">
                        {(nameDirty && nameError) && <div className="uk-text-danger">{nameError}</div>}
                          <input name="name" onBlur={e => blurHandler(e)} onKeyDown={keyHandler} className="uk-input" id="nameRule" type="text" maxLength="100" value={name} onChange={e => nameHandler(e)}/>
                        </div>
                        </div>


                      <div  className="uk-margin">
                        <label className="uk-form-label" for="phoneRule">Номер телефону клієнта <span className="uk-text-danger">*</span></label>
                        <div className="uk-form-controls">
                        {(phoneDirty && phoneError) && <div className="uk-text-danger">{phoneError}</div>}
                          <input name="phone" onBlur={e => blurHandler(e)} onKeyDown={keyHandler} className="uk-input" id="phoneRule" type="tel" maxLength="16" value={phone} onChange={e => phoneHandler(e)} placeholder="+380662554433"/>
                        </div>
                      </div>

                      <div className="uk-margin">
                        <label className="uk-form-label" for="textRule">Коментар</label>
                        {(notesError) && <div className="uk-text-danger">{notesError}</div>}
                        <textarea name='notes' id="textRule" className="uk-textarea" rows="5" value={notes}  onChange={e => notesHandler(e)} aria-label="Textarea"></textarea>
                      </div>

                  </div>
                </div>
        
                 
             <p className="uk-text-right">
             <button className="uk-button uk-button-default" type="button" uk-toggle="target: #modal-edit-client" onClick={() => history(OBJECTS_ROUTE + '/' + params.clientId + '/' + params.objectId)}>Відмінити</button>
              <button disabled={!formValid} className="uk-button uk-button-primary" type="submit" uk-toggle="target: #modal-edit-client">Зберегти</button>
             </p>
        </form>

           </div>        
     </div>
  );
});

export default EditClient;