import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "..";
import { useNavigate, useParams, useLocation} from "react-router-dom";
import TaskDashboard from "../components/TaskDashboard";
import { crmRequest } from "../http/userAPI";
import { HOME_ROUTE } from "../utils/consts";

const Home = observer(() => {

 
  const {task, user} = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [userUuid, setUserUuid] = useState('');
  const [dashboardActive, setDashboardActive] = useState(false);
  const permissionDashboard = user._permissions.includes('dashboard/main');
  const [body, setBody] = useState(false);
  const params = useParams();
  const location = useLocation();
  const allTask = location.pathname === HOME_ROUTE
  const myTask = location.pathname === '/my'

   let Uuid = {uuid: localStorage.getItem('uuid')}

   console.log(task._taskForDashboard);

   console.log(location)

      useEffect(() => {
        if(userUuid === ''){
          setUserUuid({uuid: localStorage.getItem('uuid')})
        }
     }, [])

   useEffect(() => {
       if(myTask){
         setBody(true);
      }else(setBody(false))
     }, [body, location.pathname])

    //console.log(Uuid);

    const logCheck = async () => {
      let checkUser = await crmRequest('auth/check', true, Uuid); 
     return checkUser;
    }

    useEffect(() => {
      if(Uuid.uuid  !== null){
       logCheck().then(data => {
        setDashboardActive(true);
         user.setLoginUser(data.user);
         user.setRole(data.roles);
         user.setRolesFilter(Object.entries(data.roles));
         user.setPermissions(data.permissions);
         user.setStatus(data.status);
         
      }).finally(() => setLoading(false))
    }
    },[userUuid, dashboardActive, body])

    

    let requestBody = {
      "my": body,
    }

  const tasktListForDashBoard = async () => {
    let tasks = await crmRequest('dashboard/main', true, requestBody);
    return tasks;
  }

  useEffect(()=> {

    if( userUuid.uuid  !== undefined){
      tasktListForDashBoard().then(tasks => {
        task.setTaskForDashboard(tasks)
      }).finally(() => setLoading(false))
  }
     }, [userUuid, body])

console.log(userUuid)

  return (
    <div className="uk-container uk-container-xlarge">
      
      <div>
        <div className="">
          {permissionDashboard
          ?
          <TaskDashboard title='Задачі'/>
          :
          <div>Задачі не знайдені</div>
        }
        </div>
      </div>
        
    </div>
  );
});

export default Home;