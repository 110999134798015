import React, {useEffect, useState, useRef, useContext} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { crmRequest } from "../http/userAPI";
import { CLIENTS_ROUTE } from "../utils/consts";
import { Context } from "..";

const EditClient = observer(() => {
  const {user} =useContext(Context);
  const params = useParams(); 
  const history = useNavigate();
  const [nickClient, setNickClient] = useState('');
  const [firstNameClient, setFirstNameClient] = useState('');
  const [lastNameClient, setLastNameClient] = useState('');
  const [phoneClient, setPhoneClient] = useState('');
  const [notesClient, setNotesClient] = useState('');
  const [changeClient, setChangeClient] = useState('');

  const [nickDirty, setNickDirty] = useState(false);
  const [firstNameDirty, setFirstNameDirty] = useState(false);
  const [lastNameDirty, setLastNameDirty] = useState(false);
  const [phoneDirty, setPhoneDirty] = useState(false);

  const [nickError, setNickError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [notesError, setNotesError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [modalActive, setModalActive] = useState(true);
  const modalRef = useRef(null);
  const permissionActive = user._permissions.filter(elem => elem === 'clients/activate');
  const permissionDeactive = user._permissions.filter(elem => elem === 'clients/deactivate');

  useEffect(()=> {
    if(nickError || firstNameError || lastNameError || phoneError || notesError){
      setFormValid(false)
    } else {
      setFormValid(true)
    }

  },[nickError, firstNameError, lastNameError, phoneError, notesError])


  const infoClient = {
    "id":params.id,
    "nick_name": nickClient,
    "first_name": firstNameClient,
    "last_name": lastNameClient,
    "phone": phoneClient,
    "notes": notesClient
  }

  //console.log(infoClient);


  let requestBody = {
    "id":params.id,
  }

  const oneClient = async () => {
    let cl = await crmRequest('clients/get', true, requestBody);
    return cl;
  }


 

  useEffect(() => {
    oneClient().then(client => {
      setNickClient(client.nick_name);
      setFirstNameClient(client.first_name);
      setLastNameClient(client.last_name);
      setPhoneClient(client.phone);
      setNotesClient(client.notes);
      setChangeClient(client.active);
    })
  }, [])

  const deactivate = async () => {
    let deactive = await crmRequest('clients/deactivate', true, requestBody)
    setChangeClient( '0');
}

const activate = async () => {
  let active = await crmRequest('clients/activate', true, requestBody)
  setChangeClient('1');
}


  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'nickname' :
        setNickDirty(true)
        break
      case 'name':
        setFirstNameDirty(true)
        break
      case 'lastName':
        setLastNameDirty(true)
        break
      case 'phoneClient':
        setPhoneDirty(true)
        break
      
    }
  }


   const nickHandler = (e) => {
    setNickClient(e.target.value)
    if(e.target.value.length < 2) {
      setNickError('Назва надто коротка')
    }else if(e.target.value.length > 100){
      setNickError('Назва задовга')
    }else if(!e.target.value){
      setNickError("Назва не може бути порожньою")
    }
    else{
      setNickError('')
    }
   }

   const nameHandler = (e) => {
    setFirstNameClient(e.target.value)
    if(e.target.value.length < 2) {
      setFirstNameError('Ім*я надто коротке')
    }else if(e.target.value.length > 100){
      setFirstNameError('Ім*я задовге')
    }else if(!e.target.value){
      setFirstNameError("Ім*я не може бути порожнє")
    }
    else{
      setFirstNameError('')
    }
   }

   const surnameHandler = (e) => {
    setLastNameClient(e.target.value)
    if(e.target.value.length < 2) {
      setLastNameError('Прізвище надто коротке')
    }else if(e.target.value.length > 100){
      setLastNameError('Прізвище задовге')
    }else if(!e.target.value){
      setLastNameError("Прізвище не може бути порожнє")
    }
    else{
      setLastNameError('')
    }
   }

   const notesHandler = (e) => {
    setNotesClient(e.target.value)
    if(e.target.value.length > 100){
      setNotesError('Коментарі задовгі')
    } else{
      setNotesError('')
    }
   }

   const phoneHandler = (e) => {
    setPhoneClient(e.target.value)
    const re = /\+\d{12,15}$/;
    if(!re.test(String(e.target.value).toLocaleLowerCase())){
      setPhoneError('Некоректний номер')
    }else{
      setPhoneError('')
    }
   }

  const editClient = async () => {
    let editcl = await crmRequest('clients/edit', true, infoClient);
    history(CLIENTS_ROUTE + '/' + requestBody.id);
  }

  const keyHandler = (e) => {
    if (e.key === 'Enter') { //якщо натиснута клавіша Enter
      e.preventDefault(); // запобігає стандартній поведінці Enter
      editClient(); // викликаємо обробник відправки форми
    }
    }

    const submitForm = (e) => {
        e.preventDefault(); 
        editClient(); 
      }

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
          setModalActive(false);
          history(CLIENTS_ROUTE + '/' + requestBody.id); 
        }
      };
  
      const handleTab = (event) => {
        if (event.key === 'Tab' && modalRef.current) {
          const focusableElements = modalRef.current.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
         // const touchAction = 'none';
          //document.documentElement.style.touchAction= touchAction;
          //console.log(document.documentElement)
          const firstElement = focusableElements[0];
          const lastElement = focusableElements[focusableElements.length - 1];
         // console.log(focusableElements);
          //console.log(firstElement);
          //console.log(lastElement);
  
  
          if (event.shiftKey) { // Shift + Tab
            if (document.activeElement === firstElement) {
              event.preventDefault();
              lastElement.focus();
            }
          } else { // Tab
            if (document.activeElement === lastElement) {
              event.preventDefault();
              firstElement.focus();
            }
          }
        }
      };
  
      // Додаємо обробчик подій
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keydown', handleTab);
  
      // Видаляємо обробчик подій при розмонтурованні компонента
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('keydown', handleTab);
      };
    }, [setModalActive]);
  
    if (!modalActive) return null; // Якщо модальне вікно закрито, нічого не рендеримо
  
  

  return (
    <div   className={modalActive ? "uk-modal uk-open" : "uk-modal"} style={{zIndex: '1011', display: 'block'}} tabIndex='-1' >
          
          <div className="uk-modal-dialog uk-modal-body" ref={modalRef}  role='alertdialog' aria-modal='true' onClick={e => e.stopPropagation()}>
            <div  className="uk-modal-title uk-flex uk-flex-between">
              <h2>Відредагуйте клієнта</h2>
              <div>
                          {changeClient === '1' && permissionDeactive
                          ? 
                          <a href="#" className="uk-icon-link uk-icon uk-text-success uk-margin-small-left" onClick={deactivate} uk-icon="unlock"></a>
                          :
                          null
                          }
                          {changeClient === '0' && permissionActive
                          ? 
                          <a href="#" className="uk-icon-link uk-icon uk-text-danger uk-margin-small-left" onClick={activate}  uk-icon="lock"></a>
                          :
                          null
                          }
                </div>
            </div>
             
   
             <form className="uk-form-stacked"  onSubmit={e => submitForm(e)}>
              <div className ="uk-card">

        
  
                <div className="uk-card-body">

                  <div  className="uk-margin uk-text-left">
                    <label className="uk-form-label" for="nickNameRule">Назва клієнта <span className="uk-text-danger">*</span></label>
                    <div className="uk-form-controls">
                      {(nickDirty && nickError) && <div className="uk-text-danger uk-text-large">{nickError}</div>}
                      <input name="nickname" onBlur={e => blurHandler(e)} onKeyDown={keyHandler}  className="uk-input" id="nickNameRule" type="text" maxLength="100" value={nickClient} onChange={e => nickHandler(e)}/>
                    </div>


                    <div  className="uk-margin">
                      <label className="uk-form-label" for="nameRule">Ім'я клієнта</label>
                      <div className="uk-form-controls">
                      {(firstNameDirty && firstNameError) && <div className="uk-text-danger">{firstNameError}</div>}
                        <input name="name" onBlur={e => blurHandler(e)} onKeyDown={keyHandler} className="uk-input" id="nameRule" type="text" maxLength="100" value={firstNameClient} onChange={e => nameHandler(e)}/>
                      </div>
                      </div>

                    <div  className="uk-margin">
                      <label className="uk-form-label" for="lastNameRule">Прізвище клієнта</label>
                      <div className="uk-form-controls">
                      {(lastNameDirty && lastNameError) && <div className="uk-text-danger">{lastNameError}</div>}
                        <input name="lastName" onBlur={e => blurHandler(e)} onKeyDown={keyHandler} className="uk-input" id="lastNameRule" type="text" maxLength="100" value={lastNameClient} onChange={e => surnameHandler(e)}/>
                      </div>
                    </div>

                    <div  className="uk-margin">
                      <label className="uk-form-label" for="phoneClientRule">Номер телефону клієнта <span className="uk-text-danger">*</span></label>
                      <div className="uk-form-controls">
                      {(phoneDirty && phoneError) && <div className="uk-text-danger">{phoneError}</div>}
                        <input name="phoneClient" onBlur={e => blurHandler(e)} onKeyDown={keyHandler} className="uk-input" id="phoneClientRule" type="tel" maxLength="16" value={phoneClient} onChange={e => phoneHandler(e)} placeholder="+380662554433"/>
                      </div>
                    </div>

                    <div className="uk-margin">
                      <label className="uk-form-label" htmlFor="textRule" for="textRule">Коментар</label>
                      {(notesError) && <div className="uk-text-danger">{notesError}</div>}
                      <textarea name='notes' id="textRule" className="uk-textarea" rows="5" value={notesClient}  onChange={e => notesHandler(e)} aria-label="Textarea"></textarea>
                    </div>

                
                  </div>
                </div>
              </div>
            
                    
                <p className="uk-text-right">
                <button className="uk-button uk-button-default" type="button" uk-toggle="target: #modal-edit-client" onClick={() => history(CLIENTS_ROUTE + '/' + requestBody.id)}>Відмінити</button>
                  <button disabled={!formValid} className="uk-button uk-button-primary" type="submit" uk-toggle="target: #modal-edit-client">Зберегти</button>
                </p>

            </form> 
           </div>        
     </div>
  );
});

export default EditClient;