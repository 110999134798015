import { makeAutoObservable } from "mobx";




export default class StatusCrm {
  constructor() {
    this._status = [
      {id: 1, name: 'Нова', role: 'manager', dateAdd: '15.06.24'},
      {id: 2, name: 'Прийняв', role: 'worker', dateAdd: '04.05.24'},
      {id: 3, name: 'В роботі', role: 'worker', dateAdd: '01.07.24'},
      {id: 4, name: 'Виконано', role: 'worker', dateAdd: '01.07.24'},
      {id: 5, name: 'Не виконано', role: 'worker', dateAdd: '01.07.24'},
    ]
    
    makeAutoObservable(this)
  }

  setStatus(status) {
    this._status = status
  }

  get status() {
    return this._status
  }

}