import React, { useContext, useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { crmRequest } from "../../http/userAPI";
import { useInput } from "../hooks/useInput";
import { Context } from "../..";


const AddClient = observer(({active, setActive}) => {
  const {client} = useContext(Context);
  const offset = client._limit * (client._page - 1);
  const nick_name = useInput('', {isEmpty: true, minLength: 2, maxLength: 100});
  const first_name = useInput('', {maxLength: 100});
  const last_name = useInput('', {maxLength: 100});
  const phone_name = useInput('', {isEmpty: true, maxLength: 16, phoneError: false});

  const notes_name = useInput('', {});
  const modalRef = useRef(null);

  const touchAction = '';
  const classModal = 'uk-modal-page';
  
  document.documentElement.style.touchAction= touchAction;
  document.documentElement.classList = classModal;
  //console.log(document.documentElement)

 
  



  const infoClient = {
    "nick_name": nick_name.value,
    "first_name": first_name.value,
    "last_name": last_name.value,
    "phone": phone_name.value,
    "notes": notes_name.value
  }


  let requestBody = {
    "limit": client._limit,
    "offset": offset,
    "active": [1,0], 
    "search": ''
  }

  const clientList = async () => {
    let cl = await crmRequest('clients/list', true, requestBody);
    return cl;
  }

  const addClient = async () => {
    let addcl = await crmRequest('clients/add', true, infoClient);
    clientList().then(cl => {
      client.setClients(cl.list)
      client.setTotalCount(cl.count)
    });

    setActive(false);
    
    nick_name.value = ''; 
    first_name.value = ''; 
    last_name.value = ''; 
    phone_name.value = ''; 
    notes_name.value = ''; 
    
  }

  const keyHandler = (e) => {
      e.preventDefault();
      addClient();
  }


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setActive(false); 
      }
    };

    const handleTab = (event) => {
      if (event.key === 'Tab' && modalRef.current) {
        const focusableElements = modalRef.current.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const touchAction = 'none';
        document.documentElement.style.touchAction= touchAction;
        //console.log(document.documentElement)
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];
       // console.log(focusableElements);
        //console.log(firstElement);
        //console.log(lastElement);


        if (event.shiftKey) { // Shift + Tab
          if (document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          }
        } else { // Tab
          if (document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }
    };

    // Додаємо обробчик подій
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keydown', handleTab);

    // Видаляємо обробчик подій при розмонтурованні компонента
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keydown', handleTab);
    };
  }, [setActive]);

  if (!active) return null; // Якщо модальне вікно закрито, нічого не рендеримо



  return (
    <div id="modal-add-client" uk-modal className={active ? "uk-modal uk-open" : "uk-modal"} style={{zIndex: '1011', display: 'block'}}>
          
          <div className="uk-modal-dialog uk-modal-body" ref={modalRef}   role='alertdialog' aria-modal='true' onClick={e => e.stopPropagation()}>
             <h2 className="uk-modal-title">Додайте клієнта</h2>
   
             <form className="uk-form-stacked" onSubmit={e => keyHandler(e)}>
              <div className ="uk-card">
  
            <div className="uk-card-body uk-padding-small">

              <div  className="uk-margin uk-text-left">
                <label className="uk-form-label" for="nickNameRule">Назва клієнта <span className="uk-text-danger">*</span></label>
                <div className="uk-form-controls" >
                  {(nick_name.isDirty && nick_name.isEmpty) && <div className="uk-text-danger">Поле не може бути порожнім</div>}
                  {(nick_name.isDirty && nick_name.minLengthError ) && <div className="uk-text-danger">Довжина коротка</div>}
                  {(nick_name.isDirty && nick_name.maxLengthError ) && <div className="uk-text-danger">Довжина довга</div>}
                  <input name="nickname" tabindex="0"  onBlur={e => nick_name.onBlur(e)}  className="uk-input" value={nick_name.value} onChange={e => nick_name.onChange(e)} id="nickNameRule" type="text" maxlength="100" placeholder="Назва клієнта"/>
                </div>

                <div  className="uk-margin">
                  <label className="uk-form-label" for="nameRule">Ім'я клієнта</label>
                  <div className="uk-form-controls">
                  
                  {(first_name.isDirty && first_name.minLengthError ) && <div className="uk-text-danger">Довжина не корректна</div>}
                  {(first_name.isDirty && first_name.maxLengthError ) && <div className="uk-text-danger">Довжина довга</div>}
                    <input name="name" tabindex="0" onBlur={e => first_name.onBlur(e)}  className="uk-input" id="nameRule"  value={first_name.value} onChange={e => first_name.onChange(e)} type="text" maxlength="100" placeholder="Ім'я клієнта"/>
                  </div>
                  </div>

                <div  className="uk-margin">
                  <label className="uk-form-label" for="lastNameRule">Прізвище клієнта</label>
                  <div className="uk-form-controls">
                 
                  {(last_name.isDirty && last_name.minLengthError ) && <div className="uk-text-danger">Довжина не корректна</div>}
                  {(first_name.isDirty && first_name.maxLengthError ) && <div className="uk-text-danger">Довжина довга</div>}
                    <input name="lastName" tabindex="0"  onBlur={e => last_name.onBlur(e)}  className="uk-input" id="lastNameRule" value={last_name.value} onChange={e => last_name.onChange(e)} type="text" maxlength="100" placeholder="Прізвище клієнта"/>
                  </div>
                </div>

                <div  className="uk-margin">
                  <label className="uk-form-label" for="phoneClientRule">Номер телефону клієнта <span className="uk-text-danger">*</span></label>
                  <div className="uk-form-controls">
                  {(phone_name.isDirty && phone_name.isEmpty) && <div className="uk-text-danger">Поле не може бути порожнім</div>}
                  {(phone_name.isDirty && phone_name.minLengthError ) && <div className="uk-text-danger">Довжина не корректна</div>}
                  {(phone_name.isDirty && phone_name.phoneError ) && <div className="uk-text-danger">Не корректний номер</div>}
                    <input name="phoneClient" tabindex="0" onBlur={e => phone_name.onBlur(e)}  className="uk-input" id="phoneClientRule" value={phone_name.value} onChange={e => phone_name.onChange(e)} type="tel" maxlength="16" placeholder="+380660010001"/>
                  </div>
                </div>


                <div className="uk-margin">
                  <label for="texterRule">Коментар
                  {(notes_name.maxLengthError ) && <div className="uk-text-danger">Текст довгий</div>}
                  <textarea className="uk-textarea" tabindex="0" id="texterRule"  onBlur={e => notes_name.onBlur(e)}  rows="5" placeholder="Напишіть коментар" value={notes_name.value} name="texterRule" onChange={e => notes_name.onChange(e)} aria-label="Textarea"></textarea>
                </label>
                </div>
              </div>
            </div>
          </div>

                 
             <p className="uk-text-right">
                <button className="uk-button uk-button-default" type="button" tabindex="0" uk-toggle="target: #modal-add-client" onClick={() => setActive(false)}>Відмінити</button>
                <button className="uk-button uk-button-primary" tabindex="0" disabled={!nick_name.inputValid || !phone_name.inputValid} type="submit" uk-toggle="target: #modal-add-client" >Зберегти</button>
             </p>
             </form>  
           </div>  
                       
     </div>

    
  );
});

export default AddClient;