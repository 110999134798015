import React, { useContext } from "react";
import Menu from "../components/Menu";
import TableWorker from "../components/TableWorker";
import { Context } from "..";
import { observer } from "mobx-react-lite";


const Workers = observer(() => {

  const {user} = useContext(Context);

  return (
    <div className="uk-container uk-container-xlarge uk-padding-small">
      
        <div className="">
        <TableWorker title='Працівники' object={user._user}/>
        </div>
   
     
    </div>
  );
});

export default Workers;