import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { crmRequest } from "../http/userAPI";
import { useNavigate} from "react-router-dom";
import { EDITCLIENT_ROUTE } from "../utils/consts";
import { Context } from "..";





const CardClient = observer(({client}) => {
  const {user} = useContext(Context);
  const history = useNavigate();
  const [changeClient, setChangeClient] = useState(client);
  const permissionEdit = user._permissions.filter(elem => elem === 'clients/edit');
  

 

  
    useEffect(()=> {
      setChangeClient({...client});
    },[client])
   

  const idClient = {
    "id": client.id,
  }

  const Callto = ({ phone, children }) => {
    return <a href={`tel:${phone}`}>{children}</a>;
  };




  return (
    <div uk-grid className="uk-grid uk-margin-small">

      <div className="uk-card uk-width-expand">

        <div className ="uk-margin-bottom uk-padding-small uk-background-default">
       
                    <div className="uk-card-title uk-text-medium uk-flex uk-flex-between">
                      <div>
                      Клієнт: {changeClient.nick_name}
                      </div>
                      <div className="uk-flex">
                        <div>
                          {
                            permissionEdit 
                            ?
                           <a href="#" className="uk-icon-link uk-icon uk-text-primary uk-margin-medium-left" onClick={() => history(EDITCLIENT_ROUTE + '/' + changeClient.id)}  uk-icon="file-edit" ></a>
                            :
                            null
                          }
                            
                        </div>
                        <div>
                        {changeClient.active === '1'
                        ? 
                        <a href="#" className="uk-icon-link uk-icon uk-text-success uk-margin-small-left" uk-icon="unlock"></a>
                        :
                        <a href="#" className="uk-icon-link uk-icon uk-text-danger uk-margin-small-left" uk-icon="lock"></a>
                        }
                        </div>
                      </div>
                    </div>
                  
                  <div className="uk-accordion-content">

                    <div className="">
                      <p>Ім'я клієнта: <b>{changeClient.first_name}</b></p>
                      <p>Прізвище клієнта: <b>{changeClient.last_name}</b></p>
                      <p>Номер телефону: <b> <Callto className="uk-margin-left" phone={changeClient.phone}>{changeClient.phone}</Callto></b></p>
                      <p>Коментар: <b>{changeClient.notes}</b></p>
                    </div>

                    <div className="uk-card-footer">

                      <div>
                        {/* 
                        <div uk-grid className="uk-grid uk-margin-bottom uk-margin-top">
                          <div className="uk-width-1-2@m uk-first-colum">
                            <p className="uk-article-meta uk-margin-small">{}</p>
                          </div>
                          <div className="uk-width-1-2@m">
                            <p className="uk-article-meta">{}</p>
                          </div>
                        </div>*/}
                        <div className="uk-grid uk-flex uk-flex-around">
                          <div className="uk-width-1-1 uk-first-colum uk-text-small">
                          <p className="uk-article-meta">Додано <time datetime="2024-03-15T19:00">{changeClient.time_stamp}</time></p>
                          </div>
                          <div className="uk-width-1-2@s uk-first-colum">
                          {/*   <button className="uk-button uk-button-primary uk-text-small uk-border-rounded" uk-toggle="target: #modal-add-task">Додати задачу</button>*/}
                          </div>
                            
                        </div>
                      </div>
                  </div>
                </div>
            
         

        </div>
      </div>



   


   


      
    </div>
  );
});

export default CardClient;