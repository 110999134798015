import React, { useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { NavLink, useNavigate } from "react-router-dom";
import { Context } from "../index";
import logo from '../assets/logo.png';
import avatar2 from '../assets/avatar2.png';
import { crmRequest } from "../http/userAPI";
import { TASKS_ROUTE, WORKERS_ROUTE, CLIENTS_ROUTE, LOGIN_ROUTE, HOME_ROUTE } from "../utils/consts";


const NavBarAuth = observer(() => {
  const {user} = useContext(Context);
  const [statusMyTasks, setStatusMyTasks] = useState(false);
  const [active, setActive] = useState(false);
  const permissionListClients = user._permissions.includes('clients/list');
  const permissionListTasks = user._permissions.includes('tasks/list');
  const permissionListUsers = user._permissions.includes('users/list');
 

  const history = useNavigate();

 let userUuid = {uuid: localStorage.getItem('uuid')}

  const logout = async () => {
   
   let logOutUser = await crmRequest('auth/logout', true, userUuid); 
     user.setUserUuid('');
     user.setUser({})
     user.setIsAuth(false);
     user.setLoginUser({});
     user.setRole([]);
     user.setRolesFilter([]);
     user.setPermissions([]);
     user.setStatus([]);
     localStorage.clear();
     history(LOGIN_ROUTE);

  }
 

 const pathMyTasks = () => {
  history('/my');
  setStatusMyTasks(true);
 }


  return (
   
      
    <nav className="uk-navbar-container uk-flex-wrap-around uk-padding-small uk-padding-remove-top uk-padding-remove-bottom uk-navbar uk-sticky" uk-navbar uk-sticky='sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky'>

      <div className="nav-overlay uk-navbar-left uk-grid-small uk-flex">
        {
          userUuid.uuid  !== null
          ?
          <NavLink to={HOME_ROUTE} className="uk-navbar-item uk-logo uk-padding-remove uk-margin-remove" href="#" onClick={() => setStatusMyTasks(false)}>
          <img src={logo} alt="logo" width='76'/>
        </NavLink>
          :
        <NavLink to={LOGIN_ROUTE} className="uk-navbar-item uk-logo uk-padding-remove uk-margin-remove" href="#">
          <img src={logo} alt="logo" width='76'/>
        </NavLink>
        }
       
        {
          userUuid.uuid  !== null
          ?
          <a className="uk-navbar-toggle uk-hidden@s" role="button" arial-label='Open menu' href="#"  onClick={() => setActive(true)}>
          <span uk-navbar-toggle-icon='true' className="uk-icon uk-navbar-toggle-icon">
            <svg width="20" height='20' viewBox='0 0 20 20'>
              <rect width="20" height='2' y='3' className="line-1"></rect>
              <rect width="20" height='2' y='9' className="line-2"></rect>
              <rect width="20" height='2' y='9' className="line-3"></rect>
              <rect width="20" height='2' y='15' className="line-4"></rect>
            </svg>
          </span>
        </a>
        :
        null
        }
        
      </div>

      {
        userUuid.uuid  !== null
        ?
        <div className="nav-overlay uk-navbar-center uk-visible@s">
        <ul className="uk-navbar-nav">
          {
            permissionListClients
            ?
            <li><NavLink to={CLIENTS_ROUTE} >Клієнти</NavLink></li>
            :
            null
          }
          {
            permissionListTasks
            ?
            <li><NavLink to={TASKS_ROUTE}>Задачі</NavLink></li>
            :
            null
          }
            {
              permissionListUsers
              ?
              <li><NavLink to={WORKERS_ROUTE} >Працівники</NavLink></li>
              :
              null
            } 
              
          </ul>
      </div>
        :
        null
      }

      

      <div className="nav-overlay uk-navbar-right">
        

        {
          userUuid.uuid  !== null
          ?
          <div className="uk-grid-small uk-flex" >
            <div className="uk-width-auto uk-visible@s">
                <img className="uk-border-circle" width="40" height="40" src={avatar2} alt="Avatar"/>
            </div>
            <div className="uk-width-expand uk-inline uk-padding-remove">
              
                <h6 className="uk-card-title uk-margin-remove">{user._loginUser.username} <span uk-drop-parent-icon='true' ></span></h6>
                <div className="uk-dropdown" uk-drop="mode: hover">
                  <ul className="uk-nav uk-dropdown-nav uk-margin-bottom">
                    <li className="uk-active"><a href="#" onClick={pathMyTasks}>Мої задачі{/*<span class="uk-badge">100</span>*/}</a></li>
                    <li className="uk-nav-divider"></li>
                    <li><a href="#" className="uk-text-danger" uk-drop="mode: hover" onClick={logout}>Вихід</a></li>
                  </ul>
                </div>
                </div>
        </div>
        :
        null
        }
      
      </div>

      {
        userUuid.uuid  !== null
        ?
        <div id="offcanvas-push" uk-offcanvas="overlay: false" className={active ? "uk-offcanvas uk-open" : 'uk-offcanvas' } style={{zIndex: '1011', display: 'block'}} tabIndex='-1' onClick={() => setActive(false)}>
        <div className="uk-offcanvas-bar uk-flex uk-flex-column" role="dialog">

          <button className="uk-offcanvas-close uk-margin uk-icon uk-close" type="button" uk-close aria-label='Close'>
            <svg width="14" height='14' viewBox='0 0 14 14'>
                <line fill='none' stroke='#000' strokeWidth='1.1' x1='1' y1='1' x2='13' y2='13'></line>
                <line fill='none' stroke='#000' strokeWidth='1.1' x1='13' y1='1' x2='1' y2='13'></line>
            </svg>
          </button>

          <ul className="uk-nav uk-nav-primary" uk-nav>
          {
            permissionListClients
            ?
            <li  className="uk-parent"><a href="#" onClick={() => history(CLIENTS_ROUTE)}>Клієнти</a></li>
            :
            null
          }
          {
            permissionListTasks
            ?
            <li><a href="#" onClick={() => history(TASKS_ROUTE)}>Задачі</a></li>
            :
            null
          }
            {
              permissionListUsers
              ?
              <li><a href="#" onClick={() => history(WORKERS_ROUTE)}>Працівники</a></li>
              :
              null
            } 
            <li><a href="#" role='button' className="uk-text-danger" onClick={logout}>Вихід</a></li>
          </ul>

        </div>
      </div>
        :
        null
      }

    </nav>
      
  );
});

export default NavBarAuth;