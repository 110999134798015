import { makeAutoObservable } from "mobx";

export default class ClientCrm {
  constructor() {
    this._clients = []
    this._category = [
      {id: 1, type: "усі клієнти", active: [1, 0]},
      {id: 2, type: "активні", active: [1]},
      {id: 3, type: "деактивовані", active: [0]}
    ]
    this._selectedClient ={}
    this._selectedCategory = {id: 1, category: "усі клієнти", active: [1, 0]}
    this._selectedObject ={}
    this._selectedTask ={}
    this._page = 1;
    this._totalCount = 0;
    this._limit = 10;
    
    makeAutoObservable(this)
  }

  setClients(clients) {
    this._clients = clients
  }
  setCategory(category) {
    this.setPage(1)
    this._category = category
  }
  setSelectedClient(selectedClient) {
    this.setPage(1)
    this._selectedClient = selectedClient
  }
  setSelectedCategory(selectedCategory) {
    this.setPage(1)
    this._selectedCategory = selectedCategory
  }
  setSelectedObject(selectedObject) {
    this._selectedObject = selectedObject
  }
  setSelectedTask(selectedTask) {
    this._selectedTask = selectedTask
  }
  setPage (page) {
    this._page = page
  }
  setTotalCount (totalCount) {
    this._totalCount = totalCount 
  }
  setLimit(limit) {
    this._limit = limit
  }

  get clients() {
    return this._clients
  }
  get category() {
    return this._category
  }
  get selectedClient() {
    return this._selectedClient
  }
  get selectedCategory() {
    return this._selectedCategory
  }
  get selectedObject() {
    return this._selectedObject
  }
  get selectedTask() {
    return this._selectedTask
  }
  get page() {
    return this._page
  }
  get totalCount () {
    return this._totalCount 
  }
  get limit() {
    return this._limit
  }


}