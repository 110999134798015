import React, {useContext, useEffect, useState} from "react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import { observer } from "mobx-react-lite";
import { Context } from "./index";
import NavBarAuth from "./components/NavBarAuth";
import { crmRequest } from "./http/userAPI";
import { LOGIN_ROUTE } from "./utils/consts";



const App = observer(() => {
  const {user, task} = useContext(Context);
  // //const [userUuid, setUserUuid] = useState('');
   const [loading, setLoading] = useState(true);

   let userUuid = {uuid: localStorage.getItem('uuid')}


   const logCheck = async () => {
      let checkUser = await crmRequest('auth/check', true, userUuid); 
      return checkUser;
    }

    useEffect(() => {
       if(userUuid.uuid  !== null){
       logCheck().then(data => {
         
         user.setLoginUser(data.user);
          user.setRole(data.roles);
          user.setRolesFilter(Object.entries(data.roles));
          user.setPermissions(data.permissions);
          user.setStatus(data.status);
         
       }).finally(() => setLoading(false))
     }
     },[userUuid])
  
  return (
    <BrowserRouter>
      <NavBarAuth/>
     <AppRouter/>
    </BrowserRouter>
    
  );
});

export default App;
