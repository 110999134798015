import { makeAutoObservable } from "mobx";

export default class TableDataCrm {
  constructor() {
    this._fields = [
      {id: 1, object: 'clients', pseudonym: 'Назва клієнта', name: 'Ім*я', surname: 'Прізвище', phone: 'Номер телефону'},
      {id: 2, object: 'objects', pseudonym: 'Назва клієнта', name: 'Назва об*єкта', address: 'Адреса', date: 'Дата додавання'},
      {id: 3, object: 'object', pseudonym: 'Назва клієнта', name: 'Назва об*єкта', address: 'Адреса', date: 'Дата додавання'},
      {id: 4, object: 'object', pseudonym: 'Назва клієнта', name: 'Назва об*єкта', address: 'Адреса', date: 'Дата додавання'},
      {id: 5, object: 'object', pseudonym: 'Назва клієнта', name: 'Назва об*єкта', address: 'Адреса', date: 'Дата додавання'},
    ]
    this._fieldsObjects = [
      {id: 1, pseudonym: 'Назва клієнта'},
      {id: 2, name: 'Назва об*єкта'},
      {id: 3, address: 'Адреса'},
      {id: 4, date: 'Дата додавання'},
    ]
    this._fieldsTasks = [
      {id: 1, name: 'Назва задачі'},
      {id: 2, description: 'Опис задачі'},
      {id: 3, pseudonym: 'Назва клієнта'},
      {id: 4, nameObject: 'Назва об*єкта'},
      {id: 5, dateAdd: 'Дата додавання'},
      {id: 6, dateEdit: 'Дата редагування'},
    
    ]
    this._fieldsTrustedPersons = [
      {id: 1, name: 'Імя'},
      {id: 2, phone: 'Номер телефону'},
      {id: 3, nameObject: 'Назва об*єкта'},
      {id: 4, pseudonym: 'Назва клієнта'},
      {id: 5, dateAdd: 'Дата додавання'},
      {id: 6, dateRemove: 'Дата видалення'},
    ]
    this._fieldsWorkers = [
      {id: 1, pseudonym: 'Логін'},
      {id: 2, role: 'Роль'},
      {id: 5, dateAdd: 'Дата додавання'},
      {id: 6, dateEdit: 'Дата редагування'},
      {id: 6, dateLastEnter: 'Дата останнього входу'},
      {id: 6, dateLastActive: 'Дата останньої активності'},
    ]
    makeAutoObservable(this)
  }

  setFieldsClients(fieldsClients) {
    this._fieldsClients = fieldsClients
  }

  setFieldsObjects(fieldsObjects) {
    this._fieldsObjects = fieldsObjects
  }

  setFieldsTasks(fieldsTasks) {
    this._fieldsTasks= fieldsTasks
  }

  setFieldsTrustedPersons(fieldsTrustedPersons) {
    this._fieldsTrustedPersons = fieldsTrustedPersons
  }

  setFieldsWorkers(fieldsWorkers) {
    this._fieldsWorkers = fieldsWorkers
  }

  get fieldsClients() {
    return this._fieldsClients
  }

  get fieldsObjects() {
    return this._fieldsObjects
  }

  get fieldsTasks() {
    return this._fieldsTasks
  }

  get fieldsTrustedPersons() {
    return this._fieldsTrustedPersons
  }

  get fieldsWorkers() {
    return this._fieldsWorkers
  }
}
