export const LOGIN_ROUTE = '/login';
export const HOME_ROUTE = '/';
export const TASKS_ROUTE = '/tasks';
export const CLIENTS_ROUTE = '/clients';
export const OBJECTS_ROUTE = '/objects';
export const WORKERS_ROUTE = '/users';
export const EDITWORKER_ROUTE = '/edituser';
export const EDITCLIENT_ROUTE = '/editclient';
export const EDITOBJECT_ROUTE = '/editobject';
export const EDITPERSON_ROUTE = '/editperson';
export const EDITTASK_ROUTE = '/edittask';







