import React, { useContext, useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { crmRequest } from "../../http/userAPI";
import { useInput } from "../hooks/useInput";
import { Context } from "../..";

const AddTask = observer(({active, setActive}) => {
  const {client, task, user} = useContext(Context);
  const offset = task._limit * (task._page - 1);
  const [idClient, setIdClient] = useState(null);
  const [idObject, setIdObject] = useState(null);
  const name = useInput('', {isEmpty: true, minLength: 2,  maxLength: 100});
  const description = useInput('', {isEmpty: true, minLength: 3, maxLength: 3000});
  const [termQuery, setTermQuery] = useState("free");
  const [dateQuery, setDateQuery] = useState('');
  const [levelQuery, setLevelQuery] = useState('');
  const [idUser, setIdUser] = useState(0);
  const modalRef = useRef(null);
  const [allClients, setAllClients] = useState([]);
  const [allObjects, setAllObjects] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const touchAction = '';
  const classModal = 'uk-modal-page';
  const [levelDirty, setLevelDirty] = useState(false);
  const [levelError, setLeveleError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const permissionDir = user._permissions.includes('tasks/dir');
  
  document.documentElement.style.touchAction= touchAction;
  document.documentElement.classList = classModal;
 
  useEffect(()=> {
    if(levelQuery === "" || levelQuery === "null"){
      setFormValid(false)
    } else {
      setFormValid(true)
    }
  },[levelQuery])

  const levelHandler = (e) => {
    
    if(e.currentTarget.value === "" || e.currentTarget.value === "null") {
      setLeveleError('Потрібно вибрати роль')
    }
    else{
      setLevelQuery(e.currentTarget.value)
      setLeveleError('')
    }
   }

   const blurHandlerLevel = (e) => {

    switch (e.target.name) {
      case 'level' :
        setLevelDirty(true)
        break
    }
  }

  const idClientHandler = (e) => {

     if(e === "null") {
       setIdClient(null);
   }
    else{
     setIdClient(e)  
     }
   }

   const idObjectHandler = (e) => {

     if(e === "null") {
      setIdObject(null);
   }
    else{
      setIdObject(e)  
     }
   }

    const cancelHandler = (e) => {

     if(e.target.innerText === "Відмінити"){
      let isBoss = window.confirm("Ви впевнені що хочете відмінити задачу?");
      
       if(isBoss){
        setActive(false)
       }else{
        setActive(true)
       }
     }

  }
 


  const infoTask = {
  "id_client": idClient,
  "id_object": idObject,
  "name": name.value,
  "description": description.value,
  "term": termQuery,
  "date": dateQuery,
  "level":levelQuery
  }



  const dirList = async () => {
    let dir  = await crmRequest('tasks/dir', true);
    return dir ;
  }

  let requestBody = {
    "limit": task._limit,
    "offset": offset,
    "id_client": 0,
    "id_object": 0,
    "id_user": 0,
    "term": '',
    "date": '',
    "status": '',
    "level": '', 
    "search": ''
  }

  const tasktList = async () => {
    let task = await crmRequest('tasks/list', true, requestBody);
    return task;
  }



  const addTask = async () => {
    let addTask = await crmRequest('tasks/add', true, infoTask);
    tasktList().then(el => {
      task.setTotalCount(el.count);
      task.setTask(el.list);
      task.setTaskClients(el.clients);
      task.setTaskObjects(el.objects);
      task.setTaskUsers(el.users);
    });

    setActive(false); 
    
    name.value = ''; 
    description.value = ''; 
    setIdClient(null); 
    setIdObject(null);
    setTermQuery('');
    setDateQuery('');
    setLevelQuery(''); 
  }

  const keyHandler = (e) => {
    
      e.preventDefault(); 
      addTask(); 
  
}

  useEffect(() => {
      dirList().then(dir => {
      setAllClients(dir.clients);
      setAllObjects(dir.objects);
      setAllLevels(dir.level);
    })

    }, [])


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
           setActive(false)
      }
    };

    const handleTab = (event) => {
      if (event.key === 'Tab' && modalRef.current) {
        const focusableElements = modalRef.current.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const touchAction = 'none';
        document.documentElement.style.touchAction= touchAction;
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];


        if (event.shiftKey) { // Shift + Tab
          if (document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          }
        } else { // Tab
          if (document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }
    };

    // Додаємо обробчик подій
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keydown', handleTab);

    // Видаляємо обробчик подій при розмонтурованні компонента
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keydown', handleTab);
    };
  }, [setActive]);

  if (!active) return null; // Якщо модальне вікно закрито, нічого не рендеримо



  return (
    <div id="modal-add-task" uk-modal className={active ? "uk-modal uk-open" : "uk-modal"} style={{zIndex: '1011', display: 'block'}}>
          
          <div className="uk-modal-dialog uk-modal-body" ref={modalRef} role='alertdialog' aria-modal='true' onClick={e => e.stopPropagation()}>
             <h2 className="uk-modal-title">Нова задача</h2>
   
             <form className="uk-form-stacked" onSubmit={e => keyHandler(e)}>
          <div className ="uk-card">
  
            <div className="uk-card-body uk-padding-small">

              <div  className="uk-margin">
                <label className="uk-form-label" for="nameRule">Назва <span className="uk-text-danger">*</span></label>
                <div className="uk-form-controls">
                  {(name.isDirty && name.isEmpty) && <div className="uk-text-danger">Поле не може бути порожнім</div>}
                  {(name.isDirty && name.maxLengthError ) && <div className="uk-text-danger">Довжина довга</div>}
                  <input onBlur={e => name.onBlur(e)} value={name.value} onChange={e => name.onChange(e)} name="name" className="uk-input" id="nameRule" type="text" maxlength="100" placeholder="Назва задачі"/>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="textRule" >Опис  <span className="uk-text-danger">*</span>
                  {(description.isDirty && description.isEmpty) && <div className="uk-text-danger">Поле не може бути порожнім</div>}
                  {(description.isDirty && description.maxLengthError ) && <div className="uk-text-danger">Довжина довга</div>}
                  <textarea onBlur={e => description.onBlur(e)} id="textRule" value={description.value} onChange={e => description.onChange(e)}  className="uk-textarea" rows="5" placeholder="Опишіть, що потрібно зробити" maxlength="3000" aria-label="Textarea"></textarea>
               </label>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" for="form-horizontal-select1">Клієнт</label>
                  <div className="uk-form-controls">
                    <select onChange={e => idClientHandler(e.currentTarget.value)} className="uk-select" id="form-horizontal-select1">
                      <option value='null'>-</option>
                      {
                        allClients.map(elem => 
                          elem.active === '1'
                          ?
                          <option
                            key={elem.id}
                            value={elem.id}
                        >{elem.nick_name}</option>
                        :
                        null
                        )
                      }
                    </select>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" for="form-horizontal-select2">Об'єкт</label>
                  <div className="uk-form-controls">
                    <select onChange={e => idObjectHandler(e.currentTarget.value)} className="uk-select" id="form-horizontal-select2">
                    <option value='null'>-</option>
                      {allObjects.map(object => 
                        object.active === '1' && object.id_client === idClient
                        ?
                        <option
                        key={object.id}
                        value={object.id}
                        >{object.name}</option>
                        :
                        null
                      )}
                    </select>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" for="form-horizontal-select3">Рівень <span className="uk-text-danger">*</span></label>
                  <div className="uk-form-controls">
                  {(levelDirty && levelError) && <div className="uk-text-danger uk-text-large">{levelError}</div>}
                    <select name="level" onBlur={e => blurHandlerLevel(e)} onChange={e => levelHandler(e)} className="uk-select" id="form-horizontal-select3">
                    
                     <option value='null'>-</option>
                     
                      {allLevels.map(level => 
                        <option 
                        key={level}
                        value={level}
                        >
                          {user._rolesFilter.map(role => 
                            role[0] === level
                            ?
                            <span>{role[1]}</span>
                            :
                            null
                          )}
                        </option>
                      )}
                    </select>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" for="form-horizontal-select4">Термін задачі <span className="uk-text-danger">*</span></label>
                  <div className="uk-form-controls">
                    <select onChange={e => setTermQuery(e.currentTarget.value)} className="uk-select" id="form-horizontal-select4">
                        <option value="free" selected>Без терміну</option>
                        <option value="passing">Попутно</option>
                        <option value="date" uk-toggle="target: #dateSelection">На дату</option>
                    </select>
                  </div>
                </div>

                {
                  termQuery === 'date'
                  ?
                  <div  className="uk-margin" id='dateSelection'>
                    <label className="uk-form-label" for="dateRule">Дата</label>
                      <div className="uk-form-controls">
                          <input onChange={e => setDateQuery(e.target.value)} name="date" className="uk-input" id="dateRule" type="date"/>
                       </div>
                  </div>
                  :
                  null
                }

              </div>
            </div>
          </div>
        
                 
             <p className="uk-text-right">
                 <button className="uk-button uk-button-default" type="button" tabindex="0" onClick={e => cancelHandler(e)} >Відмінити</button>
                 <button className="uk-button uk-button-primary" disabled={!name.inputValid || !description.inputValid || !formValid} type="submit" uk-toggle="target: #modal-add-task">Зберегти</button>
             </p>
          </form>   
           </div>        
     </div>
  );
});

export default AddTask;