import { makeAutoObservable } from "mobx";

export default class UserCrm {
  constructor() {
    this._isAuth = false;
    this._isAdmin = true;
    this._user =  []
    this._userUuid =  '';
    this._permissions = []
    this._category = [
      {id: 1, type: 'усі працівники', active: [1,0]},
      {id: 2, type: 'Активні', active: [1]},
      {id: 3, type: 'Не активні', active: [0]}
    ]
    this._status = [];
    this._role =[];
    this._rolesFilter = [];
    this._loginUser = {};

    this._page = 1;
    this._totalCount = 0;
    this._limit = 5;

    makeAutoObservable(this)
  }

  setIsAuth(bool) {
    this._isAuth = bool
  }

  setUserUuid(userUuid) {
    this._userUuid = userUuid
  }

  setRole(role) {
    this._role = role
  }

  setStatus(status) {
    this._status = status
  }

  setRolesFilter(rolesFilter) {
    this._rolesFilter = rolesFilter
  }

  setRolesKey(rolesKey) {
    this._rolesKey = rolesKey
  }

  setRolesValue(rolesValue) {
    this._rolesValue = rolesValue
  }

  setIsAdmin(bool) {
    this._isAdmin = bool
  }
  setPermissions(permissions) {
    this._permissions = permissions
  }
  setUser(user) {
    this._user = user
  }
  setPage(page) {
    this._page = page
  }
  setTotalCount(totalCount) {
    this._totalCount = totalCount
  }
  setLimit(limit) {
    this._limit = limit
  }
  setCategory(category) {
    this._category = category
  }

  setLoginUser(loginUser) {
    this._loginUser= loginUser
  }

  get isAuth() {
    return this._isAuth
  }

  get isAdmin() {
    return this._isAdmin
  }

  get user() {
    return this._user
  }
  get permissions() {
    return this._permissions
  }
  get loginUser() {
    return this._loginUser
  }
  get page() {
    return this._page
  }
  get totalCount() {
    return this._totalCount
  }
  get limit() {
    return this._limit
  }
  get category() {
    return this._category
  }
  get role() {
    return this._role
  }
  get rolesFilter() {
    return this._rolesFilter
  }
  get status(){
    return this._status
  }
  get userUuid() {
    return this._userUuid
  }
}