import Auth from "./pages/Auth";
import Clients from './pages/Clients';
import Client from './pages/Client';
import Object from './pages/Object';
import Tasks from './pages/Tasks';
import Home from './pages/Home';
import Task from './pages/Task';
import Workers from './pages/Workers';
import EditClient from "./pages/EditClient";
import EditObject from "./pages/EditObject";
import EditPerson from "./pages/EditPerson";
import EditTask from "./pages/EditTask";
import { LOGIN_ROUTE, HOME_ROUTE, TASKS_ROUTE, CLIENTS_ROUTE, OBJECTS_ROUTE, WORKERS_ROUTE, EDITCLIENT_ROUTE, EDITOBJECT_ROUTE, EDITPERSON_ROUTE, EDITWORKER_ROUTE, EDITTASK_ROUTE } from "./utils/consts";
import EditUser from "./pages/EditUser";


  export const ownerRoutes = [
    {
      path: LOGIN_ROUTE,
      element: <Auth/>
    },

    {
      path: HOME_ROUTE + '/:my',
      element: <Home/>
    },
    {
      path: HOME_ROUTE,
      element: <Home/>
    },
  {
    path: TASKS_ROUTE,
    element: <Tasks/>
  },
  {
    path: TASKS_ROUTE + '/:id',
    element: <Task/>
  },
  {
    path: CLIENTS_ROUTE,
    element: <Clients/>
  },
  {
    path: CLIENTS_ROUTE + '/:id',
    element: <Client/>
  },
  {
    path: OBJECTS_ROUTE + '/:clientId/:objectId',
    element: <Object/>
  },
  {
    path: WORKERS_ROUTE,
    element: <Workers/>
  },
  {
    path: EDITCLIENT_ROUTE + '/:id',
    element: <EditClient/>
  },
  {
    path: EDITWORKER_ROUTE + '/:id',
    element: <EditUser/>
  },
  {
    path: EDITOBJECT_ROUTE + '/:clientId/:objectId',
    element: <EditObject/>
  },
  {
    path: EDITPERSON_ROUTE + '/:clientId/:objectId/:personId',
    element: <EditPerson/>
  },

  {
    path: EDITTASK_ROUTE + '/:clientId/:objectId/:taskId',
    element: <EditTask/>
  },
    
    ];

export const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    element: <Auth/>
  },
];