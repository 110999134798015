import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { crmRequest } from "../../http/userAPI";
import { useInput } from "../hooks/useInput";
import { Context } from "../..";

const AddFoto = observer(({active, setActive, statusId}) => {
  const {task} = useContext(Context);
  const [idstatus, setIdStatus] = useState('');
  const params = useParams();
  const [photo, setPhoto] = useState('');
  const [modalActive, setModalActive] = useState(active);

  let requestBody = {
    "id":params.id,
  }


  const commentsTask = async () => {
    let com = await crmRequest('tasks/info', true, requestBody);
    return com;
  }



const handleFileChange = (event) => {
  const files = [...event.target.files]
  setPhoto(files);
};


// Функция для отправки файла на сервер
const handleSubmit = async (event) => {
  event.preventDefault();

 
  if (!photo) {
    alert('Please select a file.');
    return;
  }


 const formData = new FormData();

  for (let i = 0; i < photo.length; i++) {
    formData.append('files[]', photo[i]);
}
    formData.append('id', params.id);
    formData.append('id_status', idstatus);

  let uuidHeaders = {'uuid': localStorage.getItem('uuid')};

   
  
 
    let FETCH_URL = 'https://crm.pm-pool.com.ua/api/';
    if (window.location.hostname === 'localhost') {
      FETCH_URL = 'http://localhost:8010/proxy/api/';
    }

    const response = await fetch(FETCH_URL+'/api/tasks/photo', {
      headers: uuidHeaders,
      method: 'POST',
      body: formData,
    }).then(response => response.text())
            .then(result => {
                console.log('Success:', result);
                setModalActive(false); 
                setActive(false)
                setPhoto(''); 
            })
            .catch(error => {
                console.error('Error:', error);
                alert({error: error,  toString: function() {
                  return `${this.error}`
                  }})
            });

            commentsTask().then(el => {
              task.setComments(el);
            })
          

}


useEffect(()=> {
  setIdStatus(statusId);
},[])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setModalActive(false); 
      }
    };


    // Додаємо обробчик подій
    document.addEventListener('keydown', handleKeyDown);
  

    // Видаляємо обробчик подій при розмонтурованні компонента
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
   
    };
  }, [modalActive]);

  if (!modalActive) return null; // Якщо модальне вікно закрито, нічого не рендеримо

  return (
    <div id="modal-add-foto" uk-modal className={modalActive ? "uk-modal uk-open" : "uk-modal"} style={{zIndex: '1011', display: 'block'}}>
          
    <div className="uk-modal-dialog uk-modal-body" role='alertdialog' aria-modal='true' onClick={e => e.stopPropagation()}>
       <h2 className="uk-modal-title">Додайте фото</h2>

       <form  className="uk-form-stacked" id="uploadForm"  onSubmit={e => handleSubmit(e)} >
          <div uk-form-custom="target: true">
              <input type="file" multiple={true} onChange={e => handleFileChange(e)} aria-label="Custom controls"/>
              <input className="uk-input uk-form-width-medium" type="text" placeholder="Select file" aria-label="Custom controls" disabled/>
          </div>
          <button className="uk-button uk-button-primary" type="submit" >Відправити</button>
              
          <p className="uk-text-right">
              <button className="uk-button uk-button-default" type="button"  onClick={() => setActive(false)}>Закрити</button>
          </p>
       </form>
     </div>        
</div>
  );
});

export default AddFoto;