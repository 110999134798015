import { observer } from "mobx-react-lite";
import React from "react";


const Pagination = observer(({limit, totalCount, selectedPage, chosenPage}) => {
  const pageCount = Math.ceil(totalCount / limit);
  const pages = []

    for (let i = 0; i < pageCount; i++){
       pages.push(i + 1);
    }


  return (
    <nav aria-label="Pagination">
    <ul className="uk-pagination uk-flex-center" uk-margin>
      {pages.map(page => 
        <li  key={page} onClick={() => chosenPage(page)}>
        <a className={selectedPage === page ? 'uk-badge' : ''} href="#">
          {page}
          </a>
        </li>
      )}
    </ul>
</nav>
  );
});

export default Pagination;