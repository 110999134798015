import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { crmRequest } from "../../http/userAPI";
import { useInput } from "../hooks/useInput";
import { Context } from "../..";

const AddComment= observer(({active, setActive, statusId}) => {
  const {client, task, user} = useContext(Context);
  const [idstatus, setIdStatus] = useState('');
  const params = useParams();
  const note = useInput('', {isEmpty: true, maxLength: 3000});
  const [modalActive, setModalActive] = useState(active);

  let requestBody = {
    "id":params.id,
  }

  const commentsTask = async () => {
    let com = await crmRequest('tasks/info', true, requestBody);
    return com;
  }

  let requestComment = {
    "id": params.id,
    "id_status": idstatus,
    "note": note.value
  }


  const addComment = async () => {
    let addComment = await crmRequest('tasks/note', true, requestComment).then(el => {

        if (el === undefined) {
          alert('Коментар не збережен, спробуйте ще раз');
          setModalActive(true); 
            note.value = ''; 
       }else{
        setModalActive(false);
        setActive(false)
        note.value = '';  
       }
    
    });
    commentsTask().then(el => {
      task.setComments(el);
    });

  }
   

 const keyHandler = (e) => {
      
      addComment();
      e.preventDefault(); 
}


useEffect(()=> {
  setIdStatus(statusId);
},[])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setActive(false); 
      }
    };

  

    // Додаємо обробчик подій
    document.addEventListener('keydown', handleKeyDown);
  

    // Видаляємо обробчик подій при розмонтурованні компонента
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
   
    };
  }, [modalActive]);

  if (!modalActive) return null; // Якщо модальне вікно закрито, нічого не рендеримо



  return (
    <div uk-modal className={modalActive ? "uk-modal uk-open" : "uk-modal"} style={{zIndex: '1011', display: 'block'}}>
          
          <div className="uk-modal-dialog uk-modal-body"  role='alertdialog' aria-modal='true' onClick={e => e.stopPropagation()}>
             <h2 className="uk-modal-title">Додати коментар</h2>
   
             <form className="uk-form-stacked" onSubmit={e => keyHandler(e)}>
                <div className ="uk-card">
  
                  <div className="uk-card-body uk-padding-small">

                    <div className="uk-margin">
                      <label className="uk-form-label" htmlFor="textRule" >Коментар  <span className="uk-text-danger">*</span>
                        {(note.isDirty && note.isEmpty) && <div className="uk-text-danger">Поле не може бути порожнім</div>}
                        {(note.isDirty && note.maxLengthError ) && <div className="uk-text-danger">Довжина довга</div>}
                        <textarea  onBlur={e => note.onBlur(e)} id="textRule" value={note.value} onChange={e => note.onChange(e)}  className="uk-textarea" rows="5" placeholder="Опишіть, що потрібно зробити" maxlength="3000" aria-label="Textarea"></textarea>
                      </label>
                    </div>

                  </div>
               </div>

             <p className="uk-text-right">
                 <button className="uk-button uk-button-default" type="button" tabindex="0"   onClick={() => setActive(false)}>Відмінити</button>
                 <button className="uk-button uk-button-primary" disabled={!note.inputValid} type="submit" >Зберегти</button>
             </p>
          </form>   
           </div>        
     </div>
  );
});

export default AddComment;