import React, {useEffect, useState, useRef, useContext} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { crmRequest } from "../http/userAPI";
import { CLIENTS_ROUTE, WORKERS_ROUTE } from "../utils/consts";
import { Context } from "..";

const EditUser = observer(() => {
  const {user} = useContext(Context);
  const params = useParams(); 
  const history = useNavigate();
  const [username, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [active, setActive] = useState('');

  const [usernameDirty, setUserNameDirty] = useState(false);
  const [firstNameDirty, setFirstNameDirty] = useState(false);
  const [lastNameDirty, setLastNameDirty] = useState(false);
  const [phoneDirty, setPhoneDirty] = useState(false);
  const [passwordDirty, setPasswordeDirty] = useState(false);

  const [usernameError, setUsernameError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [modalActive, setModalActive] = useState(true);
  const modalRef = useRef(null);
  const permissionActive = user._permissions.includes('users/activate');
  const permissionDeactive = user._permissions.includes('users/deactivate');
 
  

  useEffect(()=> {
    if(usernameError || phoneError || passwordError){
      setFormValid(false)
    } else {
      setFormValid(true)
    }

  },[usernameError, phoneError, passwordError])

  //console.log(params.id)

  const infoUser = {
    "id": params.id,
    "username": username,
    "password": password,
    "first_name": firstName,
    "last_name": lastName,
    "phone": phone,
    "role": role
  }
  //console.log(infoUser);


  let requestBody = {
    "id":params.id,
  }

  const oneUser = async () => {
    let cl = await crmRequest('users/get', true, requestBody);
    return cl;
  }


 

  useEffect(() => {
      oneUser().then(user => {
      setUserName(user.username);
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setPhone(user.phone);
      setRole(user.role);
      setActive(user.active);
    })
  }, [])

  const deactivate = async () => {
    let deactive = await crmRequest('users/deactivate', true, requestBody)
    setActive( '0');
}

const activate = async () => {
  let active = await crmRequest('users/activate', true, requestBody)
  setActive('1');
}


  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'username' :
        setUserNameDirty(true)
        break
      case 'phone':
        setPhoneDirty(true)
        break
      
    }
  }


   const usernameHandler = (e) => {
    setUserName(e.target.value)
    if(e.target.value.length < 2) {
      setUsernameError('Логін надто короткий')
    }else if(e.target.value.length > 100){
      setUsernameError('Логін задовгий')
    }else if(!e.target.value){
      setUsernameError("Логін не може бути порожнім")
    }
    else{
      setUsernameError('')
    }
   }

   const firstNameHandler = (e) => {
    setFirstName(e.target.value)
    if(e.target.value.length < 2) {
      setFirstNameError('Ім*я надто коротке')
    }else if(e.target.value.length > 100){
      setFirstNameError('Ім*я задовге')
    }
    else{
      setFirstNameError('')
    }
   }

   const lastNameHandler = (e) => {
    setLastName(e.target.value)
    if(e.target.value.length < 2) {
      setLastNameError('Прізвище надто коротке')
    }else if(e.target.value.length > 100){
      setLastNameError('Прізвище задовге')
    }
    else{
      setLastNameError('')
    }
   }

   const passwordHandler = (e) => {
    setPassword(e.target.value)
    if(e.target.value.length < 3) {
      setPasswordError('Пароль надто короткий')
    }else if(e.target.value.length > 100){
      setPasswordError('Пароль задовгий')
    }
    else{
      setPasswordError('')
    }
   }

   const phoneHandler = (e) => {
    setPhone(e.target.value)
    const re = /\+\d{12,15}$/;
    if(!re.test(String(e.target.value).toLocaleLowerCase())){
      setPhoneError('Некоректний номер')
    }else{
      setPhoneError('')
    }
   }

  const editUser = async () => {
    let editcl = await crmRequest('users/edit', true, infoUser);
    history(WORKERS_ROUTE);
  }

  const keyHandler = (e) => {
    if (e.key === 'Enter') { //якщо натиснута клавіша Enter
      e.preventDefault(); // запобігає стандартній поведінці Enter
      editUser(); // викликаємо обробник відправки форми
    }
    }

    const submitForm = (e) => {
        e.preventDefault(); 
        editUser(); 
      }

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
          setModalActive(false);
          history(WORKERS_ROUTE); 
        }
      };
  
      const handleTab = (event) => {
        if (event.key === 'Tab' && modalRef.current) {
          const focusableElements = modalRef.current.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
         // const touchAction = 'none';
          //document.documentElement.style.touchAction= touchAction;
          //console.log(document.documentElement)
          const firstElement = focusableElements[0];
          const lastElement = focusableElements[focusableElements.length - 1];
         // console.log(focusableElements);
          //console.log(firstElement);
          //console.log(lastElement);
  
  
          if (event.shiftKey) { // Shift + Tab
            if (document.activeElement === firstElement) {
              event.preventDefault();
              lastElement.focus();
            }
          } else { // Tab
            if (document.activeElement === lastElement) {
              event.preventDefault();
              firstElement.focus();
            }
          }
        }
      };
  
      // Додаємо обробчик подій
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keydown', handleTab);
  
      // Видаляємо обробчик подій при розмонтурованні компонента
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('keydown', handleTab);
      };
    }, [setModalActive]);
  
    if (!modalActive) return null; // Якщо модальне вікно закрито, нічого не рендеримо
  
  

  return (
    <div   className={modalActive ? "uk-modal uk-open" : "uk-modal"} style={{zIndex: '1011', display: 'block'}} tabIndex='-1'>
          
          <div className="uk-modal-dialog uk-modal-body" ref={modalRef}  role='alertdialog' aria-modal='true' onClick={e => e.stopPropagation()}>
            <div  className="uk-modal-title uk-flex uk-flex-between">
              <h2>Відредагуйте працівника</h2>
              <div>
                    
                          {
                          permissionDeactive && active === '1'
                          ? 
                          <a href="#" className="uk-icon-link uk-icon uk-text-success uk-margin-small-left" onClick={deactivate} uk-icon="unlock"></a>
                          :
                          null
                          }
                          {
                          permissionActive && active === '0' 
                          ?
                          <a href="#" className="uk-icon-link uk-icon uk-text-danger uk-margin-small-left" onClick={activate}  uk-icon="lock"></a>
                          :
                          null}
                          
                </div>
            </div>
             
   
             <form className="uk-form-stacked"  onSubmit={e => submitForm(e)}>
              <div className ="uk-card">

        
  
                <div className="uk-card-body">

                  <div  className="uk-margin uk-text-left">
                    <label className="uk-form-label" for="nickNameRule">Логін <span className="uk-text-danger">*</span></label>
                    <div className="uk-form-controls">
                      {(usernameDirty && usernameError) && <div className="uk-text-danger uk-text-large">{usernameError}</div>}
                      <input name="username" onBlur={e => blurHandler(e)} onKeyDown={keyHandler}  className="uk-input" id="nickNameRule" type="text" maxLength="100" value={username} onChange={e => usernameHandler(e)}/>
                    </div>

                    <div  className="uk-margin">
                      <label className="uk-form-label" for="passwordRule">Пароль працівника <span className="uk-text-danger">*</span></label>
                      <div className="uk-form-controls">
                      {(passwordDirty && passwordError) && <div className="uk-text-danger">{passwordError}</div>}
                        <input name="password"  onKeyDown={keyHandler} className="uk-input" id="passwordRule" type="text" maxLength="100" value={password} onChange={e => passwordHandler(e)}/>
                      </div>
                      </div>


                    <div  className="uk-margin">
                      <label className="uk-form-label" for="firstNameRule">Ім'я працівника</label>
                      <div className="uk-form-controls">
                      {(firstNameDirty && firstNameError) && <div className="uk-text-danger">{firstNameError}</div>}
                        <input name="firstName" onBlur={e => blurHandler(e)} onKeyDown={keyHandler} className="uk-input" id="firstNameRule" type="text" maxLength="100" value={firstName} onChange={e => firstNameHandler(e)}/>
                      </div>
                      </div>

                    <div  className="uk-margin">
                      <label className="uk-form-label" for="lastNameRule">Прізвище працівника</label>
                      <div className="uk-form-controls">
                      {(lastNameDirty && lastNameError) && <div className="uk-text-danger">{lastNameError}</div>}
                        <input name="lastName" onBlur={e => blurHandler(e)} onKeyDown={keyHandler} className="uk-input" id="lastNameRule" type="text" maxLength="100" value={lastName} onChange={e => lastNameHandler(e)}/>
                      </div>
                    </div>

                    <div  className="uk-margin">
                      <label className="uk-form-label" for="phoneRule">Номер телефону працівника <span className="uk-text-danger">*</span></label>
                      <div className="uk-form-controls">
                      {(phoneDirty && phoneError) && <div className="uk-text-danger">{phoneError}</div>}
                        <input name="phone" onBlur={e => blurHandler(e)} onKeyDown={keyHandler} className="uk-input" id="phoneRule" type="tel" maxLength="16" value={phone} onChange={e => phoneHandler(e)} placeholder={phone}/>
                      </div>
                    </div>

                    <div className="uk-margin  uk-text-left">
                      <label className="uk-form-label" for="form-horizontal-select">Посада працівника <span className="uk-text-danger">*</span></label>
                      <div className="uk-form-controls">
                        <select className="uk-select" id="form-horizontal-select" onChange={(e) => setRole(e.currentTarget.value)}>
                          {user._rolesFilter.map(rol => 
                              rol[0] === role
                                ?
                                <option
                                selected
                                key={rol[0]}
                                value={rol[0]}
                                >{rol[1]}</option>
                                :
                                <option
                                key={rol[0]}
                                value={rol[0]}
                                >{rol[1]}</option>)
                                }
                        </select>
                      </div>
                    </div>

                
                  </div>
                </div>
              </div>
            
                    
                <p className="uk-text-right">
                <button className="uk-button uk-button-default" type="button" uk-toggle="target: #modal-edit-client" onClick={() => history(WORKERS_ROUTE)}>Відмінити</button>
                  <button disabled={!formValid} className="uk-button uk-button-primary" type="submit" uk-toggle="target: #modal-edit-client">Зберегти</button>
                </p>

            </form> 
           </div>        
     </div>
  );
});

export default EditUser;