import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UserCrm from './crm/UserCrm';
import ClientCrm from './crm/ClientCrm';
import ObjectCrm from './crm/ObjectCrm';
import PhotoCrm from './crm/PhotoCrm';
import TaskCrm from './crm/TaskCrm';
import StatusCrm from './crm/StatusCrm';
import TrustedPersonCrm from './crm/TrustedPersonCrm';
import TableCrm from './crm/TableCrm';




export const Context = createContext(null);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Context.Provider value={{
        user: new UserCrm(),
        client: new ClientCrm(),
        object: new ObjectCrm(),
        task: new TaskCrm(),
        trustedPerson: new TrustedPersonCrm(),
        photo: new PhotoCrm(),
        status: new StatusCrm(),
        tableData: new TableCrm(),
      }}>
        <App />
      </Context.Provider>
);



