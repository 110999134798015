import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "..";
import { useNavigate} from "react-router-dom";
import Pagination from './Pagination';
import { CLIENTS_ROUTE, OBJECTS_ROUTE, TASKS_ROUTE } from "../utils/consts";
import AddTask from "./modalsForm/AddTask";
import { crmRequest } from "../http/userAPI";


const TableTasks = observer(() => {

  const { task, user} = useContext(Context);
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [idClientQuery, setIdClientQuery] = useState(0);
  const [idObjectQuery, setIdObjectQuery] = useState(0);
  const [idUserQuery, setIdUserQuery] = useState(0);
  const [termQuery, setTermQuery] = useState('');
  const [dateQuery, setDateQuery] = useState('');
  const [statusQuery, setStatusQuery] = useState('');
  const [levelQuery, setLevelQuery] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const offset = task._limit * (selectedPage - 1);
  const [searchTimeout, setSearchTimeout] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const statuses = [];
  const roles = [];
  const clients = [];
  const objects = [];
  const users = [];

  


  for (let key in user._status) {
    if (user._status.hasOwnProperty(key)) {
      let statusOject = {key: key, status: user._status[key]} 
      statuses.push(statusOject);
    }
  }

  for(let key in user._role){
    if (user._role.hasOwnProperty(key)) {
      let role = {key: key, role: user._role[key]}
      roles.push(role);
    }
  }

  

  for(let key in task._taskClients){
    if (task._taskClients.hasOwnProperty(key)) {
      let client = {id: key, client: task._taskClients[key]}
      clients.push(client);
    }
  }

  for(let key in task._taskObjects){
    if (task._taskObjects.hasOwnProperty(key)) {
      let object = {id: key, object: task._taskObjects[key]}
      objects.push(object);
    }
  }

  for(let key in task._taskUsers){
    if (task._taskUsers.hasOwnProperty(key)) {
      let user = {id: key, user: task._taskUsers[key]}
      users.push(user);
    }
  }

  const permissionList = user._permissions.includes('tasks/list');
  const permissionAdd = user._permissions.includes('tasks/add');
  const permissionGet = user._permissions.includes('tasks/get');
  const permissionGetClient = user._permissions.includes('clients/get');
  const permissionGetObject = user._permissions.includes('objects/get');

  const filterTasks = (search, idClient, idObject, idUser, term, date, status, level) => {
    setSearchQuery(search);
    setIdClientQuery(idClient);
    setIdObjectQuery(idObject);
    setIdUserQuery(idUser);
    setTermQuery(term);
    setDateQuery(date);
    setStatusQuery(status);
    setLevelQuery(level);
    setSelectedPage(1);
  }

  useEffect(() => {
    setIdObjectQuery(0);
  }, [idClientQuery])

    const chosenPage = (page) => {
        setSelectedPage(page);
      }


 let requestBody = {
    "limit": task._limit,
    "offset": offset,
    "id_client": idClientQuery,
    "id_object": idObjectQuery,
    "id_user": idUserQuery,
    "term": termQuery,
    "date": dateQuery,
    "status": statusQuery,
    "level": levelQuery, 
    "search": searchQuery
  }


  const tasktList = async () => {
    let task = await crmRequest('tasks/list', true, requestBody);
    return task;
  }


 useEffect(()=> {
  if(searchQuery.length > 0) {

    if(searchTimeout != false){
      clearTimeout(searchTimeout)
    }
    setSearchTimeout(setTimeout(() => {
      tasktList().then(tasks => {
       
          task.setTotalCount(tasks.count);
          task.setTask(tasks.list);
          task.setTaskClients(tasks.clients);
          task.setTaskObjects(tasks.objects);
          task.setTaskUsers(tasks.users);
          task.setTaskLevels(tasks.levels);
      }).finally(() => setLoading(false))
  }, 500))
    }else {
    tasktList().then(tasks => {
      task.setTotalCount(tasks.count)
      task.setTask(tasks.list);
      task.setTaskClients(tasks.clients);
      task.setTaskObjects(tasks.objects);
      task.setTaskUsers(tasks.users);
      task.setTaskLevels(tasks.levels);
  }).finally(() => setLoading(false))
  }
  }, [searchQuery, offset, selectedPage, idClientQuery, idObjectQuery, idUserQuery, termQuery, dateQuery, statusQuery, levelQuery])

  const keyHandler = (e) => {
    e.preventDefault();
}

 
  return (

     <div className="uk-card uk-card-default uk-card-hover">
    
        <nav className="uk-flex uk-flex-wrap uk-flex-between" style={{paddingTop: '10px', paddingRight: '10px'}}>
            
                <div className="uk-width-1-1 uk-first-column uk-flex uk-flex-between uk-flex-wrap">
                  
                    <h3 className="uk-margin-small-left uk-text-start">Задачі</h3>

                    <form className="uk-search uk-search-default uk-flex uk-margin-small-left  uk-margin-large-right uk-margin-bottom" style={{width:"250px"}}>
                          <input className="uk-search-input" value={searchQuery} onChange={e => filterTasks(e.target.value, idClientQuery, idObjectQuery, idUserQuery, termQuery, dateQuery, statusQuery, levelQuery)}  type="search" placeholder="Пошук задачі..." aria-label="Search"/>
                          <span className="uk-search-icon-flip uk-position-center-right" uk-icon style={{paddingRight: '10px', paddingBottom: '3px'}}>
                            <svg width='20' height='20' viewBox="0 0 20 20">
                              <circle fill='none' stroke='#000' stroke-width='1.1' cx='9' cy='9' r='7'></circle>
                              <path fill='none' stroke='#000' stroke-width='1.1' d='M14,14 L18,18 L14,14 Z'></path> 
                            </svg>
                          </span>
                    </form>
                    {
                      permissionAdd
                      ?
                      <button className="uk-button uk-float-right uk-button-primary uk-margin-small-bottom uk-margin-small-left" style={{textTransform: 'none'}} onClick={() => setModalActive(true)}>Додати задачу</button>
                      :
                      null
                    }
                </div>

                <div className="uk-width-1-1">
                    <div className="uk-flex uk-flex-wrap uk-flex-around uk-margin-small-right uk-margin-remove-right@m uk-margin-bottom" style={{gap:'10px'}}>

                      <label className="uk-margin-bottom" for="">Клієнт: 
                        <select onChange={e => filterTasks(searchQuery, e.currentTarget.value, idObjectQuery, idUserQuery, termQuery, dateQuery, statusQuery, levelQuery)}  className="uk-select uk-margin-small-left" aria-label="Select " style={{width:"100px"}}>
                         <option value={0}>усі клієнти</option>
                          {
                            clients.map(client => 
                              <option key={client.id} value={client.id}>{client.client}</option>
                            )
                          }
                          
                        </select>
                      </label>

                      {idClientQuery != 0
                       ?
                      <label className="uk-margin-bottom" for="">Oб'єкт: 
                        <select onChange={e => filterTasks(searchQuery, idClientQuery, e.currentTarget.value, idUserQuery, termQuery, dateQuery, statusQuery, levelQuery)}  className="uk-select uk-margin-small-left" aria-label="Select " style={{width:"100px"}}>
                       <option value={0}>усі об'єкти</option>  
                          {
                            objects.map(obj => 
                              
                              <option key={obj.id} value={obj.id}>{obj.object}</option>
                            )
                          }
                        </select>
                      </label>
                      :
                      null
                      }


                      <label className="uk-margin-bottom" for="">Термін:
                        <select onChange={e => filterTasks(searchQuery, idClientQuery, idObjectQuery, idUserQuery, e.currentTarget.value, dateQuery, statusQuery, levelQuery)}  className="uk-select uk-margin-small-left" aria-label="Select " style={{width:"100px"}}>
                         <option value="">усі</option>
                          <option value="free">без дати</option>
                          <option value="passing">попутно</option>
                          <option value="date">дата</option>
                        </select>
                      </label>

                      {termQuery === 'date'
                       ?
                      <label className="uk-margin-bottom" for="">Дата:
                        <input onChange={e => filterTasks(searchQuery, idClientQuery, idObjectQuery, idUserQuery, termQuery, e.target.value, statusQuery, levelQuery)} className="uk-select uk-margin-small-left" type="date" name="date" value={dateQuery} style={{width:"130px"}}/>
                      </label>
                      :
                      null
                      }
                        <label className="uk-margin-bottom" for="">Статус:
                        <select onChange={e => filterTasks(searchQuery, idClientQuery, idObjectQuery, idUserQuery, termQuery, dateQuery, e.currentTarget.value, levelQuery)} className="uk-select uk-margin-small-left" aria-label="Select " style={{width:"100px"}}>
                          <option value="">усі статуси</option>
                        {statuses.map(status => 
                           <option
                           key={status.key}
                           value={status.key}
                           ><span className="uk-label" style={{backgroundColor: `${status.status.color}`}}>{status.status.name}</span>
                           </option>
                          )}
                        </select>
                        </label>

                        <label className="uk-margin-bottom" for="">Рівень:
                        <select onChange={e => filterTasks(searchQuery, idClientQuery, idObjectQuery, idUserQuery, termQuery, dateQuery, statusQuery, e.currentTarget.value)}  className="uk-select uk-margin-small-left" aria-label="Select " style={{width:"100px"}}>
                        <option value="">усі</option>
                        {task._taskLevels.map(level => 
                          roles.map(role => role.key === level ? <option key={level} value={level}>{role.role}</option> : null)
                        )}
                        </select>
                        </label>

                        <label className="uk-margin-bottom" for="">Користувач: 
                        <select onChange={e => filterTasks(searchQuery, idClientQuery, idObjectQuery, e.currentTarget.value, termQuery, dateQuery, statusQuery, levelQuery)}  className="uk-select uk-margin-small-left" aria-label="Select " style={{width:"100px"}}>
                          <option value={0}>усі</option>
                          {
                            users.map(user =>
                              <option key={user.id} value={user.id}>{user.user}</option>
                            )
                          }
                        </select>
                      </label>
                   
                    </div>
                </div>
            
              </nav>


            
    <div className="">
      <div className="uk-card-body ">
        <table className="uk-table uk-table-small uk-table-divider" style={{minHeight: "300px"}}>
          
          <thead>
            <tr>
              <th className="uk-text-small" style={{fontSize:'13px'}}>Дата</th>
              <th className="uk-text-small" style={{fontSize:'13px'}}>Назва задачі</th>
              <th className="uk-text-small uk-visible@m">Опис</th>
              <th className="uk-text-small" style={{fontSize:'13px'}}>Статус</th>
              <th className="uk-text-small uk-visible@l">Дата додавання</th>
              <th className="uk-text-small uk-visible@l">Клієнт</th>
              <th className="uk-text-small uk-visible@l">Об'єкт</th>
            </tr>
       
          </thead>
          <tbody>
            {task._task.length !== 0 && permissionList
                ?
            task._task.map(obj => 
              <tr>
                {
                  obj.term === 'date'
                  ?
                  <td className="uk-text-left uk-text-small" style={{fontSize:'12px'}}>{obj.date}</td>
                  :
                  
                    task._term.map(elem => elem.key === obj.term
                      ?
                      <td className="uk-text-left uk-text-small">{elem.type}</td>
                      :
                      null
                    )
                }
              
              {
                permissionGet
                ?
                <td className="uk-text-left uk-text-small"><a onClick={() => history(TASKS_ROUTE + '/' + obj.id)} clients={clients} objects={objects}>{obj.name}</a></td>
                :
                <td className="uk-text-left uk-text-small">{obj.name}</td>
              }
              
              <td className="uk-text-left uk-text-small uk-visible@m">{obj.description}</td>
              
                {statuses.map(status => status.key === obj.status
                 ?
                  <td className="uk-text-left uk-text-small"><span class="uk-label uk-text-small" style={{backgroundColor: `${status.status.color}`, fontSize:'12px'}}>{status.status.name}</span></td>
                  :
                 null
                )}
              
              <td className="uk-visible@l uk-text-small uk-text-left">{obj.time_stamp}</td>

              {clients.map(client => client.id === obj.id_client 
                  ?
                  permissionGetClient
                  ?
                  <td className="uk-visible@l uk-text-small uk-text-left"><a onClick={() => history(CLIENTS_ROUTE + '/' + client.id)}>{client.client}</a></td>
                  :
                  <td className="uk-visible@l uk-text-small uk-text-left">{client.client}</td>
                  :
                 null
                )
                }
              
              { permissionGetObject
                   ?
                  <td className="uk-visible@l uk-text-small uk-text-left"><a onClick={() => history(OBJECTS_ROUTE + '/' + obj.id_client + '/' + obj.id_object)}>{obj.link_objects_name}</a></td>
                  :
                  <td className="uk-visible@l uk-text-small uk-text-left">{obj.link_objects_name}</td>
                }
            
          </tr>
            
            )
          :
            <p className='uk-text-bold uk-margin-small-left uk-margin-top uk-text-uppercase' style={{textAlign: 'start'}}>Задачі не знайдені</p>
          }
    </tbody>
        </table>
      </div>

      <div className="uk-card-footer">
        <Pagination limit={task._limit} totalCount={task._totalCount} chosenPage={chosenPage} selectedPage={selectedPage}/>
      </div>
    </div>

    {modalActive &&   <AddTask active={modalActive} setActive={setModalActive} keyHandler={keyHandler}/>}
    </div>
  );
});

export default TableTasks;