import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LOGIN_ROUTE } from "../../utils/consts";



export const PrivateRoute = () => {

  let userUuid = {uuid: localStorage.getItem('uuid')}
  const location = useLocation()
console.log(userUuid.uuid);
  
  return (
    userUuid.uuid  !== null ?
    <Outlet/>
    :
    <Navigate to={LOGIN_ROUTE} state={{ from: location }} replace />
)};