import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate} from "react-router-dom";
import {TASKS_ROUTE, CLIENTS_ROUTE, HOME_ROUTE } from "../utils/consts";

const BreadCrumb = observer(({client}) => {

  const history = useNavigate();


  return (
    <div className ="uk-card uk-card-small uk-card-default uk-card-title">
      <nav aria-label="Breadcrumb">
        <ul className="uk-breadcrumb uk-padding-small uk-margin-top ">
            <li><a href="#" uk-icon="icon: home" onClick={() => history(HOME_ROUTE)}></a></li>
            <li><a href="#" onClick={() => history(CLIENTS_ROUTE)}><span className="uk-link">Клієнти</span></a></li>
            <li><span aria-current="page">{client.nick_name}</span></li>
        </ul>
      </nav>
    </div>
  );
});

export default BreadCrumb;