import React, {useState, useEffect, useContext}  from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from './Pagination';
import {Context} from '..';
import { crmRequest } from "../http/userAPI";
import { EDITPERSON_ROUTE } from "../utils/consts";
import AddTrustedPerson from "./modalsForm/AddTrustedPerson";


const TableTrusteds = observer(() => {
  const {trustedPerson, user} = useContext(Context);
  const history = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [countPersons, setCountPersons] = useState({"active": [1,0], "search": ''});
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const offset = trustedPerson._limit * (selectedPage - 1);
  const [searchTimeout, setSearchTimeout] = useState(false);
  const [active, setActive] = useState(true);
  const [modalActive, setModalActive] = useState(false);
  const permissionAdd = user._permissions.includes('persons/add');
  const permissionList = user._permissions.includes('persons/list');
  const permissionGet = user._permissions.includes('persons/get');
  const permissionEdit = user._permissions.includes('persons/edit');

  const filterPersons = (category, search) => {
    setSearchQuery(search);
    setCountPersons({"active": category, "search": search})
    setSelectedPage(1);
  }

  const chosenPage = (page) => {
    setSelectedPage(page);
  }


 let requestBody = {
    "id_object": params.objectId,
    "limit": trustedPerson._limit,
    "offset": offset,
    "active": countPersons.active, 
    "search": searchQuery
  }

  const personsList = async () => {
    let cl = await crmRequest('persons/list', true, requestBody);
    return cl;
  }

  const idActive = {
    "id": 1,
    "id_object": params.objectId,
  }

  const deactivate = async (e) => {
    const idActive = {
      "id": e.currentTarget.id,
      "id_object": params.objectId,
    }
    let deactive = await crmRequest('persons/deactivate', true, idActive);
    setActive(prevActive => !prevActive);
}

const activate = async (e) => {
  const idActive = {
    "id": e.currentTarget.id,
    "id_object": params.objectId,
  }
  let active = await crmRequest('persons/activate', true, idActive);
  setActive(prevActive => !prevActive);
}

  useEffect(()=> {
    if(searchQuery.length > 0){
      if(searchTimeout != false){
        clearTimeout(searchTimeout)
      }
      setSearchTimeout(setTimeout(() => {
        personsList().then(cl => {
          trustedPerson.setTotalCount(cl.count)
          trustedPerson.setTrustedPerson(cl.list);
      }).finally(() => setLoading(false))
      }, 500))
  }else {
    personsList().then(cl => {
      trustedPerson.setTotalCount(cl.count)
      trustedPerson.setTrustedPerson(cl.list);
  }).finally(() => setLoading(false))}
  }, [countPersons, searchQuery, offset, selectedPage, active])


  const keyHandler = (e) => {
    e.preventDefault();
    
}

const Callto = ({ phone, children }) => {
  return <a href={`tel:${phone}`}>{children}</a>;
};
  
 
  return (
    <div>
      <div className="">

          <nav className="uk-flex uk-flex-wrap uk-flex-between"  style={{paddingTop: '10px', paddingRight: '10px'}}>
            
            <div className="uk-width-1-1 uk-first-column uk-flex uk-flex-between">
                <h3 className="uk-margin-small-left">Довірені особи</h3>
              <div className="uk-margin-small-bottom">
                {
                  permissionAdd
                  ?
                  <button className="uk-button uk-float-right uk-button-primary" style={{textTransform: 'none'}} onClick={() => setModalActive(true)}>Додати особу</button>
                  :
                  null
                }
              </div>
            </div>

            <div className="uk-width-1-1 uk-margin-small-right">
                <div className="uk-margin-small uk-flex uk-flex-between uk-flex-wrap uk-margin-right uk-margin-remove-right@m">
                  <form className="uk-search uk-search-default uk-flex uk-margin-small-left uk-margin-large-right uk-margin-bottom" style={{width: '250px'}}>
                      <input className="uk-search-input" value={searchQuery} type="search" onChange={e => filterPersons(countPersons.active, e.target.value)} placeholder="Пошук особи..." aria-label="Search"/>
                      <span className="uk-search-icon-flip uk-position-center-right" uk-icon style={{paddingRight: '10px', paddingBottom: '3px'}}>
                        <svg width='20' height='20' viewBox="0 0 20 20">
                          <circle fill='none' stroke='#000' stroke-width='1.1' cx='9' cy='9' r='7'></circle>
                          <path fill='none' stroke='#000' stroke-width='1.1' d='M14,14 L18,18 L14,14 Z'></path> 
                        </svg>
                      </span>
                  </form>
                  <div>
                    <select  className="uk-select uk-margin-small-left" onChange={(e) => filterPersons(Array.from(e.currentTarget.value), searchQuery)} aria-label="Select ">
                      {
                        trustedPerson._category.map(category =>
                          <option
                            key={category.id}
                            value={category.active}>
                            {category.type}
                          </option>
                        )
                      }
                    </select>
                  </div>
                </div>
            </div>

          </nav>


          <div className="uk-card-body">
              <table className="uk-table uk-table-small uk-table-divider" style={{minHeight: "300px"}}>
                
                <thead>
                  <tr>
                    <th className="uk-text-small">Ім'я</th>
                    <th className="uk-text-small">Телефон</th>
                    <th className="uk-visible@m">Коментар</th>
                    <th className="uk-visible@l">Дата додавання</th>
                    {
                      permissionEdit
                      ?
                      <th className="uk-text-small">Дії</th>
                      :
                      null
                    }
                    
                    <th className="uk-text-small">Статус</th>
                  </tr>
            
                </thead>
                {loading
                  ?
                 <div  uk-spinner="ratio: 1"></div>
                 :
                <tbody>
                  {trustedPerson._trustedPerson.length > 0 && permissionList
                    ?
                    trustedPerson._trustedPerson.map(obj =>
                    <tr key={obj.id}>
                      <td className="uk-text-left uk-text-small">{obj.name}</td>
                      <td className="uk-text-left uk-text-small"><Callto phone={obj.phone}>{obj.phone}</Callto></td>
                      <td className="uk-visible@m uk-text-left">{obj.notes}</td>
                      <td className="uk-visible@l uk-text-left">{obj.time_stamp}</td>
                      
                         {
                          permissionEdit
                          ?
                          <td className= "uk-text-small">
                            <a href="#" className="uk-icon-link uk-icon uk-text-primary" onClick={() => history(EDITPERSON_ROUTE + '/' + params.clientId + '/' + params.objectId  + '/'+ obj.id)} uk-icon="file-edit"></a>
                          </td>
                          :
                          null
                         } 
                      <td className="uk-text-small">
                                {
                                obj.active === '1'
                                ?
                                <a href="#" id={obj.id} className="uk-icon-link uk-icon uk-text-success " uk-icon="unlock"></a>
                                :
                                <a href="#" id={obj.id} className="uk-icon-link uk-icon uk-text-danger"  uk-icon="lock"></a>
                              }
                      </td> 
                   </tr>
                  )
                    :
                    <div className="uk-margin-small-left">Довірені особи не знайдені</div>
                   }
                </tbody>
                }
              </table>
            </div>

          <div className="uk-card-footer">
              <Pagination limit={trustedPerson._limit} totalCount={trustedPerson._totalCount} chosenPage={chosenPage} selectedPage={selectedPage}/>
          </div>
      </div>

      {modalActive && <AddTrustedPerson active={modalActive} setActive={setModalActive} keyHandler={keyHandler}/>}
  
    </div>
  );
});

export default TableTrusteds;