import { makeAutoObservable } from "mobx";

export default class TrustedPersonCrm {
  constructor() {
    this._trustedPerson = [
      {id: 1, idObject: 1, name: 'Микола', phone: '0662541222', dateAdd: '15.06.24', dateRemove: '20.06.24'},
      {id: 2, idObject: 1, name: 'Степан', phone: '0662541233', dateAdd: '04.05.24', dateRemove: null},
      {id: 3, idObject: 3, name: 'Марія', phone: '0662541244', dateAdd: '01.07.24', dateRemove: null},
      {id: 4, idObject: 3, name: 'Лія', phone: '0662541244', dateAdd: '01.07.24', dateRemove: null},
      {id: 5, idObject: 4, name: 'Катерина', phone: '0662541244', dateAdd: '01.07.24', dateRemove: null},
      {id: 6, idObject: 5, name: 'Петро', phone: '0662541244', dateAdd: '01.07.24', dateRemove: null},
    ]
    this._category = [
      {id: 1, type: 'усі', active: [1, 0]},
      {id: 2, type: 'активні', active: [1]},
      {id: 3, type: 'неактивні', active: [0]}
    ]
    this._page = 1;
    this._totalCount = 0;
    this._limit = 5;
    makeAutoObservable(this)
  }

  setTrustedPerson(trustedPerson) {
    this._trustedPerson= trustedPerson
  }
  setCategory(category) {
    this._category= category
  }
  setPage(page) {
    this._page= page
  }
  setTotalCount(totalCount) {
    this._totalCount = totalCount
  }
  setLimit(limit) {
    this._limit= limit
  }

  get trustedPerson() {
    return this._trustedPerson
  }
  get category() {
    return this._category
  }
  get page() {
    return this._page
  }
  get totalCount() {
    return this._totalCount
  }
  get limit() {
    return this._limit
  }

}