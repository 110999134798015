
export const crmRequest = async (method, uuid, data) => {

  let uuidHeaders = {};

  //console.log(data);

  if(uuid){
  uuidHeaders = {
    'uuid': localStorage.getItem('uuid')
  }
}
  try{

    let FETCH_URL = 'https://crm.pm-pool.com.ua/api/';
    if (window.location.hostname === 'localhost') {
      FETCH_URL = 'http://localhost:8010/proxy/api/';
    }

    const response = await fetch(FETCH_URL+method, {
      method: "POST",
      body: JSON.stringify(data),
      headers: uuidHeaders,
    })

   // console.log(response);

      if(response.status === 200){
        
        const result =  response.json();
       // console.log(result);
              return result;
      } else if (response.status === 401) {
        let resp = await response.json();
        let error = resp.error;
        let message = resp.message;
        
        alert({error: error, message: message, toString: function() {
        return `${this.error}: ${this.message}`
        }})
        //localStorage.clear();
        //alert('Unauth');
       //window.location.href = "/login";  
      } else {
        console.log('fetch != 200')
        //console.log(await response.json())
        let resp = await response.json();
        let error = resp.error;
        let message = resp.message;
        
        alert({error: error, message: message, toString: function() {
        return `${this.error}: ${this.message}`
        }})
        //localStorage.clear();
      }
     
  } catch(error){
    console.error(error);
  }
 
}












