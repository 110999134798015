import React, {useState, useEffect, useContext} from "react";
import { observer } from "mobx-react-lite";
import { Context } from "..";
import Pagination from './Pagination';
import { useNavigate } from "react-router-dom";
import AddUser from '../components/modalsForm/AddUser';
import { crmRequest } from "../http/userAPI";
import { EDITWORKER_ROUTE } from "../utils/consts";


const TableWorker = observer(({title, object}) => {
  const {user} = useContext(Context);
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [countUsers, setCountUsers] = useState({"active": [1,0], "search": ''});
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const offset = user._limit * (selectedPage - 1);
  const [searchTimeout, setSearchTimeout] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const roles = [];
  

  for(let key in user._role){
    if (user._role.hasOwnProperty(key)) {
      roles.push(key, user._role[key]);
    }
  }
 


  const permissionAdd = user._permissions.includes('users/add');
  const permissionEdit = user._permissions.includes('users/edit');
  const permissionList = user._permissions.includes('users/list');

 
const filterUsers = (category, search) => {
    setSearchQuery(search);
    setCountUsers({"active": category, "search": search});
    setSelectedPage(1);
  }


  const chosenPage = (page) => {
    setSelectedPage(page);
  }

 let requestBody = {
    "limit": user._limit,
    "offset": offset,
    "active": countUsers.active, 
    "search": searchQuery
  }

  const userList = async () => {
    let users = await crmRequest('users/list', true, requestBody);
    return users;
  }


  useEffect(()=> {
    if(searchQuery.length > 0) {

     if(searchTimeout != false){
      clearTimeout(searchTimeout)
    }
    setSearchTimeout(setTimeout(() => {
      userList().then(us => {
        user.setTotalCount(us.count);
        user.setUser(us.list);
    }).finally(() => setLoading(false))
    }, 500))
  }else{
    userList().then(us => {
      user.setTotalCount(us.count);
      user.setUser(us.list);
  }).finally(() => setLoading(false))
  }
  }, [countUsers, searchQuery, offset, selectedPage])


  const keyHandler = (e) => {
       e.preventDefault();
      
   }

   const Callto = ({ phone, children }) => {
    return <a href={`tel:${phone}`}>{children}</a>;
  };


   
   

  





  return (
    <div className="uk-card uk-card-default uk-card-hover">

        <div className="">

        <nav className="uk-flex uk-flex-wrap uk-flex-between" style={{paddingTop: '10px', paddingRight: '10px'}}>
          
          <div className="uk-width-1-6@m uk-width-1-4 uk-first-column">
              <h3 className="uk-margin-small-left">{title}</h3>
            </div>
        

          <div className="uk-width-1-1 uk-width-1-2@m uk-flex-last">
              <div className="uk-margin-small uk-flex uk-flex-between uk-flex-wrap uk-margin-right uk-margin-remove-right@m">
                <form className="uk-search uk-search-default uk-flex uk-margin-small-left uk-margin-large-right uk-margin-bottom" style={{width:"250px"}}>
                    <input className="uk-search-input" value={searchQuery} onChange={e => filterUsers(countUsers.active, e.target.value)} type="search" placeholder="Пошук працівника..." aria-label="Search"/>
                    <span className="uk-search-icon-flip uk-position-center-right" uk-icon style={{paddingRight: '10px', paddingBottom: '3px'}}>
                      <svg width='20' height='20' viewBox="0 0 20 20">
                        <circle fill='none' stroke='#000' stroke-width='1.1' cx='9' cy='9' r='7'></circle>
                        <path fill='none' stroke='#000' stroke-width='1.1' d='M14,14 L18,18 L14,14 Z'></path> 
                      </svg>
                    </span>
                </form>
                <div>
               <select onChange={(e) => filterUsers(Array.from(e.currentTarget.value), searchQuery)}  className="uk-select uk-margin-small-left" aria-label="Select ">
               {user._category.map(category =>
                <option
                  key={category.id}
                  value={category.active}
                >
                  {category.type}
                </option>
               )}
                </select>
                </div>
            </div>
          </div>
          <div className="uk-width-1-4@m uk-width-2-3 uk-first-column uk-flex-last@m uk-margin-small-bottom">
            {
              permissionAdd
              ?
              <button className="uk-button uk-float-right  uk-button-primary" style={{textTransform: 'none', paddingLeft: '10px', paddingRight: '10px' }} onClick={() => setModalActive(true)}>Додати працівника</button>
              :
              null
            }
             
          </div>
          
        </nav>
     

      
      <div>
      <div className="uk-card-body">
        <table className="uk-table uk-table-small uk-table-divider" style={{minHeight: "300px"}}>
          
          <thead>
            <tr>
              <th>Логін</th>
              <th className="uk-visible@m">Ім'я</th>
              <th className="uk-visible@m">Прізвище</th>
              <th className="uk-visible@l">Номер телефону</th>
              <th>Посада</th>
              <th className="uk-visible@s">Дата додавання</th>
              <th className="uk-visible@l">Дата логіювання</th>
              {
                permissionEdit
                ?
                <th>Дії</th>
                :
                null
              }
              <th>Статус</th>
          
            </tr>
       
          </thead>
          
            {loading
              ?
              <div  uk-spinner="ratio: 1"></div>
              :
            <tbody>
            {user._user.length != 0 && permissionList
              ?

              user._user.map(obj =>
              <tr key={obj.id}>
              <td className="uk-text-left">{obj.username}</td>
              <td className="uk-visible@m uk-text-left">{obj.first_name}</td>
              <td className="uk-visible@m uk-text-left">{obj.last_name}</td>
              <td className="uk-visible@l uk-text-left"><Callto phone={obj.phone}>{obj.phone}</Callto></td>
              
              { 
               roles.map(role => 
                role === obj.role
                  ?
                 <td className="uk-text-left">{user._role[role]}</td> 
                  :
                  null
                )
              }
              <td className="uk-visible@s uk-text-left">{obj.time_stamp}</td>
              <td className="uk-visible@s uk-text-left">{obj.time_login}</td>
              {
                permissionEdit
                ?
                <td className="uk-text-left"><a href="#" className="uk-icon-link uk-icon uk-text-primary" onClick={() => history(EDITWORKER_ROUTE + '/' + obj.id)} uk-icon="file-edit"></a></td>
                :
                null
              }
             
              <td className="uk-text-left">
                {obj.active === '1'
                 ?
                  <a href="#" className="uk-icon-link uk-icon uk-text-success" uk-icon="unlock"></a>
                 :
                  <a href="#" className="uk-icon-link uk-icon uk-text-danger" uk-icon="lock"></a>
                }
              </td>
          </tr>
            )
            :
            <div className="uk-margin-left">Працівники не знайдені</div>
          }
          </tbody>
          }
        </table>
      </div>
      

          <div className="uk-card-footer">
            <Pagination limit={user._limit} totalCount={user._totalCount} chosenPage={chosenPage} selectedPage={selectedPage}/>
          </div>
      </div>
    </div> 


    {modalActive &&  <AddUser active={modalActive} setActive={setModalActive} keyHandler={keyHandler}/>}

    </div>
  );
});

export default TableWorker;