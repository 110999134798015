import React, { useContext } from "react";
import { Routes, Route, useLocation, Outlet, Navigate } from "react-router-dom";
import { ownerRoutes } from "../routes";
import {Context} from '../index'
import Auth from "../pages/Auth";
import { LOGIN_ROUTE } from "../utils/consts";
import { PrivateRoute } from "./hooks/PrivateRoute";



const AppRouter = () => {
   const {user} = useContext(Context);

   let userUuid = {uuid: localStorage.getItem('uuid')}
  



  return (

    <Routes>
      <Route path={LOGIN_ROUTE} element={<Auth/>}/>
      <Route element={<PrivateRoute/>}>
       {ownerRoutes.map(({path, element}) => {
         return <Route key={path} path={path} element={element} exact></Route>}
      )}
      </Route>
      
    </Routes>
  );
}

export default AppRouter;


